<template>
  <div class="password_reset">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Password Reset</h5>
        <hr style="margin-bottom:50px;">

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input type="password"  v-model="new_password" @blur="fCheckPassword" :state="fPasswordOk" placeholder=" "></b-form-input>
              <span>New password*</span>
              <p v-if="fPasswordOk!=false">Your new Password</p>
              <b-form-invalid-feedback :force-show="fPasswordOk==false">Password is required</b-form-invalid-feedback>
            </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input type="password"  v-model="confirm_password" @blur="fCheckConfirmPassword" :state="fConfirmPasswordOk" placeholder=" "></b-form-input>
              <span>Confirm password*</span>
               <p v-if="fConfirmPasswordOk!=false">Your confirm new Password</p>
              <b-form-invalid-feedback :force-show="fConfirmPasswordOk==false">Password does not match</b-form-invalid-feedback>
            </label>
          </p>
          <b-button variant="outline-secondary" @click="changePassword" style="margin-top:30px; width:80%; max-width:300px">Reset Password</b-button>

      </b-card>
      </b-container>

      <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input required fields with valid data</b-alert>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      new_password: '',
      fPasswordOk: null,
      confirm_password: '',
      fConfirmPasswordOk: null,
      email: '',
      tmp_code: '',
      showAlert: false,
    }
  },
  mounted() {
    this.email = this.$route.query.e
    this.tmp_code= this.$route.query.c

    if (!this.email){
      alert("Invalid email")
      this.$router.push({name:'Home'})
    }

    // eslint-disable-next-line
    if ( !(this.email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim()))) ){
      alert("Invalid email")
      this.$router.push({name:'Home'})
    }

    if (!this.tmp_code || this.tmp_code.length != 6){
      alert("Invalid request")
      this.$router.push({name:'Home'})
    }


  },
  methods:{
    changePassword() {
      this.showAlert = false;
      var pw2 = this.new_password.trim();
      var pw3 = this.confirm_password.trim();
      if (pw2=="" || pw3=="") {
        this.showAlert = true;
        alert('All fields are required.')
        return;
      }
      if (this.new_password != this.confirm_password) {
        this.showAlert = true;
        alert('New password does not match')
        return;
      }
      this.$http.put('/api/member/password_reset?email='+this.email+'&tmp_code='+this.tmp_code,{
        "new_password" : pw2
      }).then((resp) => {
        this.history=resp.data;
        alert('Password has been reset, please sign in with the new password.');
        this.$router.push({name: 'Home'})
      }).catch((error) => {
        alert("Fail to reset password, please check your email for resetting information");
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckPassword () {
      this.fPasswordOk = this.new_password.trim() != "";
    },
    fCheckConfirmPassword () {
      this.fConfirmPasswordOk = (this.confirm_password.trim() != "") && (this.confirm_password.trim() == this.new_password.trim());
    },
 }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
