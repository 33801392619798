<template>
  <div class="book_events">
    <div style="width:100%;">
      <div class="container-lg" style="background:#ddd;position:relative; padding:10px;">
        <div class="d-flex" style="width:260px; margin:auto;">
          <span @click="setWeek(false)"><i class="fas fa-backward" style="cursor:pointer;font-size:20px;margin-top:10px; margin-right:15px;"></i></span>
          <b-form-datepicker id="datepicking" today-button value-as-date @input="updateCalendar" v-model="calDate" class="mb-2 mx-auto" style="width:200px" :date-format-options="{month: 'short', day: 'numeric', year: 'numeric'}" locale="en-US" >
            <div class="d-flex">
            <b-button size="sm" variant="outline-primary" class="ml-auto" @click="setToday" >
              Set Today
            </b-button>
            </div>
          </b-form-datepicker>
          <span @click="setWeek(true)"><i class="fas fa-forward" style="cursor:pointer;font-size:20px;margin-top:10px; margin-left:15px;"></i></span>
        </div>
      </div>

      <div class="container-lg" style="background:#fff;min-height:400px;overflow-x:auto; white-space:nowrap;border: 1px solid #ddd; position:relative ">
        <b-overlay :show="showOverlay" rounded="sm">
          <div style="height:30px; display:inline-block; position:relative; width:60px"></div>
          <div :style="weekStyle" v-for="k in weekTable" :key="k.a">
            {{k.a}}<br>{{k.b}}
          </div>
          <div style="display:clear"></div>

          <div v-for="(d, di) in hourTable" :key="d" :style="hourTableStyle(di)">
            {{d}}
          </div>
          <div style="height:30px; display:inline-block; position:relative; width:60px"></div>

          <div class="shadow-sm" :style="calendarDaily" v-for="(d, index) in data.events" :key="index">
            <div style="width:100%" v-for="e in d" :key="e.id">
              <div v-bind:style="e.css" class="mx-auto event" @click="show_event_details(e)">
                <span class="text-wrap" style="font-size:11px; color:#fff">
                  <font style="font-size:1.1em; font-weight:bold">{{ e.name }}</font>
                  <br>
                  {{H24toH12((e.start_time.split("T")[1]).slice(0, -4))}} - {{H24toH12((e.end_time.split("T")[1]).slice(0, -4))}}
                  <br>
                  <span v-if="e.court_reservable>0">
                    ${{ (e.min_reserve_time/e.reserve_interval) * e.reserve_interval_price }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>

    </div>

    <b-modal id="modal-events" scrollable centered :title="selectedEvent.name" ref="eventDetail" button-size="sm">
      <div class="my-4" style="font-weight:bold">
        {{ selectedEvent_event_date }}
        <span v-if="selectedEvent.court_reservable==0">({{ selectedEvent_start_time }} - {{ selectedEvent_end_time }})</span>
      </div>
      <div v-html="selectedEvent.description" class="my-4"></div>

      <div v-if="selectedEvent.court_reservable==0 && !selectedEvent.no_max">
        Max capacity: {{ selectedEvent.max_member }}<br>
        Currently available: {{ current_available_slot }}
        <hr>
          <div style="text-align:center; font-size:10px;">
            Current Players:<br>
            <span v-if="player_name_list">{{ players_list }}</span>
          </div>
      </div>
      <div v-else>Max Capacity: No limit</div>

      <template #modal-footer>
        <b-overlay :show="showOverlay2" rounded="sm">
          <b-button  v-if="!selectedEvent.no_max" :disabled="(selectedEvent.available_backup==0 && selectedEvent.filled)" variant="success" @click="show_open_play_reserve_form(selectedEvent)">
            <span v-if="selectedEvent.joined">Already reserved<br>Go Member Portal <i class="fas fa-user-circle"></i></span>
            <span v-else-if="!selectedEvent.filled">Reserve now</span>
            <span v-else>
              {{ (selectedEvent.available_backup>0)? "Join Backup List":"Event Full" }}
            </span>
          </b-button>
        </b-overlay>
      </template>
    </b-modal>

    <b-modal id="modal-open-play-form" scrollable centered title="Reservation Form" ref="openPlayForm" button-size="sm">
      <p>You can reserve spot for up to {{ total_sign_up_available }}  players, please enter first name and last initial.</p>
      <b-form-input v-model="players[0]" maxlength="20" placeholder="Player 1"></b-form-input>
      <b-form-input :disabled="selectedEvent.max_member-current_booked_number<2" v-model="players[1]" maxlength="20" placeholder="Player 2 (optional)"></b-form-input>
      <b-form-input :disabled="selectedEvent.max_member-current_booked_number<3" v-model="players[2]" maxlength="20" placeholder="Player 3 (optional)"></b-form-input>
      <b-form-input :disabled="selectedEvent.max_member-current_booked_number<4" v-model="players[3]" maxlength="20" placeholder="Player 4 (optional)"></b-form-input>
      <template #modal-footer>
        <b-overlay :show="showOverlay3" rounded="sm">
          <b-button @click="$refs['openPlayForm'].hide()">Cancel</b-button>
          <b-button style="margin-left:10px;" variant="success" @click="reserve_open_play(selectedEvent)">Confirm Reserve</b-button>
        </b-overlay>
      </template>
    </b-modal>

    <b-modal id="modal-login" title="Please log in" ref="memberLogin" hide-footer>
      <div style="text-align:center; margin:auto">
        <login :login_only=1 :redirect="redirect_url"></login>
      </div>
    </b-modal>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import Login from '../login.vue';

export default {
  mixins: [datetime_mixin],
  props: {
    days: {
      type: Number,
      default: 7
    },
  },
  data() {
    return {
      data: {},
      selectedEvent: {},
      dailyDivHeight: 0,
      hourTable: [],
      calDate: new Date(),
      weekTable: [],
      current_booked_number: 0,
      players: [],
      showOverlay:false,
      showOverlay2:false,
      showOverlay3:false,
      player_name_list: [],
    }
  },
  components: {
    Login,
  },
  computed: {
    user_name () {
      return this.$store.state.userStore.first_name + ' ' + this.$store.state.userStore.last_name;
    },
    player_name () {
      return this.$store.state.userStore.first_name + ' ' + this.$store.state.userStore.last_name.charAt(0);
    },
    players_list () {
      return this.player_name_list.join(", ")
    },
    user () {
      return this.$store.state.userStore.user
    },
    member_id () {
      return this.$store.state.userStore.member_id
    },
    redirect_url () {
      if (!this.selectedEvent.event_date) return
      return "/booking/events?d="+((this.selectedEvent.event_date).split("T"))[0]
    },
    calendarDaily (){
      return {
        width: `${90/this.days}%`,
        "min-width":"100px",
        background:"#eee",
        height:`${this.data.center.business_hours / 30 * 18}px`,
        margin:"2px",
        "margin-top":"0px",
        position:"relative",
        display:"inline-block"
      };
    },
    weekStyle (){
      return {
        width: `${90/this.days}%`,
        "min-width":"100px",
        height:"38px",
        margin:"2px",
        "margin-bottom": '0px',
        position:"relative",
        "text-align":"center",
        display:"inline-block",
        border: "1px solid #eee",
        "font-size": "12px"
      }
    },
    selectedEvent_event_date () {
      if (!this.selectedEvent.event_date) return;
      return this.ISOZtoShortDate(this.selectedEvent.event_date)
    },
    selectedEvent_start_time () {
      if (!this.selectedEvent.start_time) return;
      return this.H24toH12(this.ISOZtoH24(this.selectedEvent.start_time))
    },
    selectedEvent_end_time () {
      if (!this.selectedEvent.end_time) return;
      return this.H24toH12(this.ISOZtoH24(this.selectedEvent.end_time))
    },
    total_sign_up_available () {
      let n = this.selectedEvent.max_member-this.current_booked_number
      return (4 < (n))?4:n
    },
    current_available_slot () {
      let c= this.selectedEvent.max_member -  this.current_booked_number
      return (c<0) ? 0 : c;
    }
  },
  mounted () {
    if (this.$route.query.d) {
      this.calDate = new Date(this.$route.query.d);
    }
    this.updateCalendar();
    this.players[0] = this.player_name
  },
  methods:{
    getStoreHourTable() {
      var table = [];

      var current = this.data.center.open_time;
      var tmp;
      var i = (this.data.center.business_hours / 30)+1
      while ( i>0 ) {
        i--
        tmp = current.split(":");
        if (tmp[1]!="30") table.push(this.H24toH12(current));

        current = this.H24Add30Min(current)
      }

      return table;
    },
    hourTableStyle (i) {
      return {
        position: "absolute",
        left: "20px",
        top: (i*2*18+30)+"px",
        "font-size": "10px",
      }
    },
    setCalendarWeek (inputDate) {
      this.weekTable = [];
      let weekName = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
      let options = {
        year: 'numeric', month: 'short', day: 'numeric'
      };
      for (var i=0; i<this.days; i++) {
        this.weekTable.push({
            a: inputDate.toLocaleString('en-US', options),
            b: weekName[inputDate.getDay()]
          })
      inputDate.setDate(inputDate.getDate()+1);
      }
    },
    updateCalendar () {
      this.showOverlay = true;
      this.setCalendarWeek(new Date(this.calDate));

      let options = {
        year: 'numeric', month: 'numeric', day: 'numeric'
      };
      let tmp = this.calDate.toLocaleString('en-US', options);
      tmp = tmp.split("/")
      let dateStr = ((tmp[0].length == 2)?tmp[0]:"0"+tmp[0]) + "/"+((tmp[1].length == 2)?tmp[1]:"0"+tmp[1])+"/"+tmp[2];
      this.$http.get('/api/booking/events?d='+dateStr + '&days='+this.days
      ).then((resp) => {
        this.data = resp.data;
        this.dailyDivHeight=this.data.center.business_hours / 30 * 18;

        var eventColor = ["rgba(76, 175, 200, 0.8)", "rgba(176, 75, 30, 0.8)", "rgba(76, 175, 180, 0.8)", "rgba(76, 175, 80, 0.8)","rgba(176, 75, 80, 0.8)","rgba(16, 215, 180, 0.8)"]

        //calculate the height and top of events
        for(var j = 0; j < this.data.events.length; j++) { //looping days
          for(var i = 0; i < this.data.events[j].length; i++) { //looping daily events
            var top = (18 * this.data.events[j][i].start_hour_slot)+'px';
            this.data.events[j][i].s_time = new Date(this.data.events[j][i].start_time)
            this.data.events[j][i].e_time = new Date(this.data.events[j][i].end_time)
            var event_duration = (this.data.events[j][i].e_time - this.data.events[j][i].s_time)/1000/1800
            var height = (18 * event_duration)+'px';
            this.data.events[j][i].css = 'top:'+top+
              ';height:'+height+
              ';background:'+eventColor[this.data.events[j][i].event_type_id];
          }
        }

        for(var a = 0; a < this.data.events.length; a++) { //looping days
          if ( this.data.events[a].length == 0 ) continue;
          for(var b = 0; b < this.data.events[a].length; b++) { //looping daily events
            var count = 1
            var left = 0
            for(var c = 0; c < this.data.events[a].length; c++) {
              if (b==c) continue;
              if((this.data.events[a][b].s_time < this.data.events[a][c].e_time) && (this.data.events[a][c].s_time < this.data.events[a][b].e_time)) {
                count++;
                if ((this.data.events[a][b].s_time>this.data.events[a][c].s_time)|| (this.data.events[a][b].s_time==this.data.events[a][c].s_time && b>c)){
                  left++;
                }

              }
            }

            var width = 100/count;
            this.data.events[a][b].css+=';width:'+(width)+'%;left:'+(left * width)+'%';
          }
        }
        this.hourTable = this.getStoreHourTable()
      }).catch((error) => {
        console.log(error)
        console.log(error.message)
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
        alert(error.response.data.message)
      }).finally(() => {
        this.showOverlay = false;
      })
    },
    show_event_details (e) {
      this.player_name_list = [];
      this.selectedEvent = e;
      this.selectedEvent.current_booked = [];
      this.selectedEvent.no_max = ((this.selectedEvent.max_member==0) && (this.selectedEvent.court_reservable == 0)) //open play and no max capacity
      this.$refs.eventDetail.show();

      if (this.selectedEvent.no_max) return;

      if (this.selectedEvent.court_reservable==0) this.showOverlay2 = true; //only overlay for open play
      //open play session, check capacity
      if (this.selectedEvent.court_reservable == 0 && this.selectedEvent.max_member>0) {
        this.$http.get('/api/booking/event_sessions_summary?id='+this.selectedEvent.id+'&cr='+this.selectedEvent.court_reservable
        ).then((resp) => {
          this.selectedEvent.current_booked = resp.data.member_id_list
          this.player_name_list = resp.data.player_name_list
          this.selectedEvent.joined = (this.selectedEvent.current_booked.includes(this.member_id) && this.member_id!=0)
          this.current_booked_number = this.player_name_list.length
          this.selectedEvent.filled = ((this.selectedEvent.max_member!=0) && ((this.selectedEvent.max_member - this.current_booked_number)<=0))
          let slot_left = this.selectedEvent.max_member - this.current_booked_number
          this.selectedEvent.available_backup = ((slot_left>=0)? this.selectedEvent.max_backup : this.selectedEvent.max_backup+slot_left);
        }).catch((error) => {
          alert(error.response.data.message);
          console.log(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        }).finally(() => {
          this.showOverlay2 = false;
        })
      }
    },
    setToday () {
      this.calDate = new Date()
    },
    setWeek (next) {
      if (next) {
        this.calDate = new Date(this.calDate.setDate(this.calDate.getDate()+(this.days*1)))
      } else {
        this.calDate = new Date(this.calDate.setDate(this.calDate.getDate()-(this.days*1)))
      }
      this.updateCalendar();
    },
    reserve_open_play (event) {
      this.showOverlay3 = true;
      let status = (event.filled) ? 'Joined Backup' : 'Joined event';
      this.$http.post('/api/booking/book_open_play',
          {
            center_id: event.center_id,
            member_id: this.member_id,
            event_id: event.id,
            event_date: event.event_date,
            notes: ((new Date()).toString().split("GMT"))[0] + '\n' + this.player_name + '\n' + status + '\n\n',
            start_time: this.ISOZtoH24(event.start_time),
            end_time: this.ISOZtoH24(event.end_time),
            player_names: this.players.filter( v => v ).join(), //turn array to string and ignore empty
          }
        ).then((resp) => {
          this.$bvModal.msgBoxOk(resp.data,{
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          .then(() => {
            this.$refs.openPlayForm.hide()
            this.$refs.eventDetail.hide();
            //location.reload();
          })
          .catch((err) => {
            console.log(err)
          })
        }).catch((error) => {
          this.showMsgboxOk(error.response.data.message,'danger')
          console.log(error.response.data.message);
        }).finally(() => {
          this.showOverlay3 = false;
        })
    },
    show_open_play_reserve_form (event) {
      if (this.selectedEvent.joined) {
        this.$router.push({name: 'MemberPortal'})
        return
      }

      if (this.selectedEvent.court_reservable==0) { //open play
        if (!this.user) {
          this.$refs.memberLogin.show()
        }else if (this.selectedEvent.filled && this.selectedEvent.available_backup>0){
          //just join event as a single backup, no multiple registration for backup
          this.players = []
          this.players[0]=this.player_name
          this.reserve_open_play(this.selectedEvent)
        }else{
          this.$refs.openPlayForm.show()
        }
      }else{
        this.$router.push({name: 'BookingSession', params:{id: event.id} })
      }
    },
    showMsgboxOk(html, variant) {
      if (variant == "") variant = 'success';

      let n = this.$createElement('div', { domProps: { innerHTML: html } })

      this.$bvModal.msgBoxOk(n, {
        buttonSize: 'sm',
        okVariant: variant,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
  }
}
</script>


<style scoped lang="scss">
.event {
  position: absolute;
  width: 100%;
  text-align: center;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}

::placeholder, ::-webkit-input-placeholder {
  color: #aaa;
  font-size:0.9em;
}
</style>
