<template>
  <div class="system_membership_checkin_tool">
    <b-modal id="modal-msgbox" ref="modal-msgbox" centered hide-footer title="INFO">
      <div style="text-align:center">
        <img id="memberPhoto" style="width:300px" :src=member_photo>
        <hr>
        <div class="mb-auto">{{ login_msg }}</div>
      </div>
    </b-modal>

    <b-modal id="modal-warning" ref="ref-modal-warning" centered header-bg-variant="danger" hide-footer title="Attention">
      <div style="text-align:center">
        <div class="mb-auto">{{ login_msg }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    'staff_use': { //is this being used by a staff? Error message is different for staff
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canScan: true,
      member_photo: "",
      login_msg: "",
      checked_in_status: 0,
    }
  },
  mounted() {
  },
  methods: {
    //func checks in member and insert member into event sessions
    createCheckin(member_code, event_id=0, staff='') {
      if (member_code.length <3) {
        return;
      }
      if (!this.isScanReady()) return;

      if(event_id==0) alert("Invalid event id.");

      this.canScan = false

      this.$http.post('/api/system/member_check_in_n_session',{
        "member_code" : member_code,
        "center_id" : 1,
        "staff" : staff,
        "event_id" : event_id,
        "note": ((new Date()).toString().split("GMT"))[0] + '\n'+staff+'\n' + "checked in, %s" + '\n\n',
      }).then((resp) => {
        //1=VIP checking, 2=entry pass, 3=need pay discount pass
        this.checked_in_status=resp.data.status
        if (resp.data.status == 3 && this.staff_use) { //show payment form
          this.showDiscountDropinMsg()
        } else {
          this.member_photo = resp.data.photo
          this.login_msg = resp.data.name + ' checked in (valid thru '+resp.data.member_expires + ')';
          if (resp.data.status==2) this.login_msg += ' (Entry Pass remain: ' + resp.data.free_pass + ')'
          this.$bvModal.show('modal-msgbox')
          this.$emit('checkin_succeeded',{
            'checked_in_status': this.checked_in_status,
            'member_name': resp.data.name,
            'member_id': resp.data.member_id,
          })
        }
      }).catch((error) => {
        this.login_msg = error.response.data.message;
        this.$bvModal.show('modal-warning')
        console.log(error.response.data.message);
        this.$emit('checkin_failed',error.response.data.message)
      }).finally(() => {
        // enable scanning after small pause, to clear the msg box animation
        this.$emit('checkin_completed')
        setTimeout(() => this.canScan = true , 1500);
      })
    },
    //func checks in member and will not create new event session entry, used for staff check-in page
    reservedCheckin(reserve_id, member_id, member_name, event_id, status, notes, staff='') {
      if ((reserve_id==0 && member_name == '') || event_id==0) return;

      this.$http.post('/api/system/event_reserved_member_check_in',{
        "member_id" : member_id, //guest will have an id 0
        "center_id" : 1,
        "event_id" : event_id,
        "name" : member_name,
        "status" : parseInt(status),
        "staff" : staff,
        "note": ((new Date()).toString().split("GMT"))[0] + '\n'+this.user+'\n' + "checked in, %s" + '\n\n',
      }).then((resp) => {
        this.checked_in_status=resp.data.status
        //1=VIP checking, 2=entry pass, 3=need pay discount pass
        if (resp.data.status == 3 && this.staff_use) { //show payment form
          this.showDiscountDropinMsg()
        } else {
          this.member_photo = resp.data.photo
          this.login_msg = resp.data.name + ' checked in (valid thru: '+resp.data.member_expires + ')';
          if (resp.data.status==2) this.login_msg += ' (Entry Pass remain: ' + resp.data.free_pass + ')'
          this.$bvModal.show('modal-msgbox')
          this.$emit('reserved_checkin_succeeded',{
            'checked_in_status': this.checked_in_status,
            'notes': notes,
            'reserve_id': reserve_id,
            'member_name': member_name,
            'member_id': member_id,
            'payment_status': status,
          })
        }
      }).catch((error) => {
        this.login_msg = error.response.data.message;
        this.$bvModal.show('modal-warning')
        console.log(error.response.data.message);
        this.$emit('checkin_failed',error.response.data.message)
      }).finally(() => {
      })
    },
    showDiscountDropinMsg() {
      this.$bvModal.msgBoxConfirm('Charge $5 for discount drop in ...',{
          title: 'Please Confirm',
          okVariant: 'info',
          okTitle: 'confirm',
          cancelTitle: 'cancel',
          centered: true
        }).then(confirm => {
          //probably need to input member info into queue system after payment 1/26/2023
          console.log(confirm)
        })
    },
    isScanReady() {
      if (!this.canScan) return false;
      if (this.$refs['modal-msgbox'].isShow || this.$refs['ref-modal-warning'].isShow) return false;

      return true;
    }
  }
}
</script>

<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
</style>
