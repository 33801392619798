<template>
  <div class="update_profile">

    <b-container class="form fluid">
    <b-card class="text-center" style="background:rgba(255,255,255,.9);">

    <h5>Update Profile</h5>
    <hr style="margin-bottom:30px;">

    <div class="sub-title">Your Information</div>
      <div>
        <b-button size="sm" variant="outline-secondary" @click="edit=!edit" style="width:75px; margin:5px">{{ edit? 'Cancel':'Edit' }}</b-button>
        <b-button :disabled="!edit" size="sm" variant="outline-secondary" @click="updateProfile" style="width:120px; margin:5px">Save</b-button>
      </div>
      <br><br>

      <b-row>
         <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.first_name" @blur="fCheckFirstName" :state="fFirstNameOk" placeholder=" "></b-form-input>
            <span>Your first name *</span>
            <p v-if="fFirstNameOk!=false">Enter your first name*</p>
            <b-form-invalid-feedback :force-show="fFirstNameOk==false">Valid name required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.last_name" @blur="fCheckLastName" :state="fLastNameOk" placeholder=" "></b-form-input>
            <span>Your last name *</span>
            <p v-if="fLastNameOk!=false">Enter your last name*</p>
            <b-form-invalid-feedback :force-show="fLastNameOk==false">Valid name required</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='true' v-model="user.email" placeholder=" "></b-form-input>
            <span>Email address *</span>
          </label>
        </b-col>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.phone" @blur="fCheckPhone" :state="fPhoneOk" maxlength="20" placeholder=" "></b-form-input>
            <span>Contact phone #</span>
            <p v-if="fPhoneOk!=false">Enter contact phone #</p>
            <b-form-invalid-feedback :force-show="fPhoneOk==false">Phone not valid</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>

      <div class="sub-title">Address</div>
      <b-row>
         <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.address1" @blur="fCheckAddress1" :state="fAddress1Ok" placeholder=" "></b-form-input>
            <span>Address1</span>
            <p v-if="fAddress1Ok!=false">Enter address1</p>
            <b-form-invalid-feedback :force-show="fAddress1Ok==false">Address not valid</b-form-invalid-feedback>
          </label>
         </b-col>
         <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.address2" @blur="fCheckAddress2" :state="fAddress2Ok" placeholder=" "></b-form-input>
            <span>Address2</span>
            <p v-if="fAddress2Ok!=false">Enter address2</p>
            <b-form-invalid-feedback :force-show="fAddress2Ok==false">Address not valid</b-form-invalid-feedback>
         </label>
         </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.city" @blur="fCheckCity" :state="fCityOk" placeholder=" "></b-form-input>
            <span>City</span>
            <p v-if="fCityOk!=false">Enter City</p>
            <b-form-invalid-feedback :force-show="fCityOk==false">City not valid</b-form-invalid-feedback>
          </label>
        </b-col>
       <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.state" @blur="fCheckState" :state="fStateOk" placeholder=" "></b-form-input>
            <span>State</span>
            <p v-if="fStateOk!=false">Enter State</p>
            <b-form-invalid-feedback :force-show="fStateOk==false">State not valid</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.zipcode" @blur="fCheckZipcode" :state="fZipcodeOk" placeholder=" "></b-form-input>
            <span>Zipcode</span>
            <p v-if="fZipcodeOk!=false">Enter zipcode</p>
            <b-form-invalid-feedback :force-show="fZipcodeOk==false">Zipcode not valid</b-form-invalid-feedback>
          </label>
        </b-col>

        <b-col sm="6">
          <label class="placeholder">
            <b-form-input :readonly='!edit' v-model.trim="user.country" @blur="fCheckCountry" :state="fCountryOk" placeholder=" "></b-form-input>
            <span>Country</span>
            <p v-if="fCountryOk!=false">Enter country</p>
            <b-form-invalid-feedback :force-show="fCountryOk==false">Country not valid</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>

      <hr>

      <div class="container-sm p-3" style="background:#fff1f0">
        <div class="sub-title">Delete Account</div>
        <div style="max-width:300px; margin:auto;">
          <label class="placeholder">
            <b-form-input type="password" v-model.trim="old_password" @blur="fCheckPassword" :state="fPasswordOk" placeholder=" "></b-form-input>
            <span>Password</span>
            <p v-if="fPasswordOk!=false">Enter password to confirm delete</p>
            <b-form-invalid-feedback :force-show="fPasswordOk==false">Password is required</b-form-invalid-feedback>
          </label>
        </div>
        <b-button variant="danger" @click="deleteAccount">Delete Account</b-button>
        <br>*Cautious! This cannot be undone!
      </div>

      <div style="height:50px"></div>
    </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      user: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
     },
      edit: false,
      fFirstNameOk: null,
      fLastNameOk: null,
      fPhoneOk: null,
      fAddress1Ok: null,
      fAddress2Ok: null,
      fCityOk: null,
      fStateOk: null,
      fCountryOk: null,
      fZipcodeOk: null,
      old_password: "",
      fPasswordOk: null,
    }
  },
  mounted () {
    this.getProfile();
  },
  methods:{
    getProfile () {
      this.$http.get('/api/member/profile'
      ).then((resp) => {
        this.user = resp.data;
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    updateProfile () {
      this.user.phone = this.user.phone.replace(/[^0-9]/g,""); // remove all non-digit
      this.$http.post('/api/member/update_profile', this.user
      ).then((resp) => {
        if (resp.data.error)
          alert(resp.data.error);
        if ( resp.data.updated.id != "")
          this.user = resp.data.updated;
          this.edit = false;
          alert("Profile Updated")
      }).catch((error) => {
        alert("Error updating ",error.response.data.message)
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    deleteAccount () {
      if (!this.fPasswordOk) {
        this.fCheckPassword();
        return;
      }
      this.$http.post('/api/member/delete_account', { "old_password" : this.old_password }
      ).then(() => {
        alert("Account Deleted")
        this.$router.push('/');
      }).catch((error) => {
       // alert("Error deleting account ",error.response.data.message)
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    // this.fEmailOk = (this.user.email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email.trim())));
    fCheckFirstName () {
      this.fFirstNameOk = (this.user.first_name.trim()!="" && (/^[a-zA-Z- ]+$/.test(this.user.first_name.trim())));
    },
    fCheckLastName () {
      this.fLastNameOk = (this.user.last_name.trim()!="" && (/^[a-zA-Z- ]+$/.test(this.user.last_name.trim())));
    },
    fCheckPhone () {
      if (this.user.phone.length > 0)
      //  this.fPhoneOk = (this.user.phone.trim()!="" && (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.user.phone.trim())));
        this.fPhoneOk = (this.user.phone.trim()!="" && (/^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/.test(this.user.phone.trim())));
      else
        this.fPhoneOk = true;
    },
    fCheckAddress1 () {
      if (this.user.address1.length > 0)
        this.fAddress1Ok = (this.user.address1.trim()!="" && (/^[a-zA-Z0-9-.: ]+$/.test(this.user.address1.trim())));
      else
        this.fAddress1Ok = true;
    },
    fCheckAddress2 () {
      if (this.user.address2.length > 0)
        this.fAddress2Ok = (this.user.address2.trim()!="" && (/^[a-zA-Z0-9-.: ]+$/.test(this.user.address2.trim())));
      else
        this.fAddress2Ok = true;
    },
    fCheckCity () {
      if (this.user.city.length > 0)
        this.fCityOk = (this.user.city.trim()!="" && (/^[a-zA-Z- ]+$/.test(this.user.city.trim())));
      else
        this.fCityOk = true;
    },
    fCheckState () {
      if (this.user.state.length > 0)
        this.fStateOk = (this.user.state.trim()!="" && (/^[a-zA-Z-]+$/.test(this.user.state.trim())));
      else
        this.fStateOk = true;
    },
    fCheckZipcode () {
      if (this.user.zipcode.length > 0)
        this.fZipcodeOk = (this.user.zipcode.trim()!="" && (/^[0-9-]+$/.test(this.user.zipcode.trim())));
      else
        this.fZipcodeOk = true;
    },
    fCheckCountry () {
      if (this.user.country.length > 0)
        this.fCountryOk = (this.user.country.trim()!="" && (/^[a-zA-Z- ]+$/.test(this.user.country.trim())));
      else
        this.fCountryOk = true;
    },
    fCheckPassword () {
        this.fPasswordOk = this.old_password.trim()!="";
    },
  },
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
.error-text {
  color: #f13131 !important;
}
.form-title {
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 300;
}
.form {
  box-sizing: border-box;
}
.form .sub-title {
  padding: 30px 20px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}
.card, .card-header, .card-body, .card-footer, button{
    border-radius:0px !important;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
  color: #006bb3;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:read-only, .placeholder>textarea:read-only {
  color: #000;
  background: transparent;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
