export default {
  methods: {
    //24hr time to 12 hr (13:00 to 1:00PM, 2:00 to 2:00AM)
    H24toH12: function (input) {
      let splitted = input.split(":");
      let hr = splitted[0]
      let hri = parseInt(hr);
      return (hri < 12) ? hr+":"+splitted[1]+'AM': ((hri==12)?12:(hri-12))+":"+splitted[1]+'PM';
    },
    //12hr to 24hr time (1:00PM to 13:00, 2:00AM to 2:00)
    H12toH24: function (input) {
      let apm = input.slice(-2);
      if (apm.toLowerCase() == "am") {
        return input.substring(0, input.length - 2)
      }

      let tmp = input.substring(0, input.length - 2).split(":");
      if (tmp[0]=="12") {
        return input.substring(0, input.length - 2)
      }else{
        return (parseInt(tmp[0])+12) + ":" + tmp[1]
      }
    },
    //add 30 mins to 24Hr time (13:00 to 13:30, 02:30 to 03:00)
    H24Add30Min: function (input) {
      let splitted = input.split(":");
      let next = (splitted[1]=="30")? parseInt(splitted[0])+1 : parseInt(splitted[0]);
      let hrStr = next.toString()
      let min = splitted[1] == '00'? '30': '00';
      return ((hrStr.length == 1) ? '0'+hrStr : hrStr) + ":" + min;
    },
    //add 60 mins to 24Hr time (13:00 to 14:00, 02:30 to 03:30)
    H24Add60Min: function (input) {
      let splitted = input.split(":");
      if (splitted[0]==23) return "00:"+splitted[1]
      let next = parseInt(splitted[0])+1;
      let hrStr = next.toString()
      return ((hrStr.length == 1) ? '0'+hrStr : hrStr) + ":" + splitted[1];
    },
    //0000-01-01T19:00:00Z to 19:00
    ISOZtoH24: function (input){
      return (input.split("T")[1]).slice(0, 5)
    },
    //2020-01-01T19:00:00Z to Jan. 01 2020
    ISOZtoShortDate: function (input){
      if (!input) return;
      let month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
      let s = (input.split("T")[0]).split("-")
      return month[parseInt(s[1])-1] + ' ' + s[2] + ' ' + s[0]
    },
    //return how many 30mins slot in a given time range (end_time 21:30, start_time 17:00 = 9)
    TimeRangeTo30MinSlots: function (start_time, end_time) {
      let tmp1 = start_time.split(":");
      let tmp2 = end_time.split(":");
      let slots = (parseInt(tmp2[0]) - parseInt(tmp1[0])) * 2

      let minDiff =  parseInt(tmp2[1]) - parseInt(tmp1[1])
      if (minDiff==30) {
        slots+=1;
      }else if (minDiff == -30) {
        slots-=1;
      }
      return slots;
    },
    //return the difference of time in Mins (start: 13:00, end: 15:00 = 120)
    TimeRangeDiffInMins: function(start_time, end_time) {
      let tmp1 = start_time.split(":");
      let tmp2 = end_time.split(":");
      let diff = (parseInt(tmp2[0]) - parseInt(tmp1[0])) * 60

      let minDiff =  parseInt(tmp2[1]) - parseInt(tmp1[1])
      if (minDiff==30) {
        diff+=30;
      }else if (minDiff == -30) {
        diff-=30;
      }
      return diff;
    },
    //return true if time2 > time1 (time1: 13:00, time2: 15:00 = true)
    IsTime2Larger: function(time1, time2) {
      let tmp1 = time1.split(":");
      let tmp2 = time2.split(":");
      tmp1[0] = parseInt(tmp1[0])
      tmp2[0] = parseInt(tmp2[0])
      if (tmp2[0] > tmp1[0]) {
        return true
      } else if (tmp2[0] == tmp1[0]) {
        return  (parseInt(tmp2[1]) > parseInt(tmp1[1])) ? true : false;
      } else {
        return false
      }
    },
    //check 2 time ranges for overlapping (Use 24Hr format => 13:00, 02:30)
    TimeRangeOverlap: function(start_time1, end_time1, start_time2, end_time2) {
      let tmp = start_time1.split(":")
      let sTime1 = (parseInt(tmp[0])) + (tmp[1]=="30"? 0.5 : 0)
      tmp = start_time2.split(":")
      let sTime2 = (parseInt(tmp[0])) + (tmp[1]=="30"? 0.5 : 0)
      tmp = end_time1.split(":")
      let eTime1 = (parseInt(tmp[0])) + (tmp[1]=="30"? 0.5 : 0)
      tmp = end_time2.split(":")
      let eTime2 = (parseInt(tmp[0])) + (tmp[1]=="30"? 0.5 : 0)

      return ((sTime1 < eTime2) && (sTime2 < eTime1))
    },
    // get date in YYYY-MM-DD format, default is today, params days is for offset from today
    GetDate (days) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date.toISOString().split('T')[0]
    },
    // get date in Jan. 01 2020 format, default is today, params days is for offset from today
    GetShortDate: function (days){
      let month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
      let date = new Date();
      date.setDate(date.getDate() + days);
      let s = date.toISOString()
      s = (s.split("T")[0]).split("-")
      return month[parseInt(s[1])-1] + ' ' + s[2] + ', ' + s[0]
    },
  }
}
