<template>
  <div class="admin_member">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Admin Member</h5>

        <div style="float:right; margin:20px;">
          <b-form-select v-model="pageLimit">
            <b-form-select-option value="100">Show 100 per page</b-form-select-option>
            <b-form-select-option value="150">Show 150 per page</b-form-select-option>
            <b-form-select-option value="200">Show 200 per page</b-form-select-option>
          </b-form-select>
        </div>

        <hr style="margin-bottom:50px;">

        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <h5>Admin Member</h5>
          <div style="text-align:center; width:100%">

            <span>Sort by: </span>
            <select v-model="selected">
              <option v-for="option in options" v-bind:key="option.value" v-bind:value="option.value">
                {{ option.text }}
              </option>
            </select>

            <b-table responsive striped hover :items="all_members" :fields="fields" id="admin_list_member" style="font-size:9pt">
              <template v-slot:cell(id)="row">
                <span v-if="row.item.id>0" style="cursor:pointer; color:blue" @click="getMemberHistory(row.item.id)">
                  {{row.item.id}}
                </span>
                <span v-else>{{row.item.id}}</span>
              </template>
            </b-table>

          </div>
        </div>

        <b-pagination v-model="currentPage" :total-rows="member_total" :per-page="pageLimit" align="center" aria-controls="admin_list_member:"></b-pagination>

      </b-card>
      </b-container>

    </div>

    <b-modal ref="modal-member-history" hide-footer size="lg" title="History">
      <memberHistory :member_id=selectedMemberId :limit=25></memberHistory>
    </b-modal>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import memberHistory from '../reusable/member_history.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    memberHistory,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'first_name',
          label: 'First Name',
        },
        {
          key: 'last_name',
          label: 'Last Name',
        },
        {
          key: 'member_expires',
          label: 'Membership Expiration',
          tdAttr: {style: "min-width:130px;"},
          formatter: (v) => { return v? (v).split("T")[0]:'' },
        },
        {
          key: 'email',
          label: 'E-Mail',
        },
      ],
      selected: 3,
      options: [
        { text: 'ID', value: 0 },
        { text: 'first name', value: 1 },
        { text: 'last name', value: 2 },
        { text: 'membership expiration', value: 3 }
      ],
      currentPage: 1,
      pageLimit: 150,
      all_members: [],
      member_total: 0,
      selectedMemberId: 0,
    }
  },
  watch: {
    currentPage: function(val) {
      this.getMembers(val-1)
    },
    pageLimit: function() {
      this.getMembers(0)
    },
    selected: function() {
      this.currentPage = 1
      this.getMembers(0)
    }
  },
  mounted () {
    this.getMembers(0);
  },
  methods:{

    getMembers(page) {
      this.$http.get('/api/admin/member?sort='+this.selected+'&limit='+this.pageLimit+'&offset='+ (page * this.pageLimit)
      ).then((resp) => {
        this.all_members = resp.data.all_members;
        this.member_total = resp.data.member_total;
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    getMemberHistory(id){
      if (id<=0) return;

      this.selectedMemberId = +id //turn id into int
      this.$refs.['modal-member-history'].show()
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
