
<template>
  <div class="system_camera">
    <div style="height:50px"></div>

    <div style="width:100%;">
      <b-container class="form fluid">
      <b-card class="text-center" style="height:500px; background:rgba(255,255,255,.9);">



	<!-- <div style="width:150px"> -->
          <!-- <label for="audioSource">Audio source: </label><select id="audioSource"></select> -->
	<!-- </div> -->

	<div class="mx-auto" style="">
          <label class="" for="videoSource">Camera </label>&nbsp;&nbsp;&nbsp;<select id="videoSource"></select>
	</div>
	<br>
	<button class="btn btn-primary btn-sm" id="btn-capture" type="button">Capture Image</button>
	<br><br>

	<div class="mx-auto" style="width:650px;">
          <video class="float-left" id="stream" autoplay muted playsinline style="width:300px;"></video>
          <img class="float-right" id="captureImage" style="width:300px" src="">
          <canvas class="float-none" id="capture" style="display:none;"></canvas>
	</div>
	<div style="clear:both"></div>

	<br>
	<button class="btn btn-success" id="btn-ok" @click="useSnapshot" type="button">Use Photo</button>
      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>



export default {
  data() {
    return {
      //cameraStream: null,
      //snapshot: null,
    }
  },
  mounted() {
    //var audioSelect = document.querySelector('select#audioSource');
    var videoSelect = document.querySelector('select#videoSource');
    var captureBtn = document.getElementById( "btn-capture" );

    //audioSelect.onchange = this.getStream;
    videoSelect.onchange = this.getStream;
    captureBtn.addEventListener( "click", this.captureSnapshot );

    this.getStream().then(this.getDevices).then(this.gotDevices);
  },
  methods:{
   getStream() {
      //var audioSelect = document.querySelector('select#audioSource');
      var videoSelect = document.querySelector('select#videoSource');

//    if (window.stream) {
 //     window.stream.getTracks().forEach(track => {
//	track.stop();
 //     });
  //  }
      //const audioSource = audioSelect.value;
      const videoSource = videoSelect.value;
      const constraints = {
	//audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
	video: {deviceId: videoSource ? {exact: videoSource} : undefined}
      };
      return navigator.mediaDevices.getUserMedia(constraints).
	then(this.gotStream).catch();
    },
    gotDevices(deviceInfos) {
      //var audioSelect = document.querySelector('select#audioSource');
      var videoSelect = document.querySelector('select#videoSource');
      window.deviceInfos = deviceInfos; // make available to console
      for (const deviceInfo of deviceInfos) {
	const option = document.createElement('option');
	option.value = deviceInfo.deviceId;
	if (deviceInfo.kind === 'audioinput') {
          //option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
          //audioSelect.appendChild(option);
	} else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
          videoSelect.appendChild(option);
	}
      }
    },
    gotStream(stream) {
      //var audioSelect = document.querySelector('select#audioSource');
      var videoSelect = document.querySelector('select#videoSource');
      var videoElement = document.querySelector('video');

      window.stream = stream; // make stream available to console
      //audioSelect.selectedIndex = [...audioSelect.options].findIndex(option => option.text === stream.getAudioTracks()[0].label);
      videoSelect.selectedIndex = [...videoSelect.options].findIndex(option => option.text === stream.getVideoTracks()[0].label);
      videoElement.srcObject = stream;
    },
      handleError(error) {
      console.error('Error: ', error);
    },
      getDevices() {
      // AFAICT in Safari this only gets default devices until gUM is called :/
      return navigator.mediaDevices.enumerateDevices();
    },

    captureSnapshot() {
      var canvas = document.getElementById( "capture" );
      var img = document.getElementById("captureImage");
      var video = document.getElementById('stream');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      // Other browsers will fall back to image/png

      var data = canvas.toDataURL('image/jpeg', 0.5);
      img.src = data
    },

    useSnapshot() {
      var canvas = document.getElementById( "capture" );
      var data = canvas.toDataURL('image/jpeg', 0.5);
      this.$emit('photo',data)
    }



//    update(stream) {
//      document.querySelector('video').src = stream.url;
//    },

  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
.button-group, .play-area {
  border: 1px solid grey;
  padding: 1em 1%;
  margin-bottom: 1em;
}

.button {
  padding: 0.5em;
  margin-right: 1em;
}

.play-area-sub {
  width: 47%;
  padding: 1em 1%;
  display: inline-block;
  text-align: center;
}

#capture {
  display: none;
}

#snapshot {
  display: inline-block;
  width: 320px;
  height: 240px;
}
</style>
