<template>
  <div class="password">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Password Change</h5>
        <hr style="margin-bottom:50px;">

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <input type="password" v-model="old_password" placeholder=" " />
              <span>Current password</span>
            </label>
          </p>
          <div style="height:30px"></div>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <input type="password"  v-model="new_password" placeholder=" " />
              <span>New password</span>
            </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <input type="password"  v-model="confirm_password" placeholder=" " />
              <span>Confirm password</span>
            </label>
          </p>
          <b-button variant="outline-secondary" @click="changePassword" style="margin-top:30px; width:80%; max-width:300px">Change Password</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      old_password: '',
      new_password: '',
      confirm_password: '',
    }
  },
  methods:{
    changePassword() {
      var pw1 = this.old_password.trim();
      var pw2 = this.new_password.trim();
      var pw3 = this.confirm_password.trim();
      if (pw1=="" || pw2=="" || pw3=="") {
        alert('All fields are required.')
        return;
      }
      if (this.new_password != this.confirm_password) {
        alert('New password does not match')
        return;
      }
      this.$http.put('/api/member/password_change',{
        "old_password" : pw1,
        "new_password" : pw2
      }).then((resp) => {
        this.history=resp.data;
        alert('Password has been changed');
        this.$router.push({name: 'Home'})
      }).catch((error) => {
        alert("Fail to change password, please try again with correct password");
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
