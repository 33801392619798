<template>
  <div class="new_category">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Admin New Category</h5>
        <hr style="margin-bottom:50px;">

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model="category" @blur="fCheckCategory" :state="fCategoryOk" placeholder=" "></b-form-input>
              <span>Category *</span>
              <p v-if="fCategoryOk!=false">Category type</p>
              <b-form-invalid-feedback :force-show="fCategoryOk==false">Category is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model="description" @blur="fCheckDescription" :state="fDescriptionOk" placeholder=" "></b-form-input>
              <span>Description *</span>
              <p v-if="fDescriptionOk!=false">Description of category</p>
              <b-form-invalid-feedback :force-show="fDescriptionOk==false">Description is required</b-form-invalid-feedback>
              </label>
          </p>

          <b-button variant="outline-secondary" @click="createCategory" style="margin-top:30px; width:80%; max-width:300px">Create Category</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: '',
      fCategoryOk: null,
      description: '',
      fDescriptionOk: null,
    }
  },
  methods:{
    createCategory() {
      if (this.category.trim() =="" || this.description.trim() =="") {
        alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/new_category',{
        "category" : this.category.trim(),
        "description" : this.description.trim() 
      }).then(() => {
        alert('Category "' + this.category + '" has been created');
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckCategory() {
      this.fCategoryOk = this.category.trim() != "";
    },
    fCheckDescription() {
      this.fDescriptionOk = this.description.trim() != "";
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
