<template>
  <div class="staff_member_register">

    <b-container class="form fluid">
    <b-card class="text-center">
      <div class="form-title">
        Register New Member
        &nbsp;&nbsp;&nbsp;&nbsp;
        <b-button v-b-modal.modal-camera-register class="m-3 btn-info btn-sm">Launch camera</b-button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img id="memberPhotoRegister" style="width:150px" src="">
      </div>

      <hr>

      <b-row sm="6">
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="email" @blur="fCheckEmail" :state="fEmailOk" placeholder=" "></b-form-input>
            <span>Email address *</span>
            <p v-if="fEmailOk!=false">This is member login email</p>
            <b-form-invalid-feedback :force-show="fEmailOk==false">Valid email is required</b-form-invalid-feedback>
          </label>
        </b-col>

        <b-col sm="6">
        </b-col>
      </b-row>

      <b-row sm="6">
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="first_name" placeholder=" "></b-form-input>
            <span>First Name</span>
            <p>Member first name optional</p>
          </label>
        </b-col>

        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="last_name" placeholder=" "></b-form-input>
            <span>Last Name</span>
            <p>Member Last name optional</p>
          </label>
        </b-col>
      </b-row>

      <b-button variant="outline-secondary" @click="registerMember" style="width:90%; max-width:500px">Register</b-button>

    </b-card>
    </b-container>

    <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input required fields with valid data</b-alert>

    <b-modal id="modal-camera-register" hide-footer size="lg" title="Camera">
      <systemCamera v-on:photo="photoTakenRegister($event)"></systemCamera>
    </b-modal>

  </div>
</template>

<script>
import systemCamera from '../reusable/system_camera.vue';

export default {
  components: {
    systemCamera,
  },
  data () {
    return {
      errors: [],
      email: "",
      first_name: "",
      last_name: "",
      fEmailOk: null,
      showAlert: false,
    }
  },
  created () {
  },
  mounted () {
  },
  methods:{
    registerMember () {
      this.showAlert = false;
      if (!this.fEmailOk) {
        this.fCheckEmail();
        this.showAlert = true;
        return
      }

      var img = document.getElementById("memberPhotoRegister");
      let photo = img.src

     this.$http.post('/api/staff/new_member',{
        "email" : this.email,
        "photo" : photo,
        "first_name" : this.first_name,
        "last_name": this.last_name,
      }).then((resp) => {
        //success registering
        console.log(resp.data)
        this.getMemberId(resp.data.member_id);  // emit member_id
        alert("Member registered. Password is 'ncbc'. An email is sent to member, check junk mailbox if cannot find email");
      }).catch((error) => {
        //failed to register
        alert("Fail to register, please make sure email has not been registered already.")
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    getMemberId (id) {
      var img = document.getElementById("memberPhotoRegister");
      this.$emit('memberRegistered',{
        'id': parseInt(id),
        'email': this.email,
        'first_name': this.first_name,
        "last_name": this.last_name,
        "photo": img.src
      })
    },
    fCheckEmail () {
     // eslint-disable-next-line
     this.fEmailOk = (this.email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())))
    },
    photoTakenRegister(e) {
      var img = document.getElementById("memberPhotoRegister");
      img.src = e
      this.$bvModal.hide('modal-camera-register')
    },
  },
}
</script>
