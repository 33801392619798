<template>
  <div id="app">
    <router-view style="position:fixed; width:100%;z-index:3" :key="$route.fullPath" name="top"></router-view>

    <div style="height:40px"></div>

      <div style="position:absolute; left:50%; top:10px; z-index:5;" class="d-none d-sm-none d-md-block d-lg-block">
        <div style="position:relative; left:-50%;">
          <router-link to="/" style="cursor:pointer">
            <img src='./assets/images/center_ncbc_logo.png' style="width:100px;" />
          </router-link>
        </div>
      </div>

      <div style="position:absolute; left:50%; top:50px; z-index:2;" class="d-block d-sm-block d-md-none d-lg-none">
        <div style="position:relative; left:-50%;">
          <router-link to="/" style="cursor:pointer">
            <img src='./assets/images/center_ncbc_logo.png' style=" width:65px;" />
          </router-link>
        </div>
      </div>

    <div id="body-bg">

      <div class="top_menu d-none d-sm-none d-md-none d-lg-block" style="position:relative; text-align:center">
        <div class="d-inline-flex justify-content-around" style="">
          <router-link class="top_menu_a menu-bullet" to="/ncbc/location"><span style="font-size:1.2em">&bull;</span> Location</router-link>
          <router-link class="top_menu_a menu-bullet" to="/ncbc/classes"><span style="font-size:1.2em">&bull;</span> Class/Trainings</router-link>
          <router-link class="top_menu_a" to="/booking/events"><span style="font-size:1.2em">&bull;</span> Gym Calendar</router-link>
        </div>

        <div class="d-inline-flex justify-content-center" style="width:160px; margin:auto;">
        </div>

        <div class="d-inline-flex justify-content-around" style="">
          <p class="top_menu_a menu-bullet" @click="msgBoxOk('coming soon')">Leagues/Ladder</p>
          <a class="top_menu_a menu-bullet" href="https://www.instagram.com/norcalbadminton/" target="_blank">
            <span style="font-size:1.2em">&bull;</span> Gallery
          </a>
          <p class="top_menu_a" @click="msgBoxOk('Visit NCBC for our pro shop.')"><span style="font-size:1.2em">&bull;</span> Pro Shop</p>
        </div>
      </div>

      <div class="top_menu_sm d-none d-sm-none d-md-block d-lg-none" style="position:relative; text-align:center">
        <div class="d-inline-flex justify-content-around" style="">
          <router-link class="top_menu_a_sm menu-bullet" to="/ncbc/location">Location</router-link>
          <router-link class="top_menu_a_sm menu-bullet"  to="/ncbc/classes"><span style="font-size:1.2em">&bull;</span> Class/Trainings</router-link>
          <router-link class="top_menu_a_sm" to="/booking/events"><span style="font-size:1.2em">&bull;</span> Gym Calendar</router-link>
        </div>

        <div class="d-inline-flex justify-content-center" style="width:160px; margin:auto;">
        </div>

        <div class="d-inline-flex justify-content-around" style="">
          <p class="top_menu_a_sm menu-bullet" @click="msgBoxOk('coming soon')">Leagues/Ladder</p>
          <a class="top_menu_a_sm menu-bullet" href="https://www.instagram.com/norcalbadminton/" target="_blank">
            <span style="font-size:1.2em">&bull;</span> Gallery
          </a>
          <p class="top_menu_a_sm" @click="msgBoxOk('Visit NCBC for our pro shop.')"><span style="font-size:1.2em">&bull;</span> Pro Shop</p>
        </div>
      </div>

      <div style="height:45px"></div>
      <div style="height:40px" class="d-block d-sm-block d-md-none d-lg-none"></div>

      <router-view />

      <div style="height:50px"></div>
    </div>

    <div style="width:100%; padding:60px 0 110px 0; text-align:center; background:#000; color:#fff">
      <div style="font-size:11pt; margin-bottom:20px">STAY CONNECTED WITH US</div>
      <div class="input-wrapper">
        <input type="email" v-model="mailingListEmail" id="txtNewsletter" placeholder="Enter your email">
        <span class="input-submit" @click="mailingList" href="#">
          <span class="far fa-paper-plane"></span>
        </span>
      </div>
    </div>


    <div class="footer container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-3">
            <p class="d-block topic">GYM EVENTS</p>
            <router-link class="d-block menu" to="/booking/events">Gym Calendar</router-link>
            <p class="d-block menu" @click="msgBoxOk('coming soon')">Leagues/Ladder</p>
            <p class="d-block menu" @click="msgBoxOk('coming soon')">Classes/Trainings</p>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <p class="d-block topic">PRO SHOP</p>
            <p class="d-block menu" @click="msgBoxOk('Visit NCBC for all selections.')">Racquets</p>
            <p class="d-block menu" @click="msgBoxOk('Visit NCBC for all selections.')">Shuttercock</p>
            <p class="d-block menu" @click="msgBoxOk('Visit NCBC for all selections.')">Stringing Service</p>
            <p class="d-block menu" @click="msgBoxOk('Visit NCBC for all selections.')">Apparels & Accessories</p>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <p class="d-block topic">ABOUT US</p>
            <a class="d-block menu" href="/ncbc/location">Location / Opening Hours</a>
            <a class="d-block menu" href="/ncbc/contact">Questions / Suggestion</a>
            <div class="v-spacer"></div>
          </div>
          <div class="col-6 col-sm-6 col-md-3">
            <div class="d-flex">
              <a class="" href="https://www.facebook.com/NCBadminton/" target="_blank"><i class="fab fa-facebook social" target="_blank"></i></a>
              <a class="" href="https://www.instagram.com/norcalbadminton/" target="_blank"><i class="fab fa-instagram social" target="_blank"></i></a>
            </div>

            <div style="height:30px"></div>

            <p class="d-block">
              <a href="https://www.badmintonunited.org" target="_blank">
                <span style="color:#fff;font-size:13px;">
                  Powered by:
                  <img src='./assets/images/bu_logo.png' style="width:100px" />
                </span>
              </a>
            </p>

          </div>
        </div>
      </div>

      <div style="background:#555; margin:20px 0; width:100%; height:1px;"></div>
      <div style="padding-top:10px; font-size:8pt; color:#eee; text-align:center;">
        <span style="padding:0 5px">Norcal Badminton Center © {{new Date().getFullYear()}}</span>
        <span style="padding:0 5px">|</span>
        <span style="padding:0 5px">
          GOT A QUESTION?&nbsp;
          <a href="mailto:contact@norcalbadminton.com?Subject=Web%20Question" target="_top">
            CONTACT@NORCALBADMINTON.COM
          </a>
        </span>
      </div>


    </div>


    <div class="fold_menu_btn d-block d-sm-block d-md-none d-lg-none" style="z-index:6;" v-b-toggle.menu_bar><i class="fas fa-bars"></i></div>
    <b-sidebar class="fold_menu" id="menu_bar" title="Menu" left shadow backdrop backdrop-variant="transparent">
      <hr>
      <div class="px-3 py-2" style="text-align:left;padding-bottom:15px;">
        <p class="side_bar_btn"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></p>
        <p class="side_bar_btn"><a href="/booking/events"><i class="fas fa-calendar-alt mr-2"></i>Gym Calendar</a></p>
        <p class="side_bar_btn"><a href="/ncbc/location"><i class="fas fa-location-arrow mr-2"></i>Gym Location / Hours</a></p>
        <p class="side_bar_btn"><a href="/ncbc/classes"><i class="fas fa-user-friends mr-2"></i>Classes/Trainings</a></p>
        <p class="side_bar_btn" @click="msgBoxOk('Visit NCBC for our pro shop.')"><i class="fas fa-store-alt mr-2"></i>Pro Shop</p>
        <p class="side_bar_btn" @click="msgBoxOk('coming soon')"><i class="fas fa-medal mr-2"></i>Leagues / Ladders</p>
        <p class="side_bar_btn"><a href="/ncbc/contact"><i class="fas fa-envelope mr-2"></i>Contact Us</a></p>
      </div>
    </b-sidebar>



  </div>
</template>

<script>
import title_mixin from './mixins/pagetitle.js'

export default {
  name: 'App',
  mixins: [title_mixin],
  title: "NorCal Badminton Center",
  data () {
    return {
      mailingListEmail: "",
    }
  },
  components: {
  },
  computed: {
  },
  mounted () {
    const favicon = document.getElementById("favicon");
    favicon.href = "https://ncbc.badmintonunited.org/favicon_ncbc.ico";
  },
  methods: {
    mailingList() {
      // eslint-disable-next-line
      if (this.mailingListEmail.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.mailingListEmail.trim()))) {
       this.$http.post('/api/mailing_list',{
          "email"  : this.mailingListEmail,
        }).then(() => {
          this.msgBoxOk("Thank you, we will keep you informed with NCBC news.")
          this.mailingListEmail=""
        }).catch((error) => {
          this.msgBoxOk("Failed registering, please try again")
          console.log(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        })
      } else {
        this.msgBoxOk("Please provide a valid email address")
      }
    },
    msgBoxOk (msg) {
      this.$bvModal.msgBoxOk(msg,{
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
  },
}
</script>

<style scoped>
#app {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position:relative;
}

#body-bg {
    background: url('./assets/images/birdie_bg-1.jpg') no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer {
  background: #222;
  padding: 30px;
  font-size:11pt;
  padding:50px;
  color:#fff;
  text-align: left;
}
.footer a{
}
.footer .menu{
  font-size: 8pt;
  color:#bbb;
  margin-bottom: 15px;
  cursor:pointer;
}
.footer .topic{
  margin-bottom: 15px;
  color:#ccc;
  font-weight:bold;
}
.footer .social{
  font-size: 18pt;
  margin-right: 25px;
  color: #ccc;
}
.footer .payment{
  font-size: 25pt;
  color: #eee;
  margin: 15px;
}
.footer .v-spacer{
  height:30px;
}

.top_menu_sm {
  width: 100%;
  background: rgba(0,0,0,.5);
  font-weight: 400;
  height: 40px;
  line-height: 39px;
}

.top_menu {
  width: 100%;
  background: rgba(0,0,0,.5);
  font-weight: 400;
  height: 40px;
  line-height: 38px;
}

.top_menu_a_sm {
  color:#fff;
  font-size: 11px;
  width:90px;
}

.top_menu_a {
  color:#fff;
  font-size: 15px;
  width:130px;
  cursor: pointer;
}

.menu_bullet {
  color:#fff;
  padding:0px;
}

.fold_menu_btn {
  position: fixed;
  left: 10px;
  top: 3px;
  color: #fff;
  font-size:22px;
}

input{
  padding:13px;
  width:100%;
  font-size:10pt;
  padding-right:50px;
  border:none;
}
.input-wrapper{
  position:relative;
  margin:auto;
  width:300px;
}
span.input-submit{
  position:absolute;
  right:15px;
  top:12px;
  color:#888;
}
span.input-submit:hover{
  color:#f13131;
}



</style>
