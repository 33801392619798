const msg_spacer =  "#%qs_sp%#"; //spacer to separate each message from server

export class Messages {
    static get SPACER() {
        return msg_spacer
    }

    static RequestWorldMap(clientId) {
      return JSON.stringify({
        msg_type: 1, //Request WorldMap type
        client_id: clientId,
        body: '', //send empty string if body is empty
      })
    }

    //tell server this is an Admin Client for queue system
    static RegisterAdminClient(clientId) {
      return JSON.stringify({
        msg_type: 30, //register admin client with sever
        client_id: clientId,
        body: '', //send empty string if body is empty
      })
    }

    //tell server to refresh all clients to get new updates
    static RequestAllClientRefresh(clientId) {
      return JSON.stringify({
        msg_type: 29, //request server to do a refresh of all clients
        client_id: clientId,
        body: '', //send empty string if body is empty
      })
    }

    //tell server to update court status (closed, message, court type etc ..)
    static RequestUpdateCourtStatus(clientId, id, closed, msg) {
      return JSON.stringify({
        msg_type: 28, //request server to update court status
        client_id: clientId,
          body: `{ "court_id": ${id},"closed": ${closed}, "message": "${msg}" }`
      })
    }

    //register a court reservation
    static RegisterReservation(clientId, id, msg, startTime, endTime) {
      return JSON.stringify({
        msg_type: 27, //request server to update court status
        client_id: clientId,
          body: `{ "court_id": ${id}, "message": "${msg}", "start_time": "${startTime}", "end_time": "${endTime}"}`
      })
    }

    //tell server to disable all viewing station screen saver
    static RequestDisableSS(clientId) {
      return JSON.stringify({
        msg_type: 31,
        client_id: clientId,
        body: '', //send empty string if body is empty
      })
    }

    //tell server to enable all viewing station screen saver
    static RequestEnableSS(clientId) {
      return JSON.stringify({
        msg_type: 32,
        client_id: clientId,
        body: '', //send empty string if body is empty
      })
    }

    //request delete a court reservation
    static RequestDeleteReservation(clientId, courtId, startTime, endTime) {
      return JSON.stringify({
        msg_type: 33, //request server to update court status
        client_id: clientId,
          body: `{ "court_id": ${courtId}, "start_time": "${startTime}", "end_time": "${endTime}"}`
      })
    }
}
