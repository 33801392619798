<template>
  <div id="app" style="background:red">
    <div id="body-bg" class="h-100">
      <router-view />
    </div>
  </div>
</template>

<script>
import title_mixin from './mixins/pagetitle.js'

export default {
  name: 'App',
  mixins: [title_mixin],
  title: "NorCal Badminton Center",
  data () {
    return {}
  },
  components: {
  },
  computed: {
  },
  mounted () {
    const favicon = document.getElementById("favicon");
    favicon.href = "https://ncbc.badmintonunited.org/favicon_ncbc.ico";
  },
  methods: {
  },
}
</script>

<style scoped>
#app {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position:relative;
}

#body-bg {
    background: url('./assets/images/birdie_bg-1.jpg') no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer {
  background: #222;
  padding: 30px;
  font-size:11pt;
  padding:50px;
  color:#fff;
  text-align: left;
}
.footer a{
}
.footer .menu{
  font-size: 8pt;
  color:#bbb;
  margin-bottom: 15px;
  cursor:pointer;
}
.footer .topic{
  margin-bottom: 15px;
  color:#ccc;
  font-weight:bold;
}
.footer .social{
  font-size: 18pt;
  margin-right: 25px;
  color: #ccc;
}
.footer .payment{
  font-size: 25pt;
  color: #eee;
  margin: 15px;
}
.footer .v-spacer{
  height:30px;
}

.top_menu_sm {
  width: 100%;
  background: rgba(0,0,0,.5);
  font-weight: 400;
  height: 40px;
  line-height: 39px;
}

.top_menu {
  width: 100%;
  background: rgba(0,0,0,.5);
  font-weight: 400;
  height: 40px;
  line-height: 38px;
}

.top_menu_a_sm {
  color:#fff;
  font-size: 11px;
  width:90px;
}

.top_menu_a {
  color:#fff;
  font-size: 15px;
  width:130px;
  cursor: pointer;
}

.menu_bullet {
  color:#fff;
  padding:0px;
}

.fold_menu_btn {
  position: fixed;
  left: 10px;
  top: 3px;
  color: #fff;
  font-size:22px;
}

input{
  padding:13px;
  width:100%;
  font-size:10pt;
  padding-right:50px;
  border:none;
}
.input-wrapper{
  position:relative;
  margin:auto;
  width:300px;
}
span.input-submit{
  position:absolute;
  right:15px;
  top:12px;
  color:#888;
}
span.input-submit:hover{
  color:#f13131;
}



</style>
