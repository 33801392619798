
<template>
  <div class="ncbc_classes">

    <div class="container" style="padding:15px; background:rgba(0,0,0,.5);color:#fff;text-align:center;">
      Current Available Classes
      <hr>

      <div class="row">
        <div class="col-12" style="text-align:center;">
          <router-link to="/ncbc/contact" style="color:#fff;">
            Please <u>contact us</u> for any question or info
          </router-link>
        </div>
      </div>

      <div class="row" style="">
        <div class="col-12" style="width:100%; opacity:0.85; padding:0px;">
          <div style="width:100%; text-align:center; display:flex; align-items: center; justify-content: center;">
            <iframe style="width:800px; height:1500px; border:0;" align="center" src="https://docs.google.com/document/d/e/2PACX-1vSd8TUH18un4hoF5UM4gQdwb70m6c5UYK4472M7QCgoWply7iyDvYIWp6ktSC-y_1uB11u__WzUVej1/pub?embedded=true"></iframe>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods:{
  }
}
</script>


<style scoped lang="scss">
body {
  margin: 0;
  padding: 0;
}

.top_menu_a {
  color:#fff;
  font-weight: 400;
  font-size: 18px;
}

</style>
