<template>
  <div class="register">
    <b-container class="form fluid">
    <b-card class="text-center">
      <div class="form-title">Member Sign Up</div>

      <p v-if="errors.length" class="error-text" style="max-width:400px; margin:auto;">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors" :key="error" style="color:red">{{ error }}</li>
        </ul>
      </p>
      <div class="sub-title">Your Information</div>
      <b-row sm="6">
        <b-col>
          <label class="placeholder">
            <b-form-input v-model.trim="first_name" @blur="fCheckFirstName" :state="fFirstNameOk" placeholder=" "></b-form-input>
            <span>Your first name *</span>
            <p v-if="fFirstNameOk!=false">Your first  name</p>
            <b-form-invalid-feedback :force-show="fNameOk==false">First name is required</b-form-invalid-feedback>
          </label>
        </b-col>
         <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model.trim="last_name" @blur="fCheckLastName" :state="fLastNameOk" placeholder=" "></b-form-input>
            <span>Your last name *</span>
            <p v-if="fLastNameOk!=false">Your last name</p>
            <b-form-invalid-feedback :force-show="fLastNameOk==false">Last name is required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="email" @blur="fCheckEmail" :state="fEmailOk" placeholder=" "></b-form-input>
            <span>Email address *</span>
            <p v-if="fEmailOk!=false">This is your login email</p>
            <b-form-invalid-feedback :force-show="fEmailOk==false">Valid email is required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="phone" maxlength="20" placeholder=" "></b-form-input>
            <span>Contact phone #</span>
            <p>Optional: Get notified of any last minute event changes</p>
          </label>
        </b-col>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="password" type="password" @blur="fCheckPassword" :state="fPasswordOk" placeholder=" "></b-form-input>
            <span>Password *</span>
            <p v-if="fPasswordOk!=false">This is your login Password</p>
            <b-form-invalid-feedback :force-show="fPasswordOk==false">Password is required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="6">
          <label class="placeholder">
            <b-form-input v-model="confirm_password" type="password" @blur="fCheckConfirmPassword" :state="fConfirmPasswordOk" placeholder=" "></b-form-input>
            <span>Confirm Password *</span>
            <p v-if="fConfirmPasswordOk!=false">Type in password one more time to confirm</p>
            <b-form-invalid-feedback :force-show="fConfirmPasswordOk==false">Password doesn't match</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>

      <div style="height:50px"></div>
      <b-button variant="outline-secondary" @click="register" style="width:90%; max-width:500px">Register</b-button>

    </b-card>
    </b-container>

    <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input required fields with valid data</b-alert>

  </div>
</template>

<script>
export default {
  data () {
    return {
      errors: [],
      first_name: "",
      fFirstNameOk: null,
      last_name: "",
      fLastNameOk: null,
      email: "",
      fEmailOk: null,
      fNameOk: null,
      password: "",
      fPasswordOk: null,
      confirm_password: "",
      fConfirmPasswordOk: null,
      phone: "",
      showAlert: false,
    }
  },
  created () {
  },
  mounted () {
  },
  methods:{
    register () {
      this.showAlert = false;
      if (!this.fFirstNameOk || !this.fLastNameOk || !this.fEmailOk || !this.fPasswordOk || !this.fConfirmPasswordOk) {
        this.fCheckFirstName();
        this.fCheckLastName();
        this.fCheckEmail();
        this.fCheckPassword();
        this.fCheckConfirmPassword();
        this.showAlert = true;
        return
      }
     this.phone = this.phone.replace(/[^0-9]/g,""); // remove all non-digit
     this.$http.post('/api/member/new',{
        "first_name"  : this.first_name,
        "last_name"  : this.last_name,
        "email" : this.email,
        "phone" : this.phone,
        "password" : this.password,
      }).then(() => {
        //success registering
        alert("Thank you for registering, you will be directed to member portal")
        this.$http.post('/member/sign_in',{
            login: this.email,
            pass: this.password
        }).then(() => {
            this.$router.push({name:'MemberPortal'})
        }).catch(() => {
          alert("Fail to sign in, please try again");
          this.$router.push('/')
        })
      }).catch((error) => {
        //failed to register
        alert("Fail to register, please make sure email has not been registered already.")
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckEmail () {
     // eslint-disable-next-line
     this.fEmailOk = (this.email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())))
    },
    fCheckFirstName () {
      this.fFirstNameOk = this.first_name.trim() != "";
    },
    fCheckLastName () {
      this.fLastNameOk = this.last_name.trim() != "";
    },
    fCheckPassword () {
      this.fPasswordOk = this.password.trim()  != "";
    },
    fCheckConfirmPassword () {
      this.fConfirmPasswordOk = this.confirm_password.trim()!="" && (this.password.trim() == this.confirm_password.trim());
    },
  },
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
.error-text {
  color: #f13131 !important;
}
.form-title {
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 300;
}
.form {
  box-sizing: border-box;
}
.form .sub-title {
  padding: 30px 20px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}
.card, .card-header, .card-body, .card-footer, button{
    border-radius:0px !important;
}
</style>
