<template>
  <div class="check_in">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Member Check In</h5>
        <hr style="margin-bottom:50px;">

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model="member_id" @blur="fCheckMemberId" :state="fMemberIdOk" placeholder=" "></b-form-input>
              <span>Member Id *</span>
              <p v-if="fMemberIdOk!=false">member id</p>
              <b-form-invalid-feedback :force-show="fMemberIdOk==false">Member ID is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model="name" @blur="fCheckName" :state="fNameOk" placeholder=" "></b-form-input>
              <span>Name *</span>
              <p v-if="fNameOk!=false">Member name</p>
              <b-form-invalid-feedback :force-show="fNameOk==false">Name is required to check in</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model="center_id" @blur="fCheckCenterId" :state="fCenterIdOk" placeholder=" "></b-form-input>
              <span>Center ID *</span>
              <p v-if="fCenterIdOk!=false">Center ID</p>
              <b-form-invalid-feedback :force-show="fNameOk==false">Center ID is required</b-form-invalid-feedback>
              </label>
          </p>

          <b-button variant="outline-secondary" @click="createCheckin" style="margin-top:30px; width:80%; max-width:300px">Check In</b-button>
          <b-button variant="outline-secondary" @click="deleteCheckin" style="margin-top:30px; width:80%; max-width:300px">Delete Check In</b-button>
          <b-button variant="outline-secondary" @click="searchCheckin" style="margin-top:30px; width:80%; max-width:300px">Search Check In</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      member_id: '',
      fMemberIdOk: null,
      name: '',
      fNameOk: null,
      center_id: 0,
      fCenterIdOk: null,
      check_in: {},
    }
  },
  methods:{
    createCheckin() {
      if (!this.fMemberIdOk || !this.fNameOk || ! this.fCenterIdOk) {
        this.fCheckMemberId();
        this.fCheckName();
        this.fCheckCenterId();
        return;
      }
      this.$http.post('/api/member/check_in',{
        "member_id" : parseInt(this.member_id),
        "center_id" : parseInt(this.center_id),
        "name" : this.name,
      }).then(() => {
        alert('Member ' + this.name + ' checked in');
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    deleteCheckin() {
      if (!this.fMemberIdOk) {
        this.fCheckMemberId();
        return;
      }
      this.$http.post('/api/member/delete_check_in',{
        "member_id" : parseInt(this.member_id),
      }).then(() => {
        alert('Check in deleted');
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    searchCheckin() {
      if (!this.fMemberIdOk) {
        this.fCheckMemberId();
        return;
      }
      this.$http.get('/api/member/search_check_in?member_id='+parseInt(this.member_id)
      ).then((resp) => {
        this.check_in = resp.data;
        alert('Member is already checked in'); 
        alert(JSON.stringify(this.check_in, null, 4)); // display all data of check in of member
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
 
    fCheckMemberId() {
      this.fMemberIdOk = (this.member_id.trim()!="" && (/^[0-9]+$/.test(this.member_id.trim())));
    },
    fCheckName() {
      this.fNameOk = this.name.trim() != "";
    },
    fCheckCenterId() {
      this.fCenterIdOk = (this.center_id.trim()!="" && (/^[0-9]+$/.test(this.center_id.trim())));
    }
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
