<template>
  <div class="login">
    <div class="box">
      <div class="box-part" id="bp-left">
        <div class="partition" id="partition-register">
          <div class="partition-title">{{ title_caption }}</div>
          <div class="partition-form">
            <form autocomplete="true" @keyup.enter="signin">
              <label class="placeholder">
                <b-form-input v-model.trim="cred.login" @blur="fCheckLogin" :state="fLoginOk" placeholder=" "></b-form-input>
                <span>Login Email</span>
                <p v-if="fLoginOk!=false">Enter your registered email</p>
                <b-form-invalid-feedback :force-show="fLoginOk==false">Valid email required</b-form-invalid-feedback>
              </label>
              <br>
              <label class="placeholder">
                <b-form-input type="password" v-model.trim="cred.pass" @blur="fCheckPass" :state="fPassOk" placeholder=" "></b-form-input>
                <span>Password</span>
                <b-form-invalid-feedback :force-show="fPassOk==false">Password required</b-form-invalid-feedback>
              </label>
            </form>

            <div style="margin-top:42px;"></div>

            <button class="large-btn signin-btn" @click="signin">Sign In</button>

            <div class="button-set">
              <button id="forgotPw-btn" @click="forgotPw">Forgot Password</button>
              <button @click="register">Register</button>
            </div>
          </div>
        </div>
      </div>
      <div class="box-part" style="width:50%; float:right;" id="bp-right">
        <div class="box-messages">
        </div>
        <!-- <img alt="logo" width=50px src="../assets/images/logo_white_sm.png" style="position:absolute;right:20px; bottom:20px"> -->
      </div>
    </div>

    <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input all fields with valid data</b-alert>

  </div>
</template>

<script>
import userAPI from '../api/user.js'

export default {
  props: {
    'redirect': {  //if login is used within other page.
      type: String,
      default: "",
    },
    'login_only': {  //if login is used within other page.
      type: Number,
      default:0,
    },
    'title_caption': {
      type: String,
      default: 'Member Sign In'
    },
    'badminton_united': {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cred: {
        login: '',
        pass: ''
      },
      showAlert: false,
      fLoginOk: null,
      fPassOk: null,
    }
  },
  mounted () {
    if (this.login_only) return;

    userAPI.getHeaderData(
      (user) => {
        this.$store.commit('logging_in', user.login)
        this.$store.commit('set_user_roles', user.roles)
        this.$store.commit('set_member_id', user.member_id)
        this.$router.push({name: 'MemberPortal'})
      },
      () => {
        this.$store.commit('logging_out')
      },
    )
  },
  methods: {
    signin () {
      this.showAlert = false;
      this.fCheckLogin();
      this.fCheckPass();
      if (!this.fLoginOk || !this.fPassOk) {
        this.showAlert = true;
        return
      }

      //just skip this for BU for now
      if (this.badminton_united) {
        alert("Sorry, login/password is incorrect");
        return
      }

      this.$http.post('/member/sign_in',
        this.cred
      ).then(() => {
        if (this.login_only) {
          if (this.redirect!="") {
            window.location = this.redirect;
          }else{
            location.reload();
          }
        }else{
          this.$router.push({name:'MemberPortal'})
        }
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    forgotPw () {
      let email = prompt("Please enter your login email", "");
      if (email == null) return

      // eslint-disable-next-line
      if (!(email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())))) {
        alert("Invalid email")
        return
      }

      this.$http.put('/api/member/forget_password?e='+email.trim(),
      ).then(() => {
        alert('Please check your email for information to reset your password. Check junk folder if you cannot find the email.')
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckLogin () {
     // eslint-disable-next-line
     this.fLoginOk = (this.cred.login.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.cred.login.trim())))
    },
    fCheckPass () {
      this.fPassOk = this.cred.pass.trim() != "";
    },
    register () {
      if (this.badminton_united) {
        alert("Please contact us at contact@badmintonunited.org");
        return
      }

      this.$router.push({name:'RegisterForm'})
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$signin_color: #dba226;

.box {
  background: white;
  overflow: hidden;
  max-width: 656px;
  height: 400px;
  border-radius: 0px;
  box-sizing: border-box;
  box-shadow: 0 0 1px black;
  color: #8b8c8d;
  margin: auto;
}
.box-part {
  display: inline-block;
  position: relative;
  vertical-align: top;
  box-sizing: border-box;
  height: 100%;
}

.box-messages {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

#bp-right{
    background: url('../assets/images/login_bp.jpg') no-repeat top left;
    border-left: 1px solid #eee;
}

.partition {
  width: 100%;
  height: 100%;
}
.partition .partition-title {
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 300;
}
.partition .partition-form {
  padding: 0 20px;
  box-sizing: border-box;
}

button {
  background: white;
  border-radius: 0px;
  box-sizing: border-box;
  padding: 10px;
  letter-spacing: 1px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  min-width: 140px;
  margin-top: 8px;
  color: #8b8c8d;
  cursor: pointer;
  border: 1px solid #dddedf;
  text-transform: uppercase;
  transition: 0.1s all;
  font-size: 10px;
  &:hover {
    border-color: mix(#dddedf, black, 90%);
    color: mix(#8b8c8d, black, 80%);
  }
}

.button-set {
    margin-bottom: 8px;
}

.large-btn {
  width: 100%;
  background: white;
  span {
    font-weight: 600;
  }
  &:hover {
    color: white !important;
  }
}

#register-btn,
#forgotPw {
  margin-left: 8px;
}

.signin-btn {
  border-color: $signin_color;
  color: $signin_color;
  &:hover {
    border-color: $signin_color;
    background: $signin_color;
  }
}
</style>

