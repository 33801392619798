<template>
  <div class="admin_list_product">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>{{ title }}</h5>
      <div class="container">
        <b-form-select v-model="selected_product_id" :options="all_product" text-field="product_summary" value-field="id" @change="selectedProduct" :select-size="num_list_items"></b-form-select>
      </div>
      <div class="t-3">Selected: <strong>{{ selected_product_id }}</strong></div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    'num_list_items': {  //how many list items are visible; 1=droplist >1=select list
      type: Number,
      default: 1,
    },
    'empty_option': {  //should there be a empty option with value of 0
      type: Boolean,
      default: true,
    },
    'title': {
      type: String,
      default: 'Product',
    },
    'selected_value': {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_product_id: null,
      all_product: [],
    }
  },
  mounted () {
    this.getProduct();
  },
  components: {
  },
  computed: {
    selectedOption() {
      return this.all_product.find(option => option.id == this.selected_product_id)
    }
  },
  methods:{
    getProduct () {
      this.$http.get('/api/admin/list_product'
      ).then((resp) => {
        console.log('product',resp.data)
        this.all_product = resp.data.all_product;
        this.all_product.forEach( (el,i) => this.all_product[i].product_summary = el.name + ': ' + el.description)
        if (this.empty_option) this.all_product.unshift({'id':0,'product_summary':''})
        if (this.selected_value>0) this.selected_product_id = this.selected_value
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    selectedProduct () {
      let selected = this.selectedOption
      this.$emit('product_selected',{
        'id': parseInt(this.selected_product_id),
        'name': selected.name,
        'attribute_id1': selected.attribute_id1,
        'attribute_id2': selected.attribute_id2,
        'attribute_id3': selected.attribute_id3,
        'attribute_id4': selected.attribute_id4,
        'attribute_id5': selected.attribute_id5
      })
    },
  },
}
</script>
<style scoped lang="scss">
</style>
