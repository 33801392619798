<template>
  <div class="admin_queue_system">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Court Queues ({{ clientId }})</h5>
        <div class="d-flex flex-wrap">
          <!-- <div class="row m-2 p-3" v-for="c in world.courts" style="font-size:9pt; border-width:1px; border-bottom-style:solid; max-width:350px;"> -->
          <!-- </div> -->
        </div>
      <hr>
      <h5>Court Configs</h5>
      <div class="d-flex flex-wrap">
        <div class="row m-2 p-3" v-for="c in world.courts" :key="c.id" style="font-size:9pt; border-width:1px; border-bottom-style:solid; max-width:350px;">
          <div class="col-2">
            {{ c.id }}
          </div>
          <div class="col-10" style="text-align:left">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="c.is_single_court">
              <label class="form-check-label">Single Court</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="c.is_timed_court">
              <label class="form-check-label">Timed Court</label>
            </div>
          </div>

          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            Reserve: Coming soon
          </div>
        </div>
      </div>
      <hr>
      <button type="button" class="btn btn-outline-primary" @click="requestClientRefresh()">refresh clients</button>
      <hr>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" @click="setDisableSS()" v-model="world.disable_screen_saver" id="checkDisableSS">
        <label class="form-check-label" for="checkDisableSS">
          Disable View Station Screen Saver (11PM - 4PM)
        </label>
      </div>
      <hr>
      <div id="queueLog" style="width:90%; height:300px; background:#fff; text-align:left; margin:auto; overflow:scroll; font-size:12px"></div>
    </div>
  </div>

</template>

<script>
  import datetime_mixin from '../../mixins/datetime.js'
  import {Messages} from '../../assets/js/queue_system_messages.js';

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      world: {},
      clientId: "",
      ws: {}, // websocket connection
      server_ip: "",
    }
  },
  mounted () {
    //this.server_ip = "queue-system.mybluemix.net";
    this.server_ip = "queue-system.norcalbadminton.com";
    //this.server_ip = "localhost:3031";
    this.connect_ws();
  },
  components: {
  },
  methods:{
    connect_ws() {
      if (this.server_ip==null || this.ws.readyState == 1 || this.ws.readyState == 0) { //OPEN or CONNECTING
        console.log("exit connect_ws")
        return;
      }
      //var uri = 'ws://'+this.server_ip+':3031/ws_connect';
      var uri = 'ws:';
      var loc = window.location;

      if (loc.protocol === 'https:') {
        uri = 'wss:';
      }
      uri = uri+'//'+this.server_ip+'/ws_connect';

      this.ws = new WebSocket(uri,null,null,null, {rejectUnauthorized: false}) //https://stackoverflow.com/questions/38242334/disable-websocket-certificate-validation
//      this.ws = new WebSocket(uri, {
//        rejectUnauthorized: false
//      })

      var self = this
      this.ws.onopen = () => {
        console.log('Connected')
      }
      this.ws.onerror = function(e) {
        //this.server_ip = prompt("Enter server ip", "Server ip");
        //if (this.server_ip!=null) this.connect_ws()
        console.error('Socket encountered error: ', e.message, 'Closing socket');
        self.ws.close();
      }

      this.ws.onclose = function() {
          console.log('Disconnected!');
          self.connect_ws() //connect now then try again just in case connection fail
          setTimeout(function() {
            self.connect_ws()
          }, 2000);
      };

      this.ws.onmessage = function(evt) {
        //might get more than 1 msg at once
        //need to break up msgs
        console.log('got msg ',evt.data)
        var msgArray = evt.data.split(Messages.SPACER); //seperate potential multiple msg from server
        for (let i = 0; i < msgArray.length; i++) {
          var d = JSON.parse(msgArray[i])


          if (d.msg_type==2) { //worldmap sent from server
            self.world  = JSON.parse(d.body)

            //get log messages
            var l = document.getElementById('queueLog')
            console.log("l",self.world.log_msg)
            l.innerHTML += self.world.log_msg.replace("\n","<br>")

          }else if (d.msg_type==3) { //server send client id
            self.clientId = JSON.parse(d.body).id
            self.ws.send(Messages.RegisterAdminClient(self.clientId)); // register admin client with server here
            self.ws.send(Messages.RequestWorldMap(self.clientId)); //got id, request worldmap
          }
        }
      }
    },
    requestClientRefresh(){ //tell server to refresh all clients to get new updates
      console.log('sending client refresh request')
      this.ws.send(Messages.RequestAllClientRefresh(this.clientId));
    },
    setDisableSS(){ //disable or enable viewing station screen saver. defaults runs at 11pm-4pm
      if (document.getElementById('checkDisableSS').checked) {
        this.ws.send(Messages.RequestDisableSS(this.clientId));
      } else {
        this.ws.send(Messages.RequestEnableSS(this.clientId));
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
