<template>
  <div class="staff">

    <h5 style="color:#fff">Staff Member Event Search</h5>

    <div class="container" style="padding:20px 0;">
      <div style="text-align:center; margin:auto">
        <searchMember v-on:memberSelected="memberSelected($event)"></searchMember>
      </div>
    </div>

    <div class="container" style="padding:20px 0;">
      <div style="text-align:center; margin:auto">
        <upcomingEvents v-on:upcoming_event_selected="eventSelected($event)"></upcomingEvents>
      </div>
    </div>

    <div v-if="is_user_found" class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <div style="text-align:center; margin:auto">
        <memberEvents :predefined_id="member_id"></memberEvents>
      </div>
    </div>


    <div v-if="is_user_found" class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Search for event</h5>

      <label class="placeholder">
        <b-form-input v-model.trim="id" placeholder=" "></b-form-input>
        <span>Event Id</span>
      </label>

      <template>
        <b-button size="sm"  @click="getEventDetails" style="margin:3px">Search Event</b-button>
      </template>
    </div>


    <div v-if="is_user_found" style="width:100%;">
      <div class="container" style=" background:rgba(255,255,255,.9); padding:20px 0;">

        <div class="container" style="max-width:600px; position:relative; text-align:left;">
          <h5 v-if="is_data_loaded">{{ this.data.event.name }}</h5>
          <span v-if="is_data_loaded"><b>Date: </b>{{ this.data.event.event_date.split("T")[0] }}</span>
          <br><br>
          <p v-if="is_data_loaded" v-html="data.event.description">
            <br>
          </p>
          <span v-if="is_data_loaded">
            <b>Price: </b>
            ${{ this.data.event.reserve_interval_price * (this.data.event.min_reserve_time / this.data.event.reserve_interval) }} for {{this.data.event.min_reserve_time }} minutes
            <span v-if="this.data.event.min_reserve_time != this.data.event.reserve_interval" style="font-size=.8em">
              , (${{this.data.event.reserve_interval_price}} / {{this.data.event.reserve_interval }} minutes)
            </span>
            <br><br>
          </span>
          <span v-if="is_data_loaded && has_min_reserve_time">
            <b>Minimum reserve time: </b>{{ this.data.event.min_reserve_time }} minutes
            <br><br>
          </span>
          <span v-if="is_data_loaded && has_max_member">
            <b>Max capacity:</b>{{ this.data.event.max_member }} people
          </span>
        </div>

        <div class="container" style="overflow-x:auto; white-space:nowrap; position:relative;padding:10px;">
          <div v-for="(d, di) in court_ids" :key="d" :style="courtTableStyle()">
            <div style="height:20px"></div>
            <em>Court {{d}}</em>
            <div style="height:20px"></div>
            <div v-for="(t,ti) in available[di]" :key="t" style="mdargin-top:10px;" >
              <b-button @click="popup(ti, di)" class="shadow-sm" variant="outline-primary" size="sm" style="width:80px;">
                {{t}}
              </b-button>
              <div style="height:10px"></div>
            </div>
            <div style="height:20px"></div>
          </div>
          <div style="display:clear"></div>
        </div>

      </div>
    </div>

    <b-modal id="modal-booking" scrollable centered :title="`Court ${court_ids[selectedSlot.selected_court_id]}`" ref="eventBooking" button-size="sm">
      <p class="my-4" v-if="is_data_loaded">
        Date: <b>{{ data.event.event_date.split("T")[0] }}</b>
      </p>
      <p class="my-4">
        Starting:<br>
        <b>{{ selectedStart }}</b>
      </p>
      <p class="my-4">
        Ending:<br>
        <b-form-select v-model="booking_end_time" :options="available_ending" class="mb-3">
        </b-form-select>
      </p>

      <template #modal-footer>
        <b-button variant="success" @click="book_now(selectedSlot)">
          Confirm Booking
        </b-button>
      </template>
    </b-modal>


  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import searchMember from './member_search.vue';
import memberEvents from '../members/member.vue';
import upcomingEvents from './upcoming_events.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    searchMember,
    memberEvents,
    upcomingEvents
  },
  data() {
    return {
      fields: [
       {
          key: 'event_name',
          label: 'Event',
          sortable: true,
       },
       {
          key: 'event_date',
          sortable: true,
          tdAttr: {style: "min-width:130px;"},
          formatter: (v) => { return (v).split("T")[0] },
        },
        {
          key: 'start_end_time',
          label: 'Time',
        },
      ],
      user: {},
      id: 0,
      is_user_found: false,
      data: {},  // event details
      selectedSlot: {},
      court_ids: [],
      available: [],
      selectedStart: "",
      available_ending: [],
      booking_end_time: "",
    }
  },
  mounted () {
  },
  computed: {
    member_id () {
//      return this.$store.state.userStore.member_id  // not used
      return this.user.id
    },
    has_min_reserve_time () {
      return (this.data.event.min_reserve_time>0);
    },
    has_max_member () {
      return (this.data.event.has_max_member>0);
    },
    is_data_loaded () {
      return this.data.event
    },
  },
  methods:{
    eventSelected (event) {
      console.log('Event ID: '+event.id)
    },
    memberSelected (member) {
      this.user = member;
      this.is_user_found = true;
      // this.getReservedEvents();
    },
    getEventDetails () {
      //this.$http.get('/api/booking/event_details?id='+this.$route.params.id
      this.$http.get('/api/booking/event_details?id='+this.id
      ).then((resp) => {
        this.data = resp.data;
        let slots = this.TimeRangeTo30MinSlots(this.data.event.start_time,this.data.event.end_time);
        this.court_ids = this.data.event.court.split(',');
        let eTime;
        let openings;
        let booked = false;
        for (var i = 0; i < this.court_ids.length; i++) {
          eTime = this.data.event.start_time;
          openings=[];
          for (var j = 0; j < slots; j++) {
            //make sure enough time for reservation before event ends
            if (this.TimeRangeDiffInMins(eTime,this.data.event.end_time)>=this.data.event.min_reserve_time) {
              //loop thru booked sessions, make sure no body booked this hour
              for (var x = 0; x < this.data.sessions.length; x++) {
                booked = false;
                if (this.data.sessions[x].court_id == i+1) { //booked session is for the same court
                  //try to see if booking time+1hr overlaps with reserved court,
                  booked = this.TimeRangeOverlap(eTime,this.H24Add60Min(eTime),this.data.sessions[x].start_time,this.data.sessions[x].end_time)
                  if (booked) break;
                }
              }
              if (!booked) {
                openings.push(this.H24toH12(eTime))
              }
            }
            eTime = this.H24Add30Min(eTime)
          }
          this.available.push(openings)
        }
      }).catch((error) => {
        console.log(error);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    courtTableStyle () {
      let play_time = (new Date(this.data.event.end_time).getHours() - new Date(this.data.event.start_time).getHours())*60
      play_time += new Date(this.data.event.end_time).getMinutes() - new Date(this.data.event.start_time).getMinutes();
      let play_slots = play_time / this.data.event.reserve_interval;
      return {
        width: `${95/this.court_ids.length}%`,
        "min-width":"88px",
        "max-width":"120px",
        background:"#e6f0f5",
        height:`${play_slots * 50 + 100}px`, //100 is padding from court id
        margin:"2px",
        "margin-top":"10px",
        position:"relative",
        display:"inline-block",
        border: "1px solid #cee1eb",
        "vertical-align": "top"
      }
    },
    popup (selected_slot_id, court_id) {
      this.selectedStart = this.available[court_id][selected_slot_id];
      this.available_ending = []
      let chkTime = this.H12toH24(this.selectedStart)
      chkTime = (this.data.event.reserve_interval == 30)? chkTime = this.H24Add30Min(chkTime) : this.H24Add60Min(chkTime)
      let bookingConflict = false
      while (this.TimeRangeDiffInMins(chkTime, this.data.event.end_time) >= 0 && !bookingConflict) {
        if (this.TimeRangeDiffInMins(this.data.event.start_time, chkTime) >= this.data.event.min_reserve_time) {
          let selectedStartH24 = this.H12toH24(this.selectedStart)
          if (this.TimeRangeDiffInMins(selectedStartH24, chkTime) >= this.data.event.min_reserve_time) {
            //now before pushing, check if this slot conflict with booked court
            for (var x = 0; x < this.data.sessions.length; x++) {
              bookingConflict = false
              if (this.data.sessions[x].court_id == this.court_ids[court_id]) { //booked session is for the same court
                //try to see if booking time+1hr overlaps with reserved court,
                bookingConflict = this.TimeRangeOverlap(selectedStartH24,chkTime,this.data.sessions[x].start_time,this.data.sessions[x].end_time)
                if (bookingConflict) break;
              }
            }
            if (!bookingConflict) this.available_ending.push(this.H24toH12(chkTime))
          }
        }
        chkTime = (this.data.event.reserve_interval == 30)? chkTime = this.H24Add30Min(chkTime) : this.H24Add60Min(chkTime)
      }
      this.selectedSlot.selected_court_id = court_id;
      this.booking_end_time = this.available_ending[0];
      this.$refs.eventBooking.show()
    },
    book_now () {
      this.$http.post('/api/booking/book_session',
        {
          center_id: this.data.event.center_id,
          email: this.user.email,
          member_id: parseInt(this.user.id),
          court_id: this.court_ids[this.selectedSlot.selected_court_id],
          event_id: this.data.event.id,
          start_time: this.H12toH24(this.selectedStart),
          end_time: this.H12toH24(this.booking_end_time),
          event_date: this.data.event.event_date,
        }
      ).then(() => {
        this.$bvModal.msgBoxOk('Court '+this.court_ids[this.selectedSlot.selected_court_id]+' is reserved at '+ this.selectedStart + ' - '+ this.booking_end_time + ', manage your reservations in member portal.',{
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err)
        })
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
  },
}
</script>
