<template>
  <div class="staff">

    <h5 style="color:#fff">Member Management</h5>

    <div class="container" style="padding:5px; background:#fff;">
      <div style="text-align:center; margin:auto;">
        <b-button v-b-modal.modal-search class="m-3 btn-info btn-sm">Search Member</b-button>
        <b-button v-b-modal.modal-register class="m-3 btn-info btn-sm">Register New Member</b-button>
      </div>
    </div>

    <div style="height:10px"></div>

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <div class="mx-auto" style="text-align:center; width:600px">
        <span class="m-3"><b-button v-b-modal.modal-member-history variant="link">History</b-button></span>
        <span class="m-3">MEMBER INFO</span>
        <span class="m-3"><b-button v-b-modal.modal-camera class="m-3" variant="outline-info" size="sm">Launch camera</b-button></span>
      </div>
      <div class="row m-3" style="text-align:left">
        <div class="col" style="width:33%">
          <div>
            <label class="placeholder">
              <b-form-input v-model.trim="user.first_name" @blur="fCheckFirstName" :state="fFirstNameOk" placeholder=" "></b-form-input>
              <span>Your first name *</span>
              <p v-if="fFirstNameOk!=false">Enter your first name*</p>
              <b-form-invalid-feedback :force-show="fFirstNameOk==false">Valid name required</b-form-invalid-feedback>
            </label>
          </div>
          <div>
            <label class="placeholder">
              <b-form-input v-model.trim="user.last_name" @blur="fCheckLastName" :state="fLastNameOk" placeholder=" "></b-form-input>
              <span>Your last name *</span>
              <p v-if="fLastNameOk!=false">Enter your last name*</p>
              <b-form-invalid-feedback :force-show="fLastNameOk==false">Valid name required</b-form-invalid-feedback>
            </label>
          </div>
          <div>
            <label class="placeholder">
              <b-form-input v-model="user.email" placeholder=" "></b-form-input>
              <span>Email address *</span>
              <p>Member's contact email*</p>
            </label>
          </div>
          <div>
            <label class="placeholder">
              <b-form-input v-model.trim="user.phone" @blur="fCheckPhone" :state="fPhoneOk" maxlength="20" placeholder=" "></b-form-input>
              <span>Contact phone #</span>
              <p v-if="fPhoneOk!=false">Enter contact phone #</p>
              <b-form-invalid-feedback :force-show="fPhoneOk==false">Phone not valid</b-form-invalid-feedback>
            </label>
          </div>
        </div>
        <div class="col" style="width:33%">
          <div>
            <span class="info_title">Member ID</span>
            <span class="info_body">{{ user.id }}</span><br>
          </div>
          <div>
            <span class="info_title">City</span>
            <span class="info_body">{{ user.city }}</span><br>
          </div>
          <div>
            <span class="info_title">Checked In</span>
            <span class="info_body" style="color:green">{{ user.checked_in_time }}</span><br>
          </div>
          <div>
            <span class="info_title">Member Type and Expires</span>
            <span class="info_body">
                <p v-if="user.paid_member_type == 1">GOLD Pass ({{ ISOZtoShortDate(user.member_expires) }})</p>
                <p v-else-if="user.paid_member_type == 2">STANDARD Pass ({{ ISOZtoShortDate(user.member_expires) }})</p>
                <p v-else>None</p>
            </span>
          </div>
          <div>
            <span class="info_title">Free Passes</span>
            <span class="info_body" style="color:green">{{ user.free_pass }}</span><br>
          </div>
          <div style="margin-top:25px">
            <label class="placeholder">
              <b-form-input v-model.trim="user.member_code" maxlength="10" placeholder=" "></b-form-input>
              <span>Code #</span>
              <p>Enter code from membership card</p>
            </label>
          </div>
        </div>
        <div class="col" style="">
          <img id="memberPhoto" style="width:90%" src="" />
        </div>
      </div>
      <div class="mx-auto d-flex justify-content-between" style="width:500px">
        <b-button squared variant="outline-secondary" size="sm" @click="updateMemberInfo">Update Member Info</b-button>
        <b-button squared variant="outline-secondary" size="sm" @click="updateMemberPhoto">Update Photo</b-button>
        <b-button squared variant="outline-secondary" size="sm" @click="updateMemberInfoPhoto">Update Info & Photo</b-button>
      </div>
    </div>

    <div v-if="is_user_found" class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <div>
      </div>

      <hr>
      <h5>Select Monthly Type</h5>

      <template>
        <b-button size="sm"  @click="setMonthlyType1" style="margin:3px">Gold Pass</b-button>
        <b-button size="sm"  @click="setMonthlyType2" style="margin:3px">Standard Pass</b-button>
      </template>
    </div>

    <b-modal id="modal-camera" hide-footer size="lg" title="Camera">
      <systemCamera v-on:photo="photoTaken($event)"></systemCamera>
    </b-modal>

    <b-modal id="modal-search" hide-footer size="lg" title="Member Search">
      <searchMember v-on:memberSelected="memberSelected($event)"></searchMember>
    </b-modal>

    <b-modal id="modal-register" hide-footer size="lg" title="New Register">
      <registerMember v-on:memberRegistered="memberRegistered($event)"></registerMember>
    </b-modal>

    <b-modal id="modal-member-history" hide-footer size="lg" title="History">
      <memberHistory :member_id=user.id></memberHistory>
    </b-modal>
  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js';
import searchMember from './member_search.vue';
import registerMember from './member_register.vue';
import systemCamera from '../reusable/system_camera.vue';
import memberHistory from '../reusable/member_history.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    searchMember,
    registerMember,
    systemCamera,
    memberHistory,
  },
  data() {
    return {
      user: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        member_code: "",
      },
      is_user_found: false,
      paid_member_type: 0,
      fFirstNameOk: null,
      fLastNameOk: null,
      fPhoneOk: null,
    }
  },
  mounted () {
  },
  computed: {
    member_id () {
//      return this.$store.state.userStore.member_id  // not used
      return this.user.id
    },
    is_data_loaded () {
      return this.data.event
    },
  },
  methods:{
    memberSelected (member) {   // member selected from search list
      this.user = member;
      var img = document.getElementById("memberPhoto");
      img.src = member.photo

      this.is_user_found = true;
      this.$bvModal.hide('modal-search')
    },
    memberRegistered (member) { // member from register
      this.user = member;
      this.is_user_found = true;
      var img = document.getElementById("memberPhoto");
      img.src = member.photo
    },
    setMonthlyType1 () {
      this.$bvModal.msgBoxConfirm('Charge $85 for Gold membership ...',{
          title: 'Please Confirm',
          okVariant: 'info',
          okTitle: 'confirm',
          cancelTitle: 'cancel',
          centered: true
        }).then(confirm => {
          if (!confirm) return;
          this.paid_member_type = 1;
          this.setMonthly();
        })
    },
    setMonthlyType2 () {
      this.$bvModal.msgBoxConfirm('Charge $30 for Standard membership ...',{
          title: 'Please Confirm',
          okVariant: 'info',
          okTitle: 'confirm',
          cancelTitle: 'cancel',
          centered: true
        }).then(confirm => {
          if (!confirm) return;
          this.paid_member_type = 2;
          this.setMonthly()
        })
    },
    setMonthly () {
      this.user.id = parseInt(this.user.id) //make sure id is int for server to parse
      if (this.user.id <= 0) return;

      this.$http.post('/api/staff/set_monthly_type',
        {
          id : this.user.id,
          paid_member_type : this.paid_member_type,
        }
      ).then((resp) => {
        console.log('resp 1',resp)
        let m = (resp.data.paid_member_type==2)? "Standard":"Gold"
        this.user.member_expires = resp.data.member_expires
        this.user.paid_member_type = resp.data.paid_member_type

        alert(this.user.email + " set to paid monthly member type " + m);
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    photoTaken(e) {
      var img = document.getElementById("memberPhoto");
      img.src = e
      this.$bvModal.hide('modal-camera')
    },
    fCheckFirstName () {
      this.fFirstNameOk = (this.user.first_name.trim()!="" && (/^[a-zA-Z- ]+$/.test(this.user.first_name.trim())));
    },
    fCheckLastName () {
      this.fLastNameOk = (this.user.last_name.trim()!="" && (/^[a-zA-Z- ]+$/.test(this.user.last_name.trim())));
    },
    fCheckPhone () {
      if (this.user.phone.length > 0)
      //  this.fPhoneOk = (this.user.phone.trim()!="" && (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.user.phone.trim())));
        this.fPhoneOk = (this.user.phone.trim()!="" && (/^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/.test(this.user.phone.trim())));
      else
        this.fPhoneOk = true;
    },
    updateMemberInfo () {
      if (this.user.id==0) {
        alert("Please select/search a member")
        return;
      }

      this.user.id +=""; //turn id to string for server to bind
      this.user.phone = this.user.phone.replace(/[^0-9]/g,""); // remove all non-digit
      this.$http.post('/api/staff/update_profile_by_staff', this.user
      ).then((resp) => {
        if (resp.data.error)
          alert(resp.data.error);
        if ( resp.data.updated.id != "")
          this.user = resp.data.updated;
          this.makeToast("Profile updated", 'success');
      }).catch((error) => {
        this.makeToast('Update failed: '+ error.response.data.message,'danger');
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    updateMemberPhoto () {
      if (this.user.id==0) {
        alert("Please select/search a member")
        return;
      }

      var img = document.getElementById("memberPhoto");
      if (img.src=="") return;

      this.user.id +=""; //turn id to string for server to bind

      this.$http.post('/api/staff/update_member_photo_by_staff',{
        "id" : this.user.id,
        "photo" : img.src,
      }).then(() => {
        this.makeToast("Member photo updated", 'success');
      }).catch((error) => {
        this.makeToast('Update failed: '+ error.response.data.message,'danger');
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    updateMemberInfoPhoto () {
      if (this.user.id==0) {
        alert("Please select/search a member")
        return;
      }

      this.user.id +=""; //turn id to string for server to bind
      this.user.phone = this.user.phone.replace(/[^0-9]/g,""); // remove all non-digit
      var img = document.getElementById("memberPhoto");
      if (img.src=="") {
        alert('No member photo');
        return
      }

      this.user.photo = img.src
      this.$http.post('/api/staff/update_profile_and_photo_by_staff', this.user
      ).then(() => {
        this.makeToast("Member info & photo updated", 'success');
      }).catch((error) => {
        this.makeToast('Update failed: '+ error.response.data.message,'danger');
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    makeToast(msg, variant = null) {
      this.$bvToast.toast(msg, {
        title: `Status`,
        variant: variant,
        solid: true
      })
    },
  }
}
</script>

<style scoped lang="scss">
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
  color: #006bb3;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:read-only, .placeholder>textarea:read-only {
  color: #000;
  background: transparent;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
