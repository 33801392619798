<template>
  <div class="system_member_check_in">
    <div style="height:50px"></div>

    <div style="width:100%;">
      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Scan Membership Card</h5>
        <hr style="margin-bottom:50px;">

        <div class='mx-auto' style="text-align:center; width:550px">
          <qrScanner ref="scanner" :stopAtScan=false :showInput=false v-on:code_detected="memberCodeDetected($event)"></qrScanner>
        </div>
      </b-card>
      </b-container>

    </div>

    <!-- <b-modal id="modal-msgbox" ref="modal-msgbox" centered hide-footer title="INFO"> -->
    <!--   <div style="text-align:center"> -->
    <!--     <img id="memberPhoto" style="width:300px" :src=member_photo> -->
    <!--     <hr> -->
    <!--     <div class="mb-auto">{{ login_msg }}</div> -->
    <!--   </div> -->
    <!-- </b-modal> -->

    <checkinTool ref="checkin" v-on:checkin_succeeded="onMemberCheckedin($event)" v-on:reserved_checkin_succeeded="onReservedCheckedin($event)" :staff_use=true></checkinTool>

    <queueSystem ref="queue"></queueSystem>

    <!-- <b-modal id="modal-warning" ref="modal-warning" centered header-bg-variant="danger" hide-footer title="Attention"> -->
    <!--   <div style="text-align:center"> -->
    <!--     <div class="mb-auto">{{ login_msg }}</div> -->
    <!--   </div> -->
    <!-- </b-modal> -->
  </div>
</template>

<script>

import qrScanner from '../reusable/qr_scanner.vue';
import queueSystem from '../reusable/queue_system.vue';
import checkinTool from '../reusable/membership_checkin_tool.vue';


export default {
  components: {
    qrScanner,
    queueSystem,
    checkinTool,
  },
  data() {
    return {
      member_code: '',
      fMemberIdOk: null,
      check_in: {},
      login_msg: "",
      member_photo: "",
      today: "",
      canScan: false,
      eventId: 0,
    }
  },
  mounted() {
    window.addEventListener("keypress", function(e) {
      if (e.keyCode >=48 && e.keyCode <=57) {
        if (this.member_code.length < 8) this.member_code = this.member_code + e.key
      } else if (e.keyCode == 13) {
        //this.createCheckin();
        this.memberCodeDetected(this.member_code);
      }
    }.bind(this));

    if (this.today=="") {
      this.today = this.getToday();
    }
    this.today = '2021-10-29';


    this.getTodayFirstOpenEvent();
  },
  methods:{
    getToday() {
      let d=new Date()
      return d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()
    },
    getTodayFirstOpenEvent () {
      this.$http.get('/api/system/get_today_first_open_event?d='+this.today
      ).then((resp) => {
        this.eventId = +resp.data; //turn to in

        if (this.member_code != '') {
          this.memberCodeDetected(this.member_code)
        }else {
          this.canScan = true;
        }
      }).catch(() => {
        console.log("Scanner not ready!")
      }).finally(() => {
        this.member_code = '';
      })
    },
    setInputFocus () {
      this.$refs.id_input.focus()
    },
    inputKeyPressed (k) {
      console.log(k)
    },
    memberCodeDetected (code) {
      if (this.eventId<=0) return;

      this.member_code = code

      let tmpToday = this.getToday()

      if (this.today != tmpToday) { //update today if not current
        this.today = tmpToday
        this.getTodayFirstOpenEvent()
        return;
      }

      var c = this.$refs.checkin

      if (!c.isScanReady() || !this.canScan) return;


      c.createCheckin(this.member_code,this.eventId)
      this.member_code = '';
    },
    onMemberCheckedin(e) {
      this.$refs.queue.create_queue_entry(e.member_name,e.member_id)
    },
  },
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
