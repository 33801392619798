<template>
  <div class="admin_manage_attribue">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Admin Manage Attribute</h5>
        <hr style="margin-bottom:50px;">

          <div class="container" style="padding:20px 0;">
            <div style="text-align:center; margin:auto">
              <productCategory v-on:category_selected="categorySelected($event)"></productCategory>
            </div>
          </div>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="name" @blur="fCheckName" :state="fNameOk" placeholder=" "></b-form-input>
              <span>Attribute Name *</span>
              <p v-if="fNameOk!=false">Name of product aottribute</p>
              <b-form-invalid-feedback :force-show="fNameOk==false">Name is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="help_text" @blur="fCheckHelpText" :state="fHelpTextOk" placeholder=" "></b-form-input>
              <span>Help Text *</span>
              <p v-if="fHelpTextOk!=false">Description of attribute</p>
              <b-form-invalid-feedback :force-show="fHelpTextOk==false">Help Text is required</b-form-invalid-feedback>
              </label>
          </p>

          <b-button variant="outline-secondary" @click="createAttribute" style="margin-top:30px; width:80%; max-width:300px">Create Product Attribute</b-button>

          <div class="container" style="padding:20px 0;">
            <div style="text-align:center; margin:auto">
              <listAttribute v-on:attribute_selected="attributeSelected($event)"></listAttribute>
            </div>
          </div>

          <b-button variant="outline-secondary" @click="editAttribute" style="margin-top:30px; width:80%; max-width:300px">Edit Attribute</b-button>
          <b-button variant="outline-secondary" @click="deleteAttribute" style="margin-top:30px; width:80%; max-width:300px">Delete Attribute</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
import productCategory from './list_category.vue';
import listAttribute from './list_attribute.vue';

export default {
  components: {
    productCategory,
    listAttribute,
  },
  data() {
    return {
      name: '',
      fNameOk: null,
      help_text: '',
      fHelpTextOk: null,
      category_id: "0",
      attribute_id: "0",
    }
  },
  methods:{
    categorySelected (category) {
      this.category_id = category.id
    },
    attributeSelected (attribute) {
      this.attribute_id = attribute.id
    },
    createAttribute() {
      if (!this.fNameOk || !this.fHelpTextOk) {
        this.fCheckName();
        this.fCheckHelpText();
        alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/new_attribute',{
        "name" :        this.name,
        "help_text" :   this.help_text,
        "category_id" : parseInt(this.category_id),
      }).then(() => {
        alert('Product attribute "' + this.name + '" has been created');
        this.name = ""; //to prevent double submitting
        location.reload();
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    editAttribute() {
      if (!this.fNameOk || !this.fHelpTextOk) {
        this.fCheckName();
        this.fCheckHelpText();
        alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/edit_attribute',{
        "id"   :        parseInt(this.attribute_id),
        "name" :        this.name,
        "help_text" :   this.help_text,
        "category_id" : parseInt(this.category_id),
      }).then(() => {
        alert('Product attribute option "' + this.name + '" change has been saved');
        this.attribute_id = ""; //to prevent double submitting
        location.reload();
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    deleteAttribute() {
      this.$http.post('/api/admin/delete_attribute',{
        "id" : parseInt(this.attribute_id),
      }).then(() => {
        alert('Product attribute has been deleted');
        this.attribute_id = ""; //to prevent double submitting
        location.reload();
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckName() {
      this.fNameOk = this.name.trim() != "";
    },
    fCheckHelpText() {
      this.fHelpTextOk = this.help_text.trim() != "";
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
