<template>
  <div class="admin_list_attribute_option">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>{{title}}</h5>
      <div class="container">
        <b-form-select v-model="selected_attribute_option_id" :options="all_attribute_option" text-field="attribute_option_summary" value-field="id" @change="selectedAttributeOption" :select-size="num_list_items"></b-form-select>
      </div>
      <div class="t-3">Selected: <strong>{{ selected_attribute_option_id }}</strong></div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    'num_list_items': {  //how many list items are visible; 1=droplist >1=select list
      type: Number,
      default: 1,
    },
    'empty_option': {  //should there be a empty option with value of 0
      type: Boolean,
      default: true,
    },
    'title': {
      type: String,
      default: 'Product Category',
    },
    'selected_value': {
      type: Number,
      default: 0,
    },
    'attribute_id': {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_attribute_option_id: null,
      all_attribute_option: [],
    }
  },
  mounted () {
    console.log("current id:",this.attribute_id)
    this.getAttributeOption();
  },
  components: {
  },
  computed: {
    selectedOption() {
       return this.all_attribute_option.find(option => option.id == this.selected_attribute_option_id)
     }
  },
  methods:{
    getAttributeOption () {
      let query
      if (this.attribute_id==0) {
        query = '/api/admin/list_attribute_option'
      } else {
        query = '/api/admin/list_attribute_option?aid='+this.attribute_id
      }

      this.$http.get(query
      ).then((resp) => {
        this.all_attribute_option = resp.data.all_attribute_option;
        this.all_attribute_option.forEach( (el,i) => this.all_attribute_option[i].attribute_option_summary = el.option)
        if (this.empty_option) this.all_attribute_option.unshift({'id':0,'attribute_option_summary':''})
        if (this.selected_value>0) this.selected_attribute_option_id = this.selected_value
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    selectedAttributeOption () {
      this.$emit('attribute_option_selected',{
        'id': parseInt(this.selected_attribute_option_id),
        'option': this.selectedOption.option,
      })
    },
  },
}
</script>
<style scoped lang="scss">
</style>
