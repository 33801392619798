<template>
  <div class="summary_view">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Summary View </h5>
   </div>

    <div style="height:50px"></div>

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Total Members</h5>
      <div style="text-align:center; width:100%">
        {{ total_member }}
      </div>
    </div>

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Total Active Gold Members</h5>
      <div style="text-align:center; width:100%">
        {{ total_active_gold_member }}
      </div>
    </div>

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Total Inactive Gold Members</h5>
      <div style="text-align:center; width:100%">
        {{ total_inactive_gold_member }}
      </div>
    </div>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      total_member: 0,
      total_active_gold_member: 0,
      total_inactive_gold_member: 0,
    }
  },
  mounted () {
    this.getSummaryView();
  },
  components: {
  },
  computed: {
  },
  methods:{
    getSummaryView () {
      this.$http.get('/api/admin/summary_view'
      ).then((resp) => {
        this.total_member = resp.data.total_member;
        this.total_active_gold_member = resp.data.total_active_gold_member;
        this.total_inactive_gold_member = resp.data.total_inactive_gold_member;
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
  },
}
</script>

