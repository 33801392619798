
<template>
  <div class="system_qr_scanner" style="width:100%">
    <div style="text-align:center;" class="mx-auto">
      <div id="reader"></div>
    </div>

    <div :style="{visibility: showInput ? 'visible' : 'hidden'}" style="width:250px;" class="mx-auto">
      <hr>
      <div style="float:left"><b-form-input id="input-small" v-model="codeInput" size="sm" placeholder="Enter member code"></b-form-input></div>
      <div style="float:right"><b-button squared variant="outline-secondary" size="sm" @click="codeEntered">Go</b-button></div>
      <div style="clear:both"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    'showInput': {  //shows keyboard input
      type: Boolean,
      default: true,
    },
    'stopAtScan': {  //stop the scanner once scanned
      type: Boolean,
      default: true,
    },
    'fps': {  //scanning frequency
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      html5QrCode:{},
      codeInput: "",
    }
  },
  mounted() {
  //    let recaptchaScript = document.createElement('script')
  //    recaptchaScript.setAttribute('src', "https://github.com/mebjas/html5-qrcode/releases/download/V2.0.11/html5-qrcode.min.js")
  //    document.head.appendChild(recaptchaScript)

//      let html5QrcodeScanner = new Html5QrcodeScanner("reader", {
//	fps: 10,
//	qrbox: 250,
//	aspectRatio: 1.3,
//	facingMode: { exact: "user"},
//      }, /* verbose= */ false);
//      html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);


    // eslint-disable-next-line
    this.html5QrCode = new Html5Qrcode("reader");
    // eslint-disable-next-line
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      //console.log(`Code matched = ${decodedText}`, decodedResult);
      if (this.stopAtScan) {
	this.html5QrCode.stop()
      }
      this.$emit('code_detected', decodedText)
    };
    const config = { fps: this.fps, qrbox: 250 };
    this.html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
  },
  methods:{
    stopScanner() {
      this.html5QrCode.stop()
    },
    codeEntered() {
      if (!this.codeInput) return;
      this.$emit('code_detected', this.codeInput)
    },
  }
}
</script>


<style scoped lang="scss">
</style>
