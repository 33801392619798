<template>
  <div class="staff_checkin_list">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Staff Checked-in Members</h5>
      <div style="text-align:center; width:100%">

        <div class="d-flex flex-wrap" style="margin:auto">
          <div v-for='m in checkin_list' :key="m.member_id+m.name" style="width:180px; text-align:center; cursor:pointer"  @click="getMemberHistory(m.member_id)">
            <img :src="m.photo" alt="member photo" style="width:150px;"><br>
            <span style="font-size:8pt">{{ m.name}} ({{ ISOZtoH24(m.checkin_time) }})</span>
            <div style="height:10px"></div>
          </div>
        </div>

      </div>
    </div>

    <b-modal ref="modal-member-history" hide-footer size="lg" title="History">
      <memberHistory :member_id=selectedMemberId :limit=25></memberHistory>
    </b-modal>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import memberHistory from '../reusable/member_history.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    memberHistory,
  },
  data() {
    return {
      checkin_list: [],
      selectedMemberId: 0,
    }
  },
  mounted () {
    this.getCheckInList();
  },
  computed: {
  },
  methods:{
    getCheckInList () {
      this.$http.get('/api/staff/list_member_checkin'
      ).then((resp) => {
        this.checkin_list = resp.data.checkin_list;
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    getMemberHistory(id){
      if (id<=0) return;

      this.selectedMemberId = id
      this.$refs.['modal-member-history'].show()
    },
  },
}
</script>

