<template>
  <div class="admin_list_attribute">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>{{ title }}</h5>
      <div class="container">
        <b-form-select v-model="selected_attribute_id" :options="all_attribute" text-field="attribute_summary" value-field="id" @change="selectedAttribute" :select-size="num_list_items"></b-form-select>
      </div>
      <div class="t-3">Selected: <strong>{{ selected_attribute_id }}</strong></div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    'num_list_items': {  //how many list items are visible; 1=droplist >1=select list
      type: Number,
      default: 1,
    },
    'empty_option': {  //should there be a empty option with value of 0
      type: Boolean,
      default: true,
    },
    'title': {
      type: String,
      default: 'Product Attribute',
    },
    'categoryId1': {
      type: Number,
      default: 0,
    },
    'categoryId2': {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_attribute_id: null,
      all_attribute: [],
    }
  },
  mounted () {
    this.getAttribute();
  },
  components: {
  },
  computed: {
    selectedOption() {
      return this.all_attribute.find(option => option.id == this.selected_attribute_id)
    }
  },
  methods:{
    getAttribute () {
      let q=''
      if (this.categoryId1>0 || this.categoryId2>0) {
        q = '/api/admin/list_attribute?cid1='+this.categoryId1+'&cid2='+this.categoryId2
      }else{
        q = '/api/admin/list_attribute'
      }

      this.$http.get(q)
      .then((resp) => {
        this.all_attribute = resp.data.all_attribute;
        this.all_attribute.forEach( (el,i) => this.all_attribute[i].attribute_summary = el.name + ': ' + el.help_text)
        if (this.empty_option) this.all_attribute.unshift({'id':0,'attribute_summary':''})
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    selectedAttribute () {
      this.$emit('attribute_selected',{
        'id': parseInt(this.selected_attribute_id),
        'name': this.selectedOption.name,
      })
    },
  },
}
</script>
<style scoped lang="scss">
</style>
