<template>
  <div class="manage_attribute_option">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Admin Manage Attribute Option</h5>
        <hr style="margin-bottom:50px;">

          <div class="container" style="padding:20px 0;">
            <div style="text-align:center; margin:auto">
              <listAttribute v-on:attribute_selected="attributeSelected($event)"></listAttribute>
            </div>
          </div>

         <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="option" @blur="fCheckOption" :state="fOptionOk" placeholder=" "></b-form-input>
              <span>Option *</span>
              <p v-if="fOptionOk!=false">Option of category</p>
              <b-form-invalid-feedback :force-show="fOptionOk==false">Option is required</b-form-invalid-feedback>
              </label>
          </p>
 
          <b-button variant="outline-secondary" @click="createAttributeOption" style="margin-top:30px; width:80%; max-width:300px">Create Attribute Option</b-button>

          <div class="container" style="padding:20px 0;">
            <div style="text-align:center; margin:auto">
              <listAttributeOption v-on:attribute_option_selected="attributeOptionSelected($event)"></listAttributeOption>
            </div>
          </div>

          <b-button variant="outline-secondary" @click="editAttributeOption" style="margin-top:30px; width:80%; max-width:300px">Edit Attribute Option</b-button>
          <b-button variant="outline-secondary" @click="deleteAttributeOption" style="margin-top:30px; width:80%; max-width:300px">Delete Attribute Option</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
import listAttribute from './list_attribute.vue'; //
import listAttributeOption from './list_attribute_option.vue'; //
export default {
  components: {
    listAttribute,
    listAttributeOption,
  },
  data() {
    return {
      attribute_id: "0",
      attribute_option_id: "0",
      option: '',
      fOptionOk: null,
    }
  },
  methods:{
    attributeSelected (attribute) {
      this.attribute_id = attribute.id
    },
    attributeOptionSelected (attributeOption) {
      this.attribute_option_id = attributeOption.id
    },
    createAttributeOption() {
      if (!this.fOptionOk) {
        this.fCheckOption();
        alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/new_attribute_option',{
        "attribute_id" : parseInt(this.attribute_id),
        "option" :        this.option,
      }).then(() => {
        alert('Product attribute option "' + this.option + '" has been created');
        this.attribute_id = ""; //to prevent double submitting
        location.reload()
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    editAttributeOption() {
      if (!this.fOptionOk) {
        this.fCheckOption();
        alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/edit_attribute_option',{
        "attribute_id" : parseInt(this.attribute_option_id),
        "option" :        this.option,
      }).then(() => {
        alert('Product attribute option "' + this.option + '" change has been saved');
        this.attribute_option_id = ""; //to prevent double submitting
        location.reload();
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    deleteAttributeOption() {
      this.$http.post('/api/admin/delete_attribute_option',{
        "attribute_id" : parseInt(this.attribute_option_id),
      }).then(() => {
        alert('Product attribute option "' + this.option + '" has been deleted');
        this.attribute_option_id = ""; //to prevent double submitting
        location.reload();
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckOption() {
      this.fOptionOk = this.option.trim() != "";
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
