<template>
  <div class="staff_logs">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Staff Logs</h5>
        <hr style="margin-bottom:50px;">


        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <h5>Select Category</h5>

          <b-row>
            <b-col>
              <input type="checkbox" id="payment" value="Payment" v-model="checkedCategory">
              <label for="payment">Payment</label>
            </b-col>
            <b-col>
              <input type="checkbox" id="member" value="Member" v-model="checkedCategory">
              <label for="member">Member</label>
            </b-col>
            <b-col>
              <input type="checkbox" id="events" value="Events" v-model="checkedCategory">
              <label for="events">Events</label>
            </b-col>
          </b-row>

          <br>
          <span>Checked Category: {{ checkedCategory }}</span>
          <br><br>
          <b-button @click="getStaffLogs">List Staff Logs</b-button>
        </div>

        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <h5>Staff Logs</h5>
          <div style="text-align:center; width:100%">
            <b-table responsive striped hover :items="staff_logs" :fields="fields" id="staff_list_logs" style="font-size:9pt">

              <template v-slot:cell(category)="data">
                {{ category[data.item.category] }}
              </template>

            </b-table>
          </div>
        </div>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      fields: [
       {
          key: 'log_date',
          sortable: true,
          tdAttr: {style: "min-width:130px; "},
          formatter: (v) => { return (v).split("T")[0]+' '+this.ISOZtoH24(v) },
       },
       {
          key: 'email',
          label: 'Email',
          sortable: true,
       },
       {
          key: 'category',
          label: 'Category',
          sortable: true,
        },
        {
          key: 'log',
          label: 'Log',
        },
      ],
      staff_logs: [],
      category1: 0,
      category2: 0,
      category3: 0,
      category: ["0","Payment", "Member", "Events"],    // checkbox starts at index 1
      checkedCategory: ["Payment", "Member", "Events"],
    }
  },
  mounted () {
    this.getStaffLogs();
  },
  methods:{

    getStaffLogs() {
      this.category1 = 0;
      this.category2 = 0;
      this.category3 = 0;

      if (this.checkedCategory.includes("Payment"))
        this.category1 = 1;
      if (this.checkedCategory.includes("Member"))
        this.category2 = 1;
      if (this.checkedCategory.includes("Events"))
        this.category3 = 1;
      if (this.category1 == 0 && this.category2 == 0 && this.category3 == 0) {
        alert("Must select at least 1 category");
        return;
      }

      this.$http.get('/api/staff/list_staff_logs?category1='+this.category1+'&category2='+this.category2+'&category3='+this.category3
      ).then((resp) => {
        this.staff_logs = resp.data.staff_logs;
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
