<template>
  <div class="ncbc_home">

    <div class="container" style="background:rgba(0,0,0,.5);">
      <!-- <div class="row col-12" style="color:#fff"> -->
      <!--   <div class="d-inline-flex p-2" style="overflow-x:auto"> -->
      <!--       <!-1- <span style="white-space:pre; margin-left:50px">{{GetShortDate(0)}}:<font style="font-weight:bold;color:#15ed2f"> OPEN</font></span> -1-> -->
      <!--       <span style="white-space:pre; font-weight:bold; cursor:pointer" @click="covidMsg()">Gym Status &nbsp;<i class="far fa-question-circle"></i></span> -->
      <!--       <span style="white-space:pre; margin-left:20px">{{GetShortDate(0)}}:<font style="font-weight:bold;color:#ff570f"> CLOSE</font></span> -->
      <!--       <span style="white-space:pre; margin-left:50px">{{GetShortDate(1)}}:<font style="font-weight:bold;color:#ff570f"> CLOSE</font></span> -->
      <!--       <span style="white-space:pre; margin-left:50px">{{GetShortDate(2)}}:<font style="font-weight:bold;color:#ff570f"> CLOSE</font></span> -->
      <!--       <span style="white-space:pre; margin-left:50px">{{GetShortDate(3)}}:<font style="font-weight:bold;color:#ff570f"> CLOSE</font></span> -->
      <!--   </div> -->
      <!--   <hr> -->
      <!-- </div> -->
      <div class="row">
        <div class="col-12 col-md-6" style="opacity:0.85;">
          <div style="padding:15px 0;">
            <span style="color:#fff">Current Calendar</span>
            <events :days=3 ></events>
            <div class="row">
              <div class="col-6" style="text-align:left">
                <span style="color:#fff;text-decoration:italic">Powered by:</span><a href="https://www.badmintonunited.org" target="_blank"><img src='../assets/images/bu_logo.png' style="width:100px" /></a>
              </div>
              <div class="col-6" style="text-align:right">
                <router-link class="top_menu_a" to="/booking/events" style="font-size:19px; font-weight:400">See full calendar</router-link>
              </div>
            </div>
            <div style="clear:both"></div>
          </div>

          <div style="padding-top:35px" class="d-block d-sm-block d-md-none d-lg-none"><hr></div>
        </div>


        <div class="col-12 col-md-6" style="opacity:0.80; text-align:left; color:#fff; overflow-y:auto; max-height:550px;">
          <div class="p-2">
            <p style="font-weight:bold">18th Feb. 2021</p>
            <p>
              Gym status update:<br><br>
              Hello fellow badminton players.<br><br>
              Finally some good news to share. With reports of our covid situation has been getting better and cases number is trending downward, NCBC will have open play and court rental sessions starting next Wednesday, Feb 24th.<br><br>
              For now we will still have capacity limit, with the hope we can soon increase the limit and eventually back to no limit. And everyone please continue to be vigilant, wear our masks in waiting area and practice social distance.<br><br>
              Our retail pro shop will also be open for business, come get any of your badminton needs, discount for NCBC members.<br><br>
              Click <a href='booking/events?d=2021-02-24'>here</a> to reserve for play sessions:<br>
            </p>
            <hr>
            <p style="font-weight:bold">14th Jan. 2021</p>
            <p>
              Gym status update:<br><br>
              Since our region's stay-at-home order has been lifted, we have been getting questions about our reopening.<br><br>
              While we are looking forward to reopening the gym, the main goal is to provide a safe environment for everyone to enjoy badminton. According to the health department we are still in the midst of Covid surge, it is best for us to wait a little before returning to the courts.<br><br>
              We will keep on monitoring and have everyone updated, hope we can see everyone in the gym soon!
            </p>
            <hr>
            <p style="font-weight:bold">9th Dec. 2020</p>
            <p>
              The Sacramento stay at home order will be in effect starting on the 10th Dec. 2020 at 11:59PM<br><br>
              NCBC will close the gym starting on this Friday 11th Dec. 2020, the closure will last at least for 3 weeks.<br><br>
              For our reopening status, please check this page, or join our email list at the bottom of this page.
            </p>
            <hr>
            <p style="font-weight:bold">8th Dec. 2020</p>
            <p>
              A small bug was found on the website which causes error for a small number of members during login.<br>
              The bug has since been fixed and everything should be functioning.<br><br>
              Feel free to <a href='/ncbc/contact'>contact us</a> if you have any problem or questions.
            </p>
            <hr>
            <p style="font-weight:bold">2nd Dec. 2020</p>
            <p>
              Hello Everyone,<br>
              <br>
              A new stay at home order is expected in the upcoming days, all non essential activities including NCBC will close for 3 weeks. To be clear, the new order is NOT yet in effect in our region, but is expected to be soon once our ICU capacity falls below 15%.
              <br><br>
              As of now, our gym is open and we will keep everyone updated with our status. If you wish to, scroll to the bottom of the page and join our mailiing list for the most updated NCBC news.
              <br><br>
              Thank you for everybody’s patience!
            </p>
            <hr>
            <p style="font-weight:bold">25th Nov. 2020</p>
            <p>
              Please continue to use the <a href="https://docs.google.com/spreadsheets/d/1_wjr1xT0OGRATvK8hN8eb6i-6GHjKs0sFjCGKpZghXU/edit?fbclid=IwAR2drGK2wzGSH4TUqb1YxLhtfuuOxfB4yDJrtin4KvYZAKqIl5dVDoxW5lc" target=_blank>Google sheet</a> for booking and reservation from now till Dec 4th.<br><br>
              Use the new reservation system on this website starting Dec 5th.<br><br>
              See <a href="/booking/events?d=2020-12-05T00%3A00%3A00Z">event calendar here</a>.
            </p>
            <hr>
            <p style="font-weight:bold">20th Nov. 2020</p>
            <p>Starting from the 21st of November, all 7-11pm open play sessions will become 7-10pm, this is to comply with the city's curfew order.</p>
            <hr>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import datetime_mixin from '../mixins/datetime.js'
import events from './booking/events.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    events
  },
  data() {
    return {
    }
  },
  methods:{
    covidMsg () {
      this.$bvModal.msgBoxOk("With the new stay at home order, once our region's ICU capacity falls below 15%, all non essential activities including NCBC will close. Our gym status is updated daily to refect our opening status.",{
          centered: true,
      })
    },
  }
}
</script>


<style scoped lang="scss">
.top_menu_a {
  color:#fff;
  font-weight: 400;
  font-size: 18px;
}

a {
  color: #fabe57;
}

</style>
