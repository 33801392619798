<template>
  <div class="member">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Your Upcoming Event</h5>
      <div style="text-align:center; width:100%">
        <b-table responsive striped hover :items="all_reserved" :fields="fields" id="member_all_reserved" class="events">

          <template #cell(start_end_time)="data">
           {{ H24toH12(data.item.start_time) }} - {{ H24toH12(data.item.end_time) }}
          </template>

          <template v-slot:cell(delete)="row">
            <span  @click="deleteRow(row)" style="cursor:pointer"><i class="fas fa-trash-alt"></i></span>
          </template>

          <template v-slot:cell(status)="data">
            {{ status[data.item.status] }}
          </template>
        </b-table>
      </div>
    </div>

    <div style="height:50px"></div>

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Recent Reserved Events</h5>
      <div style="text-align:center; width:100%">
        <b-table responsive striped hover :items="all_past" :fields="fields2" id="member_all_past" class="events">

          <template #cell(start_end_time)="data">
            {{ H24toH12(data.item.start_time) }} - {{ H24toH12(data.item.end_time) }}
          </template>

          <template v-slot:cell(status)="data">
            {{ status[data.item.status] }}
          </template>
        </b-table>
      </div>
    </div>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  props: {
    'predefined_id': {
      type: Number,
      default:0,
    },
  },
  data() {
    return {
      fields: [
       {
          key: 'event_name',
          label: 'Event',
          sortable: true,
       },
       {
          key: 'event_date',
          sortable: true,
          tdAttr: {style: "min-width:130px;"},
          formatter: (v) => { return (v).split("T")[0] },
        },
        {
          key: 'start_end_time',
          label: 'Time',
        },
        {
          key: 'delete',
          label: '',
        },
        {
          key: 'status',
          sortable: true,
        },
        {
          key: 'player_names',
          label: 'Player',
        },
      ],
      fields2: [
       {
          key: 'event_name',
          label: 'Event',
          sortable: true,
       },
       {
          key: 'event_date',
          sortable: true,
          tdAttr: {style: "min-width:130px;"},
          formatter: (v) => { return (v).split("T")[0] },
        },
        {
          key: 'start_end_time',
          label: 'Time',
        },
        {
          key: 'status',
          sortable: true,
        },
      ],
      all_reserved: [],
      all_past: [],
      status: ["Reserved", "Checked In", "Paid", "Backup List"],
    }
  },
  mounted () {
    this.getReservedEvents();
  },
  components: {
  },
  computed: {
    member_id () {
      if (this.predfined_id>0) {
        return this.predefined_id
      } else {
        return this.$store.state.userStore.member_id
      }
    },
  },
  watch: {
    predefined_id: function() {
      this.getReservedEvents();
    }
  },
  methods:{
    getReservedEvents () {
      this.$http.get('/api/member/reserved_events?id='+this.member_id
      ).then((resp) => {
        this.all_reserved = resp.data.all_reserved;
        this.all_past = resp.data.all_past;
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    deleteRow (row) {
      this.$bvModal.msgBoxConfirm('Are you sure to delete this reserved event?',{
        title: 'Please Confirm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-3',
        centered: true,
      }) .then(ok => {
          if (!ok) return;

          var d = {}
          d.id = parseInt(row.item.id);
          this.$http.post('/api/member/delete_reservation',d,
          ).then((resp) => {
            console.log(resp.data.error)
            console.log(resp.data.updated)
            if (resp.data.error) alert(resp.data.error);
            alert('Reservation deleted');
            location.reload();
          }).catch((error) => {
            console.log(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          }).finally(() => {
          })
      })
        .catch(err => {
          console.log(err)
      })

    },
  },
}
</script>

<style scoped lang="scss">
.events {
  font-size: 13px;

  button {
    font-size: 11px;
  }
}
</style>
