<template>
  <div class="staff_upcoming_events">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Upcoming Event</h5>
      <div class="container">
        <b-form-select v-model="selected_event_id" :options="all_events" text-field="event_summary" value-field="id" @change="selectedEvent" :select-size="4"></b-form-select>
      </div>
      <div class="mt-3">Selected: <strong>{{ selected_event_id }}</strong></div>
    </div>


  </div>
</template>

<script>
  import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      selected_event_id: null,
      all_events: [],
    }
  },
  mounted () {
    this.getUpcomingEvents();
  },
  components: {
  },
  computed: {
    member_id () {
      return this.$store.state.userStore.member_id
    },
  },
  methods:{
    getUpcomingEvents () {
      this.$http.get('/api/staff/upcoming_events'
      ).then((resp) => {
        this.all_events = resp.data.all_events;
        this.all_events.forEach( (el,i) => this.all_events[i].event_summary = el.event_date.split("T")[0] + ' : ' + el.name + ' (' + this.H24toH12(this.ISOZtoH24(el.start_time)) + ')')
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    selectedEvent () {
      this.$emit('upcoming_event_selected',{
        'id': parseInt(this.selected_event_id),
      })

    },
  },
}
</script>

<style scoped lang="scss">
.events {
  font-size: 13px;

  button {
    font-size: 11px;
  }
}
</style>
