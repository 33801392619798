E<template>
  <div class="recent_expired_member_view">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Recent Expired Membership</h5>
        <hr style="margin-bottom:50px;">

        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <b-row>
            <b-col>
              <b-button size="sm" @click="getRecentExpired">Refresh List</b-button>
            </b-col>
          </b-row>

        </div>

        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <h5>Recent Expired</h5>
          <div style="text-align:center; width:100%">

            <b-table responsive striped hover :items="recent_expires" :fields="fields" id="recent_expired_list" style="font-size:9pt">
              <template v-slot:cell(id)="row">
                <span style="cursor:pointer; color:blue" @click="getMemberHistory(row.item.id)">
                  {{row.item.id}}
                </span>
              </template>
            </b-table>

          </div>
        </div>

      </b-card>
      </b-container>

    </div>

    <b-modal ref="modal-member-history" hide-footer size="lg" title="History">
      <memberHistory :member_id=selectedMemberId :limit=25></memberHistory>
    </b-modal>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import memberHistory from '../reusable/member_history.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    memberHistory,
  },
  data() {
    return {
      fields: [
       {
         key: 'id',
         label: 'id',
       },
       {
          key: 'name',
          label: 'Name',
          sortable: true,
       },
       {
          key: 'member_expires',
          sortable: true,
          tdAttr: {style: "min-width:130px; "},
          formatter: (v) => { return (v).split("T")[0] },
       },
      ],
      recent_expires: [],
      selectedMemberId: 0,
    }
  },
  mounted () {
    this.getRecentExpired();
  },
  methods:{
    getRecentExpired() {
      this.$http.get('/api/staff/list_recent_expires'
      ).then((resp) => {
        this.recent_expires = resp.data.recent_expires;
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    getMemberHistory(id){
      if (id<=0) return;

      this.selectedMemberId = id
      this.$refs.['modal-member-history'].show()
    },
  },
  beforeDestroy() {
  },
};
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}

input {
  margin: 3px;
}
</style>
