
<template>
  <div class="contact_us">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Suggestion / Questions</h5>
        <hr style="margin-bottom:50px;">

        <p style="max-width:500px; margin:auto; text-align:left">
          We thrive to have a fun and enjoyable badminton gym for everyone.<br><br>If you have any ideas, suggestions, questions or even complains, please let us know here.
        </p>
        <div style="height:50px"></div>
        <p style="max-width:500px; margin:auto">
          <label class="placeholder">
            <b-form-input v-model="email" @blur="fCheckEmail" :state="fEmailOk" placeholder=" "></b-form-input>
            <span>Your email*</span>
            <p v-if="fEmailOk!=false">Please enter your email address</p>
            <b-form-invalid-feedback :force-show="fEmailOk==false">Valid email is required</b-form-invalid-feedback>
          </label>
        </p>

        <p style="max-width:500px; margin:auto">
          <label class="placeholder">
            Enter your message
            <b-form-textarea @blur="fCheckMessage" :state="fMessageOk" v-model="message" placeholder=" " rows="6"></b-form-textarea>
            <b-form-invalid-feedback :force-show="fMessageOk==false">Please enter a message</b-form-invalid-feedback>
          </label>
        </p>

        <div style="height:30px"></div>

        <b-overlay :show="showOverlay" rounded="sm">
          <b-button block variant="success" @click="send" style="margin:auto; max-width:500px">Send Messge</b-button>
        </b-overlay>

      </b-card>
      </b-container>

    </div>

    <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input all fields with valid data</b-alert>

  </div>
</template>

<script>
export default {
  data() {
    return {
      fEmailOk: null,
      fMessageOk: null,
      email: "",
      message: "",
      showAlert: false,
      showOverlay: false,
    }
  },
  methods: {
    fCheckEmail () {
     // eslint-disable-next-line
     this.fEmailOk = (this.email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.trim())))
    },
    fCheckMessage () {
      this.fMessageOk = this.message.trim() != "";
    },
    send () {
      this.showAlert = false;
      this.fCheckEmail();
      this.fCheckMessage();
      if (!this.fEmailOk || !this.fMessageOk) {
        this.showAlert = true;
        return
      }

      this.showOverlay = true;
      this.$http.post('/api/contact_message',{
          email: this.email,
          message: this.message
        }
      ).then(() => {
        alert("Thank you, we have received your message.")
        this.$router.push({name:'Home'})
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      }).finally(() => {
        this.showOverlay = false;
      })
    },
  },
}
</script>
