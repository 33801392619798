
<template>

  <div class="member_history_component">
    <div>
      <img id="historyMemberPhoto" class="mb-3" style="height:100px" src="" />
    </div>

    <b-table responsive striped hover :items="member_logs" :fields="fields" id="member_list_logs" style="font-size:9pt">
    </b-table>

    <div>
      <b-pagination v-model="currentPage" :total-rows="log_total" :per-page="pageLimit" align="center" aria-controls="member_list_logs:" style="float:left"></b-pagination>
      <div style="float:right;">
        <b-form-select v-model="pageLimit">
          <b-form-select-option value="35">Show 35 per page</b-form-select-option>
          <b-form-select-option value="75">Show 75 per page</b-form-select-option>
          <b-form-select-option value="125">Show 125 per page</b-form-select-option>
        </b-form-select>
      </div>
      <div style="clear:both"></div>
    </div>

  </div>

</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  props: {
    'member_id': {
      type: Number,
      default: 0,
    },
  },
  data() {
     return {
       fields: [
        {
           key: 'log_date',
           sortable: true,
           tdAttr: {style: "min-width:130px;"},
          formatter: (v) => { return (v).split("T")[0]+' '+this.ISOZtoH24(v) },
        },
        {
           key: 'event',
           label: 'Event',
           sortable: true,
        },
        {
           key: 'staff',
           label: 'Staff',
           sortable: true,
         },
         {
           key: 'log',
           label: 'Log',
         },
       ],
       member_logs: [],
       currentPage: 1,
       pageLimit: 35,
       log_total: 0,
     }
   },
   watch: {
     currentPage: function(val) {
       this.getMemberHistory(val-1)
     },
     pageLimit: function() {
       this.getMemberHistory(0)
     },
   },
   mounted () {
    this.getMemberHistory (0);
   },
   computed: {
   },
   methods:{
     getMemberHistory (page) {
       if (this.member_id==0) return;
       this.$http.get('/api/staff/list_member_logs?id='+this.member_id+'&limit='+this.pageLimit+'&offset='+ (page * this.pageLimit)
       ).then((resp) => {
         var img = document.getElementById("historyMemberPhoto");
         img.src = resp.data.photo

         this.member_logs = resp.data.member_logs;
         this.log_total = resp.data.log_total;
       }).catch((error) => {
         alert(error.response.data.message);
         console.log(error.response.data.message);
         console.log(error.response.status);
         console.log(error.response.headers);
       })
     },
   }
}
</script>

<style scoped lang="scss">
* {
}
</style>
