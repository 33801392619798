<template>
  <div class="staff">
    <queueSystem ref="queue"></queueSystem>

    <h5 style="color:#fff">
      Daily Events
      <span class="mb-3" style="cursor:pointer; margin-left:15px;" @click="getReservedEvents"><i class="fas fa-sync"></i></span>
    </h5>

    <div class="container" style="padding:0px 0; font-size:13px;">
      <b-form-datepicker id="edatepicker" @input="getReservedEvents" today-button v-model="today" class="mb-2" style="max-width:350px;margin:auto" :date-format-options="{month: 'short', day: 'numeric', year: 'numeric'}" locale="en-US"></b-form-datepicker>

      <div style="text-align:center; width:100%">
        <div class="container" v-for="(event,index1) in list" :key="`event-${index1}`" style="background:rgba(255,255,255,.9); margin:20px auto; padding:10px 0;">
        <b-overlay :show="showOverlay2" rounded="sm">
          <div style="font-size:15pt">
            <span style="cursor:pointer; float:left" v-if="event.event_detail.event_type_id==1" v-b-modal.modal-scanner :click="selectedEventId=event.event_detail.id">
              <span style="margin-left:10px;"><i class="fas fa-id-card"></i></span><font style="font-size:8pt;"> Member </font>
            </span>
            ({{event.reserved.length}}) {{event.event_detail.name}}
            <span style="margin:5px"></span>
            <button type="button" class="btn btn-info btn-sm" @click="showRecentExpired()">Recent Expired</button>

            <span style="cursor:pointer; float:right" v-if="event.event_detail.event_type_id==1" @click="openNewReserveForm(event)">
              <span><i class="fas fa-user-plus"></i></span> <font style="font-size:8pt; margin-right:10px;"> Guest</font><br>
            </span>
            <div style="clear:both"></div>
          </div>
            Cash: {{event.cash}} | Credit: {{event.credit}} | Checked-in: {{event.checked_in}} | Gold Pass: {{event.gold_pass}} | Standard Pass: {{event.standard_pass}}
            <br>

            <b-table responsive striped hover small v-if="event.event_detail.event_type_id==1" @sort-changed="sortChanged" :items="event.reserved" :fields="fields" ref="openPlayTable" data-toggle="openPlayTable">
            <template #cell(start_end_time)="data">
              {{ H24toH12(data.item.start_time) }} - {{ H24toH12(data.item.end_time) }}
            </template>

            <template v-slot:cell(member_id)="row">
              <span v-if="row.item.member_id>0" style="cursor:pointer; color:blue" @click="getMemberHistory(row.item.member_id)">
                {{row.item.member_id}}
              </span>
              <span v-else>{{row.item.member_id}}</span>
            </template>

            <template v-slot:cell(notes)="row">
              <div style="margin:3px; font-size:10px; height:35px; text-align:left; overflow-y:auto;">
                <pre> {{row.item.notes}} </pre>
              </div>
            </template>

            <template v-slot:cell(notes_save)="row">
              <span class="m-2 mini-icon" @click="queue_system_entry(row)" style="font-size:13pt;cursor:pointer"><i class="fa-solid fa-link"></i></span>
              <span class="m-2 mini-icon" @click="saveNotes(row)" style="font-size:13pt;cursor:pointer"><i class="far fa-sticky-note"></i></span>
              <span class="m-2 mini-icon" @click="showNotes(row.item.notes)" style="cursor:pointer"><i class="far fa-eye"></i></span>
            </template>

            <template v-slot:cell(status)="row">
              <!-- <b-form-select  v-model="row.item.status" @change="setStatus(row)" class="mb-3" size="sm"> -->
              <b-form-select  :value="row.item.status" @change="setStatus(row, $event)" class="mb-3" size="sm">
                <b-form-select-option value=0>-</b-form-select-option>
                <b-form-select-option value=1>Checked In (Guest) (not paid)</b-form-select-option>
                <b-form-select-option value=2>Paid Cash (Guest)</b-form-select-option>
                <b-form-select-option value=4>Credit (Guest)</b-form-select-option>
                <b-form-select-option value=3>Backup</b-form-select-option>
                <b-form-select-option value=5>Gold Pass</b-form-select-option>
                <b-form-select-option value=6>Standard Pass</b-form-select-option>
              </b-form-select>
            </template>
          </b-table>

          <b-table responsive striped hover small v-if="event.event_detail.event_type_id==2" @sort-changed="sortChanged" primary-key="id" :items="event.reserved" :fields="fields2" id="staff_all_reserved2">
            <template #cell(start_end_time)="data">
              {{ H24toH12(data.item.start_time) }} - {{ H24toH12(data.item.end_time) }}
            </template>

            <template v-slot:cell(notes)="row">
              <div style="margin:3px; font-size:10px; height:35px; text-align:left; overflow-y:auto;">
                <pre> {{row.item.notes}} </pre>
              </div>
            </template>

            <template v-slot:cell(notes_save)="row">
              <span class="m-2" @click="saveNotes(row)" style="font-size:15pt;cursor:pointer"><i class="far fa-sticky-note"></i></span>
              <span class="m-2" @click="showNotes(row.item.notes)" style="cursor:pointer"><i class="far fa-eye"></i></span>
            </template>

            <template v-slot:cell(status)="row">
              <b-form-select v-model="row.item.status" @change="setStatus(row)" class="mb-3" size="sm">
                <b-form-select-option value="0">-</b-form-select-option>
                <b-form-select-option value="1">Checked In</b-form-select-option>
                <b-form-select-option value="2">Paid Cash</b-form-select-option>
                <b-form-select-option value="4">Paid Credit</b-form-select-option>
              </b-form-select>
            </template>
          </b-table>

        </b-overlay>
        </div>
      </div>
    </div>

    <b-modal id="modal-open-play-form" scrollable centered title="Add Guest Players" ref="newReserveForm" button-size="sm">
      <!-- <div style="float:right"> -->
      <!--   <span style="cursor:pointer;" @click="$refs['searchMemberForm'].show()"><i class="fas fa-search"></i> Search Member</span> -->
      <!-- </div> -->
      <div style="clear:both"></div>
      <div style="font-size:13px; padding:5px; margin:auto; color:blue;" v-if="selectedMember">
        Selected member: {{ selectedMember.first_name }} {{ selectedMember.last_name }}
      </div>
      <b-form-input v-model="players[0]" maxlength="20" placeholder="Player 1"></b-form-input>
      <b-form-input v-model="players[1]" maxlength="20" placeholder="Player 2 (optional)"></b-form-input>
      <b-form-input v-model="players[2]" maxlength="20" placeholder="Player 3 (optional)"></b-form-input>
      <b-form-input v-model="players[3]" maxlength="20" placeholder="Player 4 (optional)"></b-form-input>
      <hr>

      <b-form-select v-model="new_players_status" class="mb-3" size="sm">
        <b-form-select-option value=0>-</b-form-select-option>
        <b-form-select-option value=1>Checked In</b-form-select-option>
        <b-form-select-option value=2>Paid Cash</b-form-select-option>
        <b-form-select-option value=4>Paid Credit</b-form-select-option>
      </b-form-select>

      <template #modal-footer>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-button @click="$refs['newReserveForm'].hide();selectedMember=null">Cancel</b-button>
          <b-button variant="success" style="margin-left:10px" @click="newReserve">Confirm Reserve</b-button>
        </b-overlay>
      </template>
    </b-modal>

    <b-modal id="modal-scanner" hide-footer title="Scan Membership Card" @close="onScannerClose" no-close-on-backdrop>
      <qrScanner ref="scanner" v-on:code_detected="memberCodeDetected($event)"></qrScanner>
    </b-modal>

    <checkinTool ref="checkin" v-on:checkin_succeeded="onMemberCheckedin($event)" v-on:reserved_checkin_succeeded="onReservedCheckedin($event)" :staff_use=true></checkinTool>

    <b-modal ref="modal-member-history" hide-footer size="lg" title="History">
      <memberHistory :member_id=selectedMemberId :limit=25></memberHistory>
    </b-modal>

    <b-modal ref="modal-recent-expires" hide-footer size="lg" title="Recent Expired">
      <recentExpiredList :member_id=selectedMemberId></recentExpiredList>
    </b-modal>
  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import memberHistory from '../reusable/member_history.vue';
import qrScanner from '../reusable/qr_scanner.vue';
import checkinTool from '../reusable/membership_checkin_tool.vue';
import queueSystem from '../reusable/queue_system.vue';
import recentExpiredList from '../reusable/recent_expired_member_view.vue';

export default {
  mixins: [datetime_mixin],
  components: {
    qrScanner,
    checkinTool,
    queueSystem,
    memberHistory,
    recentExpiredList,
  },
  data() {
    return {
      fields: [
        {
          key: 'member_id',
          label: 'id',
          sortable: true,
        },
        {
          key: 'player_names',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'start_end_time',
          label: 'Time',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'notes',
          label: 'Notes',
          sortable: true,
        },
        {
          key: 'notes_save',
          label: '',
        },
      ],
      fields2: [
        {
          key: 'member_id',
          label: 'id',
          sortable: true,
        },
        {
          key: 'first_name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'court_id',
          label: 'Court',
          sortable: true,
        },
        {
          key: 'start_end_time',
          label: 'Time',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'notes',
          label: 'Notes',
          sortable: true,
        },
        {
          key: 'notes_save',
          label: '',
        },
      ],
      list: [],
      today:"",
      players: [],
      new_players_status: 0,
      newReserveEvent:{},
      showOverlay: false,
      showOverlay2: false,
      noEdit: false,
      selectedMember: null,
      selectedEventId: 0,
      selectedMemberId: 0,
    }
  },
  mounted () {
    if (this.today=="") {
      let d=new Date()
      this.today = d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()
    }
    this.getReservedEvents();
  },
  computed: {
    user () {
      return this.$store.state.userStore.user
    },
    member_id () {
      return this.$store.state.userStore.member_id
    },
  },
  methods:{
    getReservedEvents () {
      this.showOverlay2 = true;
      this.$http.get('/api/staff/daily_events?d='+this.today
      ).then((resp) => {
        this.list = resp.data;
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      }).finally(() => {
        this.showOverlay2 = false;
        this.statsCount();
      })
    },
    saveNotes (row) {
          let note = prompt("Enter note", "");
          if (note == null || note.trim() =="") return;

          note = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + note + '\n\n';

          var d = {}
          d.id = parseInt(row.item.id);
          d.notes = note
          this.$http.post('/api/staff/notes',d,
          ).then((resp) => {
            if (resp.data.error) alert(resp.data.error);
            row.item.notes = note+row.item.notes;
            alert('Notes saved');
          }).catch((error) => {
            console.log(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          })
    },
    setStatus (r, $new_val) {
      var d = {}
      d.id = parseInt(r.item.id);

      if (this.noEdit) {
        alert('Cannot update while sorting by "status"')
        this.list=[]
        this.getReservedEvents() //reset status
        return
      }

      //if true, need to 'check in' player reserved event and now is paying at counter, only check in if changing from a 'non-checked-in' status to a 'check-in' status
      let need_check_in = ((r.item.status == "3" || r.item.status == "0" || r.item.status=="1") && ($new_val != 0 && $new_val != 3))

      if (need_check_in) {
        d.event_id = r.item.event_id
        d.player_names = r.item.player_names
        var c = this.$refs.checkin
        //wait till result emit and set status
        c.reservedCheckin(r.item.id, r.item.member_id, d.player_names, d.event_id, $new_val, r.item.notes, this.user)
      } else {
        // simply set status here
      }

      //let the membership tool emit status then we update the player's status
      if (need_check_in) return;

      if ($new_val) { //select box not bound by model, needs manual assign
        r.item.status = $new_val
      }

      let api = "";
      let toastMsg = "";
      if (r.item.status == "0") {
        api = '/api/staff/set_status'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "status changed to Reserved" + '\n\n';
        toastMsg = r.item.player_names + ': Reserved'
      } else if (r.item.status == "1") {
        api = '/api/staff/check_in'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "checked in" + '\n\n';
        toastMsg = r.item.player_names + ': Checked In'
      } else if (r.item.status == "2") {
        api = '/api/staff/paid'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "paid cash" + '\n\n';
        toastMsg = r.item.player_names + ': Cash'
      } else if (r.item.status == "3") {
        api = '/api/staff/set_status'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "status changed to Backup" + '\n\n';
        toastMsg = r.item.player_names + ': Backup'
      } else if (r.item.status == "4") {
        api = '/api/staff/paid'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "paid credit" + '\n\n';
        toastMsg = r.item.player_names + ': Credit'
      } else if (r.item.status == "5") {
        api = '/api/staff/set_status'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "status changed to Gold Pass" + '\n\n';
        toastMsg = r.item.player_names + ': Gold Pass'
      } else if (r.item.status == "6") {
        api = '/api/staff/set_status'
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "status changed to Standard Pass" + '\n\n';
        toastMsg = r.item.player_names + ': Standard Pass'
      }

      d.status=parseInt(r.item.status);

      this.$http.post(api,d,
        ).then((resp) => {
          console.log('in resp now')
          console.log(resp.data)
          if (resp.data.error) alert(resp.data.error);
          r.item.notes = d.notes+r.item.notes;
          this.makeToast(toastMsg, 'success');
          this.statsCount()
        }).catch((error) => {
          console.log('in error now', error)
          this.makeToast('Update failed: '+ error.response.data.message,'danger');
          this.getReservedEvents() //reset status
          console.log(error.response.data.message);
        })
    },
    showNotes (notes) {
      alert(notes)
    },
    openNewReserveForm (e) {
      if (e.event_detail.event_type_id != 1) return;
      this.newReserveEvent = e;
      this.new_players_status = 0;
      this.$refs.newReserveForm.show()
    },
    newReserve () {
      if (this.players.length==0) return;

      let e = this.newReserveEvent;

      this.showOverlay = true;

      let member_id = 0;
      if (this.selectedMember) member_id = this.selectedMember.id

      this.$http.post('/api/staff/book_open_play',
          {
            center_id: e.event_detail.center_id,
            member_id: member_id,
            event_id: e.event_detail.id,
            event_date: e.event_detail.event_date,
            notes: ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "Staff added player to event" + '\n\n',
            start_time: e.event_detail.start_time,
            end_time: e.event_detail.end_time,
            player_names: this.players.filter( v => v ).join(), //turn array to string and ignore empty
            //status: 0, //Reserved
            status: parseInt(this.new_players_status), //turn value to int
          }
        ).then((resp) => {
          var tmpPlayers = this.players;
          this.players = [];
          this.getReservedEvents();
          this.makeToast(resp.data, 'success');
          this.$refs['newReserveForm'].hide()
          this.selectedMember = null;
          console.log("resp",resp, "status",this.new_players_status,"players",tmpPlayers)
          if (parseInt(this.new_players_status)!=0) {
            //create queue entry
            var q = this.$refs
            for (let i = 0; i < tmpPlayers.length; i++) {
              q.queue.create_queue_entry(tmpPlayers[i],"")
            }
          }
        }).catch((error) => {
          this.showMsgboxOk(error.response.data.message,'danger')
          console.log(error.response.data.message);
        }).finally(() => {
          this.showOverlay = false;
        })
    },
    sortChanged (ctx) { // should not update row when sort by status, b-table might update wrong row
      if (ctx.sortBy=="status") {
        this.noEdit=true;
      }else{
        this.noEdit=false;
      }
    },
    memberSelected (member) {
      this.$refs.searchMemberForm.hide()
      this.selectedMember = member;
    },
    makeToast(msg, variant = null) {
      this.$bvToast.toast(msg, {
        title: `Status`,
        variant: variant,
        solid: true
      })
    },
    statsCount () {
      this.list.forEach(function(e){
        e.cash = 0;
        e.credit = 0;
        e.checked_in = 0;
        e.gold_pass = 0;
        e.standard_pass = 0;
        e.reserved.forEach(function(r){
          if (r.status == 2) {
            e.cash += 1;
          } else if (r.status==4) {
            e.credit += 1;
          } else if (r.status==1) {
            e.checked_in += 1;
          } else if (r.status==5) {
            e.gold_pass += 1;
          } else if (r.status==6) {
            e.standard_pass += 1;
          }
        })
      })
    },
    memberCodeDetected (code) { // member from register
     this.$bvModal.hide('modal-scanner')

      // first check to see if the member is already in the event list
      let index = -1
      this.list.every((l,i) => {
        if (l.event_detail.id == this.selectedEventId) {
          index = i
          return false
        }
        return true
      })

      let member = null
      if (index!=-1) {
        this.list[index].reserved.every((v) => {
          if (v.member_code == code) {
            member = v
            return false
          }
          return true
        })
      }

      var c = this.$refs.checkin
      if (member) { //found member in event list
        c.reservedCheckin(member.id, member.member_id, member.player_names, member.event_id, 6, member.notes, this.user)
      }else{
        c.createCheckin(code, this.selectedEventId, this.user)
      }
    },
    onScannerClose(){
      var s = this.$refs.scanner
      s.stopScanner()
    },
    showMsgboxOk(html, variant) {
      if (variant == "") variant = 'success';

      let n = this.$createElement('div', { domProps: { innerHTML: html } })

      this.$bvModal.msgBoxOk(n, {
        buttonSize: 'sm',
        okVariant: variant,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
    onMemberCheckedin(e) {
      this.$refs.queue.create_queue_entry(e.member_name,e.member_id)
      this.getReservedEvents();
    },
    onReservedCheckedin(e) { //called by checkin tool
      //1=VIP checking, 2=entry pass

      let api = '/api/staff/set_status'
      let d = {}
      let checked_in_status=e.checked_in_status
      let notes = e.notes

      d.id = e.reserve_id
      if (checked_in_status==0) { //guest paid and checked in
        if (e.payment_status == 1) {
          d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "checked in" + '\n\n' + notes;
        }else if (e.payment_status == 2) {
          d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "paid cash" + '\n\n' + notes;
        }else if (e.payment_status == 4) {
          d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "paid credit" + '\n\n' + notes;
        }
          d.status = e.payment_status
      }else if (checked_in_status==1) {
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "status changed to Gold Pass" + '\n\n' + notes;
        d.status = 5
      }else if (checked_in_status==2) {
        d.notes = ((new Date()).toString().split("GMT"))[0] + '\n' + this.user + '\n' + "status changed to Standard Pass" + '\n\n' + notes;
        d.status = 6
      }

      d.status = parseInt(d.status); //make sure it is int, payment_status can be string
      this.$http.post(api,d,
        ).then((resp) => {
          if (resp.data.error) alert(resp.data.error);

          this.statsCount()
        }).catch((error) => {
          this.makeToast('Update failed: '+ error.response.data.message,'danger');
          this.getReservedEvents() //reset status
          console.log(error.response.data.message);
        })

      //input member info into system queue
      console.log('onReservedCheckedin',e)
      this.$refs.queue.create_queue_entry(e.member_name,e.member_id)
    },
    getMemberHistory(id){
      if (id<=0) return;

      this.selectedMemberId = id
      this.$refs.['modal-member-history'].show()
   },
    showRecentExpired(){
      this.$refs.['modal-recent-expires'].show()
      return;
    },
    queue_system_entry(e) {
      this.$refs.queue.create_queue_entry(e.item.player_names,e.item.member_id)
      this.makeToast(e.item.player_names + ' requested system entry' , 'success');
    },
  },
}
</script>

<style scoped>
.mini-icon:active {
  color: #f13131;
}

</style>

