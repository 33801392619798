import userAPI from '../../api/user.js'

const state = {
  user: "",
  roles: [],
  member_id: 0,
  first_name: '',
  last_name: '',
}

const actions = {
  loadUserData( {commit} ,payload){
    var next = payload.next
    var forbid = payload.forbid
    var next_always = payload.next_always

    userAPI.getHeaderData(
      (user) => {
        commit('logging_in', user.login)
        commit('set_user_roles', user.roles)
        commit('set_member_id', user.member_id)
        commit('set_first_name', user.first_name)
        commit('set_last_name', user.last_name)
        next()
      },
      () => {
        commit('logging_out')
        if (next_always) {
          next()
        } else {
          next({name: forbid})
        }
      },
    )
  },
}

const mutations = {
    logging_in (state, user) {
      state.user = user
    },
    logging_out (state) {
      state.user = ""
      state.roles = []
      state.member_id = 0
      state.first_name = ''
      state.last_name = ''
    },
    set_user_roles (state, roles) {
      state.roles = roles
    },
    set_member_id (state, id) {
      state.member_id = id
    },
    set_first_name (state, name) {
      state.first_name = name
    },
    set_last_name (state, name) {
      state.last_name = name
    }
}

const getters = {
  checkRole: state => (role) => {
     return state.roles.includes(role)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
