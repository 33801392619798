<template>
  <div class="admin_list_category">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>{{ title }}</h5>
      <div class="container">
        <b-form-select v-model="selected_category_id" :options="all_category" text-field="category_summary" value-field="id" @change="selectedCategory" :select-size="num_list_items"></b-form-select>
      </div>
      <div class="t-3">Selected: <strong>{{ selected_category_id }}</strong></div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    'num_list_items': {  //how many list items are visible; 1=droplist >1=select list
      type: Number,
      default: 1,
    },
    'empty_option': {  //should there be a empty option with value of 0
      type: Boolean,
      default: true,
    },
    'title': {
      type: String,
      default: 'Product Category',
    },
    'selected_value': {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_category_id: null,
      all_category: [],
    }
  },
  mounted () {
    this.getCategory();
  },
  components: {
  },
  computed: {
    selectedOption() {
      return this.all_category.find(option => option.id == this.selected_category_id)
    }
  },
  methods:{
    getCategory () {
      this.$http.get('/api/admin/list_category'
      ).then((resp) => {
        this.all_category = resp.data.all_category;
        this.all_category.forEach( (el,i) => this.all_category[i].category_summary = el.category + ': ' + el.description + ' (' + el.date_created.split("T")[0]+')')
        if (this.empty_option) this.all_category.unshift({'id':0,'category_summary':''})
        if (this.selected_value>0) this.selected_category_id = this.selected_value
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    selectedCategory () {
      this.$emit('category_selected',{
        'id': parseInt(this.selected_category_id),
        'category': this.selectedOption.category,
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
