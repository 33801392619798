<template>
  <div class="staff">

    <div class="container" style="background:rgba(255,255,255,.9);">
      <h5 class="p-3">
        Member Search<span> | </span>
        <b-button v-b-modal.modal-scanner class="m-3 btn-info btn-sm">Scan Member Card</b-button>
      </h5>

      <div class="row">
        <div class="col-sm-6">
          <label class="placeholder">
            <b-form-input v-model.trim="first_name" placeholder=" "></b-form-input>
            <span>First name</span>
          </label>
        </div>
        <div class="col-sm-6">
          <label class="placeholder">
            <b-form-input v-model.trim="last_name" placeholder=" "></b-form-input>
            <span>Last name</span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <label class="placeholder">
            <b-form-input v-model.trim="phone" placeholder=" "></b-form-input>
            <span>Phone</span>
          </label>
        </div>

        <div class="col-sm-6">
          <label class="placeholder">
            <b-form-input v-model.trim="email" placeholder=" "></b-form-input>
            <span>Email</span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <label class="placeholder">
            <b-form-input v-model.trim="id" @blur="fCheckId" :state="fIdOk" placeholder=" "></b-form-input>
            <span>Member ID</span>
            <p v-if="fIdOk!=false">Member Id</p>
            <b-form-invalid-feedback :force-show="fIdOk==false">ID not valid</b-form-invalid-feedback>
          </label>
        </div>
      </div>

      <b-button @click="getMembers" style="margin:10px">Search</b-button>
    </div>

    <div style="height:50px"></div>

    <div class="container" style="background:rgba(255,255,255,.9);">
      <b-overlay :show="showOverlay" rounded="sm">
        <h5 class="p-3">Member Search result</h5>
        <div style="text-align:center; width:100%">
          <b-table responsive striped hover :items="all_user" :fields="user_fields" id="staff_all_reserved">

            <template v-slot:cell(select)="row">
              <b-button size="sm"  @click="selectMember(row)" style="margin:3px">select</b-button>
            </template>

          </b-table>
        </div>
      </b-overlay>
    </div>

    <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input required fields with valid data</b-alert>

    <b-modal id="modal-scanner" hide-footer title="Scan Membership Card" @close="onScannerClose" no-close-on-backdrop>
      <qrScanner ref="scanner" v-on:code_detected="memberCodeDetected($event)"></qrScanner>
    </b-modal>

  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'
import qrScanner from '../reusable/qr_scanner.vue';
export default {
  mixins: [datetime_mixin],
  components: {
    qrScanner,
  },
  data() {
    return {
      user_fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'first_name',
          label: 'First name',
          sortable: true,
        },
        {
          key: 'last_name',
          label: 'Last name',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'select',
          label: '',
        },
      ],
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      id: "",
      all_user: [],
      showOverlay: false,
      showAlert: false,
      fIdOk: true,
    }
  },
  mounted () {
  },
  computed: {
  },
  methods:{
    getMembers () {
      this.showAlert = false;
      if ((this.id == "0" && this.first_name == "" && this.last_name == "" && this.phone == "" && this.email == "") || !this.fIdOk) { // check if fields are empty then check ID
        this.fCheckId();
        this.showAlert = true;
        return
      }
      this.showOverlay = true;
      this.all_user = []
      var d = {}
      d.first_name = this.first_name;
      d.last_name = this.last_name;
      d.phone = this.phone.replace(/[^0-9]/g,"");       // remove all non-digits
      d.email = this.email;
      d.id = this.id;
      this.$http.post('/api/staff/search',d,
      ).then((resp) => {
        this.all_user = resp.data.all_user;
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      }).finally(() => {
        this.showOverlay = false;
      })
    },
    getMemberByCode (code) {
      if (!code) return

      this.showOverlay = true;
      this.$http.get('/api/staff/search_by_code?c='+code,
      ).then((resp) => {
        let r = resp.data.user
        this.$emit('memberSelected',{
          'id': parseInt(r.id),
          'first_name': r.first_name,
          'last_name': r.last_name,
          'email': r.email,
          'city': r.city,
          'phone': r.phone,
          'member_code': r.member_code,
          'paid_member_type': r.paid_member_type,
          'member_expires': r.member_expires,
          'photo': r.photo,
          'free_pass': r.free_pass,
        })
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      }).finally(() => {
        this.showOverlay = false;
      })
    },
    selectMember (r) {
      this.$emit('memberSelected',{
        'id': parseInt(r.item.id),
        'first_name': r.item.first_name,
        'last_name': r.item.last_name,
        'email': r.item.email,
        'city': r.item.city,
        'phone': r.item.phone,
        'member_code': r.item.member_code,
        'paid_member_type': r.item.paid_member_type,
        'member_expires': r.item.member_expires,
        'free_pass': r.item.free_pass,
        'photo': r.item.photo,
      })
    },
    memberCodeDetected (code) { // member from register
      this.getMemberByCode(code)
      this.$bvModal.hide('modal-scanner')
    },
    onScannerClose(){
      var s = this.$refs.scanner
      s.stopScanner()
    },
    fCheckId () {
     this.fIdOk = (this.id.trim()!="" && (/^[0-9]+$/.test(this.id.trim())))
    },
  },
}
</script>
