<template>
  <div class="email">
    <div style="width:100%;">
      <div style="margin:auto; padding:20px;">

        <b-container class="form fluid">
        <b-card class="text-center" style="background:rgba(255,255,255,.9); padding:20px;">
          <h5>Login Email Change</h5>
          <hr style="margin-bottom:50px;">

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model="password" type="password" @blur="fCheckPassword" :state="fPasswordOk" placeholder=" "></b-form-input>
              <span>Password *</span>
              <p v-if="fPasswordOk!=false">Your current Password</p>
              <b-form-invalid-feedback :force-show="fPasswordOk==false">Password is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
                <b-form-input v-model="new_email" @blur="fCheckNewEmail" :state="fNewEmailOk" placeholder=" "></b-form-input>
                <span>New Email *</span>
                <p v-if="fNewEmailOk!=false">This will be your new login email</p>
                <b-form-invalid-feedback :force-show="fNewEmailOk==false">Valid email is required</b-form-invalid-feedback>
            </label>
          </p>

          <b-button variant="outline-secondary" @click="changeEmail" style="margin-top:30px; width:80%; max-width:300px">Change Email</b-button>

        </b-card>
        </b-container>

        <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input required fields with valid data</b-alert>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      new_email: "",
      fNewEmailOk: null,
      password: "",
      fPasswordOk: null,
      showAlert: false,
    }
  },
  methods:{
    changeEmail() {
      this.showAlert = false;
      if (this.new_email.trim()=="" || this.password.trim()=="") {
        //alert('All fields are required.')
        this.showAlert = true;
        return;
      }
      this.$http.put('/api/member/email_change',{
        "new_email" : this.new_email.trim(),
        "password"  : this.password.trim()
      }).then((resp) => {
        this.history=resp.data;
        alert('Email has been changed. Sign in with updated email');
        //this.$router.push({name: 'SignOut'})
        this.signout();
      }).catch((error) => {
        alert("Fail to change email, please try again with correct password");
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckNewEmail () {
     // eslint-disable-next-line
     this.fNewEmailOk = (this.new_email.trim()!="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.new_email.trim())))
    },
    fCheckPassword () {
      this.fPasswordOk = this.password.trim()  != "";
    },
    signout() {  //
      console.log("sigining out!!")
      this.$http.get('/api/member/sign_out'
      ).then(() => {
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      }).finally(() => {
        this.$store.commit("logging_out")
        this.$router.push({name: 'Home'})
      })
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
</style>
