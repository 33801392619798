<template>
  <div class="new_product">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Admin New Product</h5>
        <hr style="margin-bottom:50px;">

        <b-button @click="showCategoryForm(v => category_id1 = v, category_id1)" class="m-3">Category 1 *</b-button>
        <b-button @click="showCategoryForm(v => category_id2 = v, category_id2)" class="m-3">Category 2</b-button><br>

        <b-button @click="showAttributeForm(v => attribute_id1 = v, attribute_id1)" class="m-3">Attribute 1</b-button>
        <b-button @click="showAttributeForm(v => attribute_id2 = v, attribute_id2)" class="m-3">Attribute 2</b-button>
        <b-button @click="showAttributeForm(v => attribute_id3 = v, attribute_id3)" class="m-3">Attribute 3</b-button>
        <b-button @click="showAttributeForm(v => attribute_id4 = v, attribute_id4)" class="m-3">Attribute 4</b-button>
        <b-button @click="showAttributeForm(v => attribute_id5 = v, attribute_id5)" class="m-3">Attribute 5</b-button><br>

        <b-modal id="modal-category-list" ok-only centered title="Choose Category" ref="categoryForm" button-size="sm">
          <productCategory title="Select Category" :selected_value="tmpSelectedVal" v-on:category_selected="categorySelected($event)"></productCategory>
        </b-modal>

        <b-modal id="modal-attribute-list" ok-only centered title="Choose Product Attribute" ref="attributeForm" button-size="sm">
          <listAttribute title="Select Attribute" :categoryId1="category_id1.id" :categoryId2="category_id2.id" v-on:attribute_selected="attributeSelected($event)"></listAttribute>
        </b-modal>

          <p>
            Category 1 *<br><b>{{category_id1.category}}</b><br>
            Category 2<br><b>{{category_id2.category}}</b><br>
            Attribute 1<br><b>{{attribute_id1.name}}</b><br>
            Attribute 2<br><b>{{attribute_id2.name}}</b><br>
            Attribute 3<br><b>{{attribute_id3.name}}</b><br>
            Attribute 4<br><b>{{attribute_id4.name}}</b><br>
            Attribute 5<br><b>{{attribute_id5.name}}</b><br>
          </p>
          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="name" @blur="fCheckName" :state="fNameOk" placeholder=" "></b-form-input>
              <span>Name *</span>
              <p v-if="fNameOk!=false">Name</p>
              <b-form-invalid-feedback :force-show="fNameOk==false">Name is required</b-form-invalid-feedback>
              </label>
          </p>

         <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="description" @blur="fCheckDescription" :state="fDescriptionOk" placeholder=" "></b-form-input>
              <span>Description *</span>
              <p v-if="fDescriptionOk!=false">Description of category</p>
              <b-form-invalid-feedback :force-show="fDescriptionOk==false">Description is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="msrp" @blur="fCheckMsrp" :state="fMsrpOk" placeholder=" "></b-form-input>
              <span>Manufacturer Suggested Retail Price *</span>
              <p v-if="fMsrpOk!=false">Manufacturer Suggested Retail Price</p>
              <b-form-invalid-feedback :force-show="fMsrpOk==false">Manufacturer Suggested Retail Price is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="member_price" @blur="fCheckMemberPrice" :state="fMemberPriceOk" placeholder=" "></b-form-input>
              <span>Member Price *</span>
              <p v-if="fMemberPriceOk!=false">Member Price</p>
              <b-form-invalid-feedback :force-show="fMemberPriceOk==false">Member Price is required</b-form-invalid-feedback>
              </label>
          </p>

           <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="map" @blur="fCheckMap" :state="fMapOk" placeholder=" "></b-form-input>
              <span>MAP (minimum advertised price) *</span>
              <p v-if="fMapOk!=false">MAP (minimum advertised price)</p>
              <b-form-invalid-feedback :force-show="fMapOk==false">MAP is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="listing_price" @blur="fCheckListingPrice" :state="fListingPriceOk" placeholder=" "></b-form-input>
              <span>Listing Price *</span>
              <p v-if="fListingPriceOk!=false">List Prince</p>
              <b-form-invalid-feedback :force-show="fListingPriceOk==false">Listing Price is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="commission" @blur="fCheckCommission" :state="fCommissionOk" placeholder=" "></b-form-input>
              <span>Commission *</span>
              <p v-if="fCommissionOk!=false">Commission</p>
              <b-form-invalid-feedback :force-show="fCommissionOk==false">Commission is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="sale" @blur="fCheckSale" :state="fSaleOk" placeholder=" "></b-form-input>
              <span>Sale *</span>
              <p v-if="fSaleOk!=false">Sale</p>
              <b-form-invalid-feedback :force-show="fSaleOk==false">Sale is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="member_sale" @blur="fCheckMemberSale" :state="fMemberSaleOk" placeholder=" "></b-form-input>
              <span>Member Sale *</span>
              <p v-if="fMemberSaleOk!=false">Member Sale</p>
              <b-form-invalid-feedback :force-show="fMemberSaleOk==false">Member Sale is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="brand" @blur="fCheckBrand" :state="fBrandOk" placeholder=" "></b-form-input>
              <span>Brand *</span>
              <p v-if="fBrandOk!=false">Brand</p>
              <b-form-invalid-feedback :force-show="fBrandOk==false">Brand is required</b-form-invalid-feedback>
              </label>
          </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="made_in" @blur="fCheckMadeIn" :state="fMadeInOk" placeholder=" "></b-form-input>
              <span>Made In *</span>
              <p v-if="fMadeInOk!=false">Made In</p>
              <b-form-invalid-feedback :force-show="fMadeInOk==false">Made In is required</b-form-invalid-feedback>
              </label>
          </p>

          <b-button variant="outline-secondary" @click="createProduct" style="margin-top:30px; width:80%; max-width:300px">Create Product</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
import productCategory from './list_category.vue';
import listAttribute from './list_attribute.vue';

export default {
  components: {
    productCategory,
    listAttribute,
  },
  data() {
    return {
      tmpSelectedVal: 0,
      tmpFn: {},
      category_id1: 0,
      category_id2: 0,
      name: '',
      fNameOk: null,
      description: '',
      fDescriptionOk: null,
      msrp: "0",
      fMsrpOk: null,
      member_price: "0",
      fMemberPriceOk: null,
      map : "0",
      fMapOk: null,
      listing_price: "0",
      fListingPriceOk: null,
      commission: "0",
      fCommissionOk: null,
      sale: "0",
      fSaleOk: null,
      member_sale: "0",
      fMemberSaleOk: null,
      attribute_id1: "0",
      attribute_id2: "0",
      attribute_id3: "0",
      attribute_id4: "0",
      attribute_id5: "0",
      brand: "",
      fBrandOk: null,
      made_in: "",
      fMadeInOk: null,
    }
  },
  methods:{
    showCategoryForm(fn, v) {
      this.tmpSelectedVal = v.id
      this.tmpFn = fn
      this.$refs['categoryForm'].show()
    },
    categorySelected (category) {
      this.tmpFn(category)
    },
    showAttributeForm(fn, v) {
      this.tmpSelectedVal = v.id
      this.tmpFn = fn
      this.$refs['attributeForm'].show()
    },
    attributeSelected (attr) {
      this.tmpFn(attr)
    },

    createProduct() {
      if (!this.fNameOk || !this.fDescriptionOk || !this.fMsrpOk || !this.fMemberPriceOk || !this.fMapOk || !this.fListingPriceOk
         || !this.fCommissionOk || !this.fSaleOk || !this.fMemberSaleOk || !this.fBrandOk || !this.fMadeInOk) {
        this.fCheckName();
        this.fCheckDescription();
        this.fCheckMsrp();
        this.fCheckMemberPrice();
        this.fCheckMap();
        this.fCheckListingPrice();
        this.fCheckCommission();
        this.fCheckSale();
        this.fCheckMemberSale();
        this.fCheckBrand();
        this.fCheckMadeIn();
        alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/new_product',{
        "category_id1" : parseInt(this.category_id1.id),
        "category_id2" : parseInt(this.category_id2.id),
        "name" :        this.name,
        "description" : this.description,
        "msrp" :        parseFloat(this.msrp),
        "member_price" : parseFloat(this.member_price),
        "map" :         parseFloat(this.map),
        "listing_price" : parseFloat(this.listing_price),
        "commission"  : parseFloat(this.commission),
        "sale" :        parseFloat(this.sale),
        "member_sale" : parseFloat(this.member_sale),
        "attribute_id1" : parseInt(this.attribute_id1.id),
        "attribute_id2" : parseInt(this.attribute_id2.id),
        "attribute_id3" : parseInt(this.attribute_id3.id),
        "attribute_id4" : parseInt(this.attribute_id4.id),
        "attribute_id5" : parseInt(this.attribute_id5.id),
        "brand" :       this.brand,
        "made_in" :     this.made_in,
      }).then(() => {
        alert('Product "' + this.name + '" has been created');
        this.category_id1 = ""; //to prevent double submitting
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckName() {
      this.fNameOk = this.name.trim() != "";
    },
    fCheckDescription() {
      this.fDescriptionOk = this.description.trim() != "";
    },
    fCheckMsrp() {
      this.fMsrpOk = (this.msrp.trim() != "" && (/^(?!0*\.?0*$)\d*\.?\d*$/.test(this.msrp.trim())));    // regex accepts any number except 0
    },
    fCheckMemberPrice() {
      this.fMemberPriceOk = (this.member_price.trim() != "" && (/^(?!0*\.?0*$)\d*\.?\d*$/.test(this.member_price.trim())));
    },
    fCheckMap() {
      this.fMapOk = (this.map.trim() != "" && (/^(?!0*\.?0*$)\d*\.?\d*$/.test(this.map.trim())));
    },
    fCheckListingPrice() {
      this.fListingPriceOk = (this.listing_price.trim() != "" && (/^(?!0*\.?0*$)\d*\.?\d*$/.test(this.listing_price.trim())));
    },
    fCheckCommission() {
      this.fCommissionOk = (this.commission.trim() != "" && (/^(?!0*\.?0*$)\d*\.?\d*$/.test(this.commission.trim())));
    },
    fCheckSale() {
      this.fSaleOk = (this.sale.trim() != "" && (/([0-9]*[.])?[0-9]+?$/.test(this.sale.trim())));
    },
    fCheckMemberSale() {
      this.fMemberSaleOk = (this.member_sale.trim() != "" && (/([0-9]*[.])?[0-9]+?$/.test(this.member_sale.trim())));
    },
    fCheckBrand() {
      this.fBrandOk = this.brand.trim() != "";
    },
    fCheckMadeIn() {
      this.fMadeInOk = this.made_in.trim() != "";
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
