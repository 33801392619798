<template>
  <div class="admin_new_event">
    <b-container class="form fluid">
    <b-card class="text-center">

     <div class="form-title">Admin Event Page</div>
     <div class="sub-title">Fill in Event information</div>
      <b-row>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="center_id" @blur="fCheckCenterId" :state="fCenterIdOk" placeholder=" "></b-form-input>
            <span>Center ID *</span>
            <b-form-invalid-feedback :force-show="fCenterIdOk===false">Center ID is required</b-form-invalid-feedback>
          </label>
        </b-col>
         <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="event_type_id" @blur="fCheckEventTypeId" :state="fEventTypeIdOk" placeholder=" "></b-form-input>
            <span>Event Type ID* (1: open play/2: court rental)</span>
            <b-form-invalid-feedback :force-show="fEventTypeIdOk==false">Event Type ID is required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="court_reservable" @blur="fCheckCourtReservable" :state="fCourtReservableOk" placeholder=" "></b-form-input>
            <span>Court Reservable*</span>
            <p v-if="fCourtReservableOk!=false">How many court is available to be reserved. 0=open play</p>
            <b-form-invalid-feedback :force-show="fCourtReservableOk==false">Court reservable is required</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="min_reserve_time" @blur="fCheckMinReserveTime" :state="fMinReserveTimeOk" placeholder=" "></b-form-input>
            <span>Minimum Reserve Time</span>
            <p v-if="fMinReserveTimeOk!=false">Minimum Reserve Time in minutes</p>
            <b-form-invalid-feedback :force-show="fMinReserveTimeOk==false">Minimum Reserve Time is invalid</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="reserve_interval" @blur="fCheckReserveInterval" :state="fReserveIntervalOk" placeholder=" "></b-form-input>
            <span>Reserve Interval</span>
            <p v-if="fReserveIntervalOk!=false">The allowed interval after initial period in minutes</p>
            <b-form-invalid-feedback :force-show="fReserveIntervalOk==false">Reserve Interval time is invalid</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="reserve_interval_price" @blur="fCheckReserveIntervalPrice" :state="fReserveIntervalPriceOk" placeholder=" "></b-form-input>
            <span>Reserve Interval Price</span>
            <p v-if="fReserveIntervalPriceOk!=false">The price for each reserve interval</p>
            <b-form-invalid-feedback :force-show="fReserveIntervalPriceOk==false">Reserve Interval Price is invalid</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="name" @blur="fCheckName" :state="fNameOk" placeholder=" "></b-form-input>
            <span>Name*</span>
            <p v-if="fNameOk!=false">Name required</p>
            <b-form-invalid-feedback :force-show="fNameOk==false">Name is required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model="description" placeholder=" "></b-form-input>
            <span>Description (html)</span>
          </label>
        </b-col>

        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="court" maxlength="50" @blur="fCheckCourt" :state="fCourtOk" placeholder=" "></b-form-input>
            <span>Court (ex 1,2,3)*</span>
            <p v-if="fCourtOk!=false">Court required</p>
            <b-form-invalid-feedback :force-show="fCourtOk==false">Court reservable is required</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="event_date" @blur="fCheckEventDate" :state="fEventDateOk" placeholder=" "></b-form-input>
            <span>Date (MM/DD/YYYY)*</span>
            <p v-if="fEventDateOk!=false">Event Date required</p>
            <b-form-invalid-feedback :force-show="fEventDateOk==false">Event Date is required</b-form-invalid-feedback>
          </label>

          <label class="placeholder">
            <b-form-input v-model.trim="more_event_date[0]" @blur="fCheckEventDate2" :state="fEventDate2Ok" placeholder=" "></b-form-input>
            <span>Date (MM/DD/YYYY)</span>
            <p v-if="fEventDate2Ok!=false">Additional Event Date</p>
            <b-form-invalid-feedback :force-show="fEventDate2Ok==false">Incorrect Date</b-form-invalid-feedback> 
          </label>

          <label class="placeholder">
            <b-form-input v-model.trim="more_event_date[1]" @blur="fCheckEventDate3" :state="fEventDate3Ok" placeholder=" "></b-form-input>
            <span>Date (MM/DD/YYYY)</span>
            <p v-if="fEventDate3Ok!=false">Additional Event Date</p>
            <b-form-invalid-feedback :force-show="fEventDate3Ok==false">Incorrect Date</b-form-invalid-feedback> 
          </label>

          <label class="placeholder">
            <b-form-input v-model.trim="more_event_date[2]" @blur="fCheckEventDate4" :state="fEventDate4Ok" placeholder=" "></b-form-input>
            <span>Date (MM/DD/YYYY)</span>
            <p v-if="fEventDate4Ok!=false">Additional Event Date</p>
            <b-form-invalid-feedback :force-show="fEventDate4Ok==false">Incorrect Date</b-form-invalid-feedback> 
          </label>

          <label class="placeholder">
            <b-form-input v-model.trim="more_event_date[3]" @blur="fCheckEventDate5" :state="fEventDate5Ok" placeholder=" "></b-form-input>
            <span>Date (MM/DD/YYYY)</span>
            <p v-if="fEventDate5Ok!=false">Additional Event Date</p>
            <b-form-invalid-feedback :force-show="fEventDate5Ok==false">Incorrect Date</b-form-invalid-feedback> 
          </label>

          <label class="placeholder">
            <b-form-input v-model.trim="more_event_date[4]" @blur="fCheckEventDate6" :state="fEventDate6Ok" placeholder=" "></b-form-input>
            <span>Date (MM/DD/YYYY)</span>
            <p v-if="fEventDate6Ok!=false">Additional Event Date</p>
            <b-form-invalid-feedback :force-show="fEventDate6Ok==false">Incorrect Date</b-form-invalid-feedback> 
          </label>

          <!-- <label class="placeholder"> -->
          <!--   <b-form-input v-model.trim="more_event_date[5]" @blur="fCheckEventDate7" :state="fEventDate7Ok" placeholder=" "></b-form-input> -->
          <!--   <span>Date (MM/DD/YYYY)</span> -->
          <!--   <p v-if="fEventDate7Ok!=false">Additional Event Date</p> -->
          <!--   <b-form-invalid-feedback :force-show="fEventDate7Ok==false">Incorrect Date</b-form-invalid-feedback> --> 
          <!-- </label> -->

          <!-- <label class="placeholder"> -->
          <!--   <b-form-input v-model.trim="more_event_date[6]" @blur="fCheckEventDate8" :state="fEventDate8Ok" placeholder=" "></b-form-input> -->
          <!--   <span>Date (MM/DD/YYYY)</span> -->
          <!--   <p v-if="fEventDate8Ok!=false">Additional Event Date</p> -->
          <!--   <b-form-invalid-feedback :force-show="fEventDate8Ok==false">Incorrect Date</b-form-invalid-feedback> --> 
          <!-- </label> -->

          <!-- <label class="placeholder"> -->
          <!--   <b-form-input v-model.trim="more_event_date[7]" @blur="fCheckEventDate9" :state="fEventDate9Ok" placeholder=" "></b-form-input> -->
          <!--   <span>Date (MM/DD/YYYY)</span> -->
          <!--   <p v-if="fEventDate9Ok!=false">Additional Event Date</p> -->
          <!--   <b-form-invalid-feedback :force-show="fEventDate9Ok==false">Incorrect Date</b-form-invalid-feedback> -->
          <!-- </label> -->

          <!-- <label class="placeholder"> -->
          <!--   <b-form-input v-model.trim="more_event_date[8]" @blur="fCheckEventDate10" :state="fEventDate10Ok" placeholder=" "></b-form-input> -->
          <!--   <span>Date (MM/DD/YYYY)</span> -->
          <!--   <p v-if="fEventDate10Ok!=false">Additional Event Date</p> -->
          <!--   <b-form-invalid-feedback :force-show="fEventDate10Ok==false">Incorrect Date</b-form-invalid-feedback> -->
          <!-- </label> -->
        </b-col>

        <b-col sm="4">
          <div>
            <b-button variant="outline-secondary" @click="clear_selected_dates" style="width:150px;">clear selected</b-button>
            <br><br>
          </div>

            <input type="checkbox" id="day1" :value="twoWeekDate[0]" v-model="checkedDate">
            <label for="day1" style="margin-left:10px">{{twoWeekDate[0]}} {{dayOfWeek[twoWeekDayOfWeek[0]]}}</label>
            <br>

            <input type="checkbox" id="day2" :value="twoWeekDate[1]" v-model="checkedDate">
            <label for="day2" style="margin-left:10px">{{twoWeekDate[1]}} {{dayOfWeek[twoWeekDayOfWeek[1]]}}</label>
            <br>

            <input type="checkbox" id="day3" :value="twoWeekDate[2]" v-model="checkedDate">
            <label for="day3" style="margin-left:10px">{{twoWeekDate[2]}} {{dayOfWeek[twoWeekDayOfWeek[2]]}}</label>
            <br>

            <input type="checkbox" id="day4" :value="twoWeekDate[3]" v-model="checkedDate">
            <label for="day4" style="margin-left:10px">{{twoWeekDate[3]}} {{dayOfWeek[twoWeekDayOfWeek[3]]}}</label>
            <br>

            <input type="checkbox" id="day5" :value="twoWeekDate[4]" v-model="checkedDate">
            <label for="day5" style="margin-left:10px">{{twoWeekDate[4]}} {{dayOfWeek[twoWeekDayOfWeek[4]]}}</label>
            <br>

            <input type="checkbox" id="day6" :value="twoWeekDate[5]" v-model="checkedDate">
            <label for="day6" style="margin-left:10px">{{twoWeekDate[5]}} {{dayOfWeek[twoWeekDayOfWeek[5]]}}</label>
            <br>

            <input type="checkbox" id="day7" :value="twoWeekDate[6]" v-model="checkedDate">
            <label for="day7" style="margin-left:10px">{{twoWeekDate[6]}} {{dayOfWeek[twoWeekDayOfWeek[6]]}}</label>
            <br>

            <input type="checkbox" id="day8" :value="twoWeekDate[7]" v-model="checkedDate">
            <label for="day8" style="margin-left:10px">{{twoWeekDate[7]}} {{dayOfWeek[twoWeekDayOfWeek[7]]}}</label>
            <br>

            <input type="checkbox" id="day9" :value="twoWeekDate[8]" v-model="checkedDate">
            <label for="day9" style="margin-left:10px">{{twoWeekDate[8]}} {{dayOfWeek[twoWeekDayOfWeek[8]]}}</label>
            <br>

            <input type="checkbox" id="day10" :value="twoWeekDate[9]" v-model="checkedDate">
            <label for="day10" style="margin-left:10px">{{twoWeekDate[9]}} {{dayOfWeek[twoWeekDayOfWeek[9]]}}</label>
            <br>

            <input type="checkbox" id="day11" :value="twoWeekDate[10]" v-model="checkedDate">
            <label for="day11" style="margin-left:10px">{{twoWeekDate[10]}} {{dayOfWeek[twoWeekDayOfWeek[10]]}}</label>
            <br>

            <input type="checkbox" id="day12" :value="twoWeekDate[11]" v-model="checkedDate">
            <label for="day12" style="margin-left:10px">{{twoWeekDate[11]}} {{dayOfWeek[twoWeekDayOfWeek[11]]}}</label>
            <br>

            <input type="checkbox" id="day13" :value="twoWeekDate[12]" v-model="checkedDate">
            <label for="day13" style="margin-left:10px">{{twoWeekDate[12]}} {{dayOfWeek[twoWeekDayOfWeek[12]]}}</label>
            <br>

            <input type="checkbox" id="day14" :value="twoWeekDate[13]" v-model="checkedDate">
            <label for="day14" style="margin-left:10px">{{twoWeekDate[13]}} {{dayOfWeek[twoWeekDayOfWeek[13]]}}</label>
            <br>
        </b-col>

        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="start_time" @blur="fCheckStartTime" :state="fStartTimeOk" placeholder=" "></b-form-input>
            <span>Start Time (ex 7:00PM)*</span>
            <p v-if="fStartTimeOk!=false">Start Time required</p>
            <b-form-invalid-feedback :force-show="fStartTimeOk==false">Start Time is required</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="end_time" @blur="fCheckEndTime" :state="fEndTimeOk" placeholder=" "></b-form-input>
            <span>End Time (ex 10:00PM)*</span>
            <p v-if="fEndTimeOk!=false">End Time required</p>
            <b-form-invalid-feedback :force-show="fEndTimeOk==false">End Time is required</b-form-invalid-feedback>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="event_price" @blur="fCheckEventPrice" :state="fEventPriceOk" placeholder=" "></b-form-input>
            <span>Drop in Price</span>
            <p v-if="fEventPriceOk!=false">Drop in Price</p>
            <b-form-invalid-feedback :force-show="fEventPriceOk==false">Drop in Price is invalid</b-form-invalid-feedback>
          </label>
        </b-col>
        <b-col sm="4">
          <label class="placeholder">
            <b-form-input v-model.trim="max_member"  @blur="fCheckMaxMember" :state="fMaxMemberOk" placeholder=" "></b-form-input>
            <span>Max Member</span>
            <p v-if="fMaxMemberOk!=false">The max # of players can join. 0 = unlimit</p>
            <b-form-invalid-feedback :force-show="fMaxMemberOk==false">Max Member is invalid</b-form-invalid-feedback>
          </label>
        </b-col>

      </b-row>
      <div style="height:50px"></div>
      <b-button variant="outline-secondary" @click="create_event" style="width:90%; max-width:500px">Create Event</b-button>
    </b-card>

    <br>
    <div class="container">
      <b-card>
        <b-button variant="outline-secondary" @click="saveEventTemplate" style="width:90%; max-width:500px">Save Event Template</b-button>
        <br><br>
        <span  style="width:90%; max-width:500px">Load Event Template</span>
        <b-form-select v-model="selected_template_id" :options="all_event_templates" text-field="template" value-field="id" @change="selectedTemplate"></b-form-select>
        <div class="t-3">Selected: <strong>{{ selected_template_id }}</strong></div>
      </b-card>
    </div>
    </b-container>

    <b-alert v-model="showAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="danger" dismissible>Please input all fields with valid data</b-alert>

  </div>
</template>

<script>
export default {
  data () {
    return {
      center_id: "1",
      event_type_id: "",
      court_reservable: "0",
      min_reserve_time: "0",
      reserve_interval: "0",
      name: "",
      description: "",
      court: "1,2,3,4",
      reserve_interval_price: "0",
      max_member: "0",
      event_date: "",
      more_event_date: [], // event_date 2 to 10
      start_time: "",
      end_time: "",
      event_price: "0",
      showAlert: false,
      fCenterIdOk: true,
      fEventTypeIdOk: false,
      fCourtReservableOk: true,
      fMinReserveTimeOk: true,
      fReserveIntervalOk: true,
      fNameOk: false,
      fCourtOk: true,
      fReserveIntervalPriceOk: true,
      fMaxMemberOk: true,
      fEventDateOk: true,
      fEventDate2Ok: true,
      fEventDate3Ok: true,
      fEventDate4Ok: true,
      fEventDate5Ok: true,
      fEventDate6Ok: true,
//      fEventDate7Ok: true,
//      fEventDate8Ok: true,
//      fEventDate9Ok: true,
//      fEventDate10Ok: true,
      fStartTimeOk: false,
      fEndTimeOk: false,
      fEventPriceOk: true,
      checkedDate: [],
      twoWeekDate: [],        // 1 to 14
      twoWeekDayOfWeek: [],    // 1 to 14
      dayOfWeek: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      template: "",
      template_description: "",
      all_event_templates: [],
      selected_template_id: null,
    }
  },
  created () {
  },
  mounted () {
    for(var i=0;i<10;i++){ //init array with empty string, for data verification purpose later
        this.more_event_date.push("");
    }

    this.createTwoWeekDate();
    this.getEventTemplates();
  },
  methods:{
    clear_selected_dates() {
      this.checkedDate = [];
    },
    create_event () {
      this.showAlert = false;
      console.log(this.fCenterIdOk +", "+ this.fEventTypeIdOk  +", "+ this.fCourtReservableOk  +", "+ this.fMinReserveTimeOk  +", "+ this.fReserveIntervalOk
         +", "+ this.fNameOk +", "+ this.fCourtOk + ", " + this.fReserveIntervalPriceOk + ", " + this.fMaxMemberOk
        +", "+ this.fStartTimeOk + ", " + this.fEndTimeOk + ", " + this.fEventPriceOk
        +", "+ this.fEventDateOk + ", " + this.fEventDate2Ok + ", " + this.fEventDate3Ok + ", " + this.fEventDate4Ok )


      if (!this.fCenterIdOk || !this.fEventTypeIdOk || !this.fCourtReservableOk || !this.fMinReserveTimeOk || !this.fReserveIntervalOk
        || !this.fNameOk || !this.fCourtOk || !this.fReserveIntervalPriceOk || !this.fMaxMemberOk
        || !this.fStartTimeOk || !this.fEndTimeOk || !this.fEventPriceOk
        || !this.fEventDateOk || !this.fEventDate2Ok || !this.fEventDate3Ok || !this.fEventDate4Ok) {
        this.fCheckCenterId();
        this.fCheckEventTypeId();
        this.fCheckCourtReservable();
        this.fCheckMinReserveTime();
        this.fCheckReserveInterval();
        this.fCheckName();
        this.fCheckCourt();
        this.fCheckReserveIntervalPrice();
        this.fCheckMaxMember();
        this.fCheckEventDate();
        this.fCheckEventDate2();
        this.fCheckEventDate3();
        this.fCheckEventDate4();
//        this.fCheckEventDate5();
//        this.fCheckEventDate6();
//        this.fCheckEventDate7();
//        this.fCheckEventDate8();
//        this.fCheckEventDate9();
//        this.fCheckEventDate10();
        this.fCheckStartTime();
        this.fCheckEndTime();
        this.fCheckEventPrice();
        this.showAlert = true;
        return
      }

      let copy_event_date;
      let copy_more_event_date = [];
      if (this.checkedDate.length > 0) {
        copy_event_date = this.checkedDate[0];
        copy_more_event_date = this.checkedDate.slice(1);
      } else {
        copy_event_date = this.event_date;
        copy_more_event_date = this.more_event_date;
      }

      let max_backup=0;
      if (this.event_type_id=="1") max_backup=10; //set max backup for open play

      this.$http.post('/admin/new_event',{
        "center_id" :           parseInt(this.center_id),
        "event_type_id" :       parseInt(this.event_type_id),
        "court_reservable" :    parseInt(this.court_reservable),
        "min_reserve_time" :    parseInt(this.min_reserve_time),
        "reserve_interval" :    parseInt(this.reserve_interval),
        "name" :                this.name,
        "description" :         this.description,
        "court" :               this.court,
        "reserve_interval_price" :      parseFloat(this.reserve_interval_price),
        "max_member" :          parseInt(this.max_member),
        "event_date" :          copy_event_date,
        "more_event_date" :     copy_more_event_date,
        "start_time" :          this.start_time,
        "end_time" :            this.end_time,
        "event_price" :       parseFloat(this.event_price),
        "max_backup" : max_backup
     }).then(() => {
        alert("Event Created ")
        this.center_id = ""; //to prevent double submitting
      }).catch((error) => {
        alert(error.response.data.message)
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    createTwoWeekDate() {
      let today = new Date();   // set current date

      for(let i=0;i<14;i++) {
        today = this.addDays(today, 1);
        this.twoWeekDate[i] = (today.getMonth()+1) + '/' + today.getDate() + '/' + today.getFullYear();
        this.twoWeekDayOfWeek[i] = today.getDay();
      }
    },
    addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    }, 
    getEventTemplates () {
      this.$http.get('/api/admin/list_event_templates'
      ).then((resp) => {
        this.all_event_templates = resp.data.all_event_templates;
        this.all_event_templates.forEach( (el,i) => this.all_event_templates[i].template = el.template + ': ' + el.template_description)
      }).catch((error) => {
        alert(error.response.data.message)
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    saveEventTemplate () {
      this.showAlert = false;
      if (!this.fCenterIdOk || !this.fEventTypeIdOk || !this.fCourtReservableOk || !this.fMinReserveTimeOk || !this.fReserveIntervalOk
        || !this.fNameOk || !this.fCourtOk || !this.fReserveIntervalPriceOk || !this.fMaxMemberOk
        || !this.fStartTimeOk || !this.fEndTimeOk || !this.fEventPriceOk) {
        this.fCheckCenterId();
        this.fCheckEventTypeId();
        this.fCheckCourtReservable();
        this.fCheckMinReserveTime();
        this.fCheckReserveInterval();
        this.fCheckName();
        this.fCheckCourt();
        this.fCheckReserveIntervalPrice();
        this.fCheckMaxMember();
        this.fCheckStartTime();
        this.fCheckEndTime();
        this.fCheckEventPrice();
        this.showAlert = true;
        return
      }

      this.template = prompt("Enter template name: ").trim();
      if (this.template == "") {
        alert("template name can't be empty")
        return;
      } else if (this.template == null) {       // cancel button
        return;
      }

      this.template_description = prompt("Enter template description: ").trim();
      if (this.template_description == "" ) {
        alert("template description can't be empty")
        return;
      } else if (this.description == null) {       // cancel button
        return;
      }

      this.$http.post('/api/admin/save_event_template',{
        "center_id" :           parseInt(this.center_id),
        "event_type_id" :       parseInt(this.event_type_id),
        "court_reservable" :    parseInt(this.court_reservable),
        "min_reserve_time" :    parseInt(this.min_reserve_time),
        "reserve_interval" :    parseInt(this.reserve_interval),
        "name" :                this.name,
        "description" :         this.description,
        "court" :               this.court,
        "reserve_interval_price" :      parseFloat(this.reserve_interval_price),
        "max_member" :          parseInt(this.max_member),
        "start_time" :          this.start_time,
        "end_time" :            this.end_time,
        "event_price" :         parseFloat(this.event_price),
        "max_backup" :          this.max_backup,
        "template" :            this.template,
        "template_description" : this.template_description,
     }).then(() => {
        alert("Template Created ");
        this.getEventTemplates(); //get updated Template list from DB
        this.center_id = ""; //to prevent double submitting
      }).catch((error) => {
        alert(error.response.data.message)
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    selectedTemplate () {
      let id = this.selected_template_id - 1
      this.center_id          = this.all_event_templates[id].center_id + ""; // +"" to convert value back to string
      this.event_type_id      = this.all_event_templates[id].event_type_id + "";
      this.court_reservable   = this.all_event_templates[id].court_reservable + "";
      this.min_reserve_time   = this.all_event_templates[id].min_reserve_time + "";
      this.reserve_interval   = this.all_event_templates[id].reserve_interval + "";
      this.name               = this.all_event_templates[id].name;
      this.description        = this.all_event_templates[id].description;
      this.court              = this.all_event_templates[id].court + "";
      this.reserve_interval_price = this.all_event_templates[id].reserve_interval_price + "";
      this.max_member         = this.all_event_templates[id].max_member + "";
      this.start_time         = this.all_event_templates[id].start_time;
      this.end_time           = this.all_event_templates[id].end_time;
      this.event_price        = this.all_event_templates[id].event_price + "";
      this.max_backup         = this.all_event_templates[id].max_backup + "";
    },
    fCheckCenterId () {
      this.fCenterIdOk = (this.center_id.trim()!="" && (/^[0-9]+$/.test(this.center_id.trim())));
    },
    fCheckEventTypeId () {
      this.fEventTypeIdOk = (this.event_type_id.trim()!="" && (/^[0-9]+$/.test(this.event_type_id.trim())));
    },
    fCheckCourtReservable () {
      this.fCourtReservableOk = (this.court_reservable.trim()!="" && (/^[0-9]+$/.test(this.court_reservable.trim())));
    },
    fCheckMinReserveTime () {
      this.fMinReserveTimeOk = (this.min_reserve_time.trim()!="" || (/^[0-9]+$/.test(this.min_reserve_time.trim())));
    },
    fCheckReserveInterval () {
      this.fReserveIntervalOk = (this.reserve_interval.trim()!="" || (/^[0-9]+$/.test(this.reserve_interval.trim())));
    },
    fCheckName () {
      this.fNameOk = this.name.trim() != "";
    },
    fCheckCourt () {
      this.fCourtOk = (this.court.trim()!="" && (/^[0-9,]+$/.test(this.court.trim())));
    },
    fCheckReserveIntervalPrice () {
      this.fReserveIntervalPriceOk = (this.reserve_interval_price.trim()!="" || (/^[0-9.]+$/.test(this.reserve_interval_price.trim())));
    },
    fCheckMaxMember () {
      this.fMaxMemberOk = (this.max_member.trim()!="" || (/^[0-9]+$/.test(this.max_member.trim())));
    },
    fCheckEventDate () {
      this.fEventDateOk = (this.event_date.trim()!="" && (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.event_date.trim())))
        || this.checkedDate.length > 0;         // ignore Event Date field if Checked Box is checked
    },
    fCheckEventDate2 () {       // More Event Date can be empty or valid date
      this.fEventDateOk = (this.more_event_date[0].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[0].trim())));
    },
    fCheckEventDate3 () {
      this.fEventDate2Ok = (this.more_event_date[1].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[1].trim())));
    },
    fCheckEventDate4 () {
      this.fEventDate3Ok = (this.more_event_date[2].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[2].trim())));
    },
    fCheckEventDate5 () {
      this.fEventDate5Ok = (this.more_event_date[3].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[3].trim())));
    },
    fCheckEventDate6 () {
      this.fEventDate6Ok = (this.more_event_date[4].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[4].trim())));
    },
//    fCheckEventDate7 () {
//      this.fEventDate7Ok = (this.more_event_date[5].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[5].trim())));
//    },
//    fCheckEventDate8 () {
//      this.fEventDate8Ok = (this.more_event_date[6].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[6].trim())));
//    },
//    fCheckEventDate9 () {
//      this.fEventDate9Ok = (this.more_event_date[7].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[7].trim())));
//    },
//    fCheckEventDate10 () {
//      this.fEventDate10Ok = (this.more_event_date[8].trim()=="" ||  (/^[0-9]+[/][0-9]+[/]\d{4}$/.test(this.more_event_date[8].trim())));
//    },
    fCheckStartTime () {
      this.fStartTimeOk = (this.start_time.trim()!="" && (/^[0-9:]+[ApPmM]+$/.test(this.start_time.trim())));
    },
    fCheckEndTime () {
      this.fEndTimeOk = (this.end_time.trim()!="" && (/^[0-9:]+[aApPmM]+$/.test(this.end_time.trim())));
    },
    fCheckEventPrice () {
      this.fEventPriceOk = (this.event_price.trim()!="" || (/^[0-9.]+$/.test(this.event_price.trim())));
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
.error-text {
  color: #f13131 !important;
}
.form-title {
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 300;
}
.form {
  box-sizing: border-box;
}
.form .sub-title {
  padding: 30px 20px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}
.card, .card-header, .card-body, .card-footer, button{
    border-radius:0px !important;
}
input:not([type=checkbox]), textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
