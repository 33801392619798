import Vue from 'vue'
import VueRouter from 'vue-router';
import RouteTable from './routes.js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/global.css';
import { SidebarPlugin } from 'bootstrap-vue'
import store from './store/index.js'
import App from './App.vue'
import Ncbc from './NCBC.vue'
import SystemBare from './System.vue'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(SidebarPlugin)

var router = new VueRouter({
  mode: 'history',
  history: true,
  routes: RouteTable
})

router.beforeEach((to, from, next) => {
  if (to.query.redirect) next(to.query.redirect)
  else if (to.meta.requiresAuth) store.dispatch('loadUserData',{next:next,forbid:"Home",next_always:false})
  else store.dispatch('loadUserData',{next:next,forbid:"",next_always:true}) //try loading user data even path auth not required
})

let selectedApp = Ncbc;

//if system_bare in query, we use a different template and shows fullpage web without menu
let system_bare = window.location.href.includes("system_bare")

if (system_bare) { //full screen system app, with no header and menu
  selectedApp = SystemBare
}else if (window.location.hostname=="localhost") {
    selectedApp = Ncbc
    //selectedApp = App
} else {
  let urlParts = window.location.hostname.split(".")

  if (urlParts[0]=="norcalbadminton" || urlParts[1] == "norcalbadminton") { // norcalbadminton.com
    selectedApp = Ncbc
  }else if (urlParts[0] == "ncbc") { // badmintonunited.org
    selectedApp = Ncbc
  } else if (urlParts[0] == "www") { // badmintonunited.org
    selectedApp = App
  } else if (urlParts[0] == "badmintonunited") { // badmintonunited.org
    selectedApp = App
  }
}

new Vue({
  store,
  router: router,
  render: h => h(selectedApp),
}).$mount('#app')

