<!--
  module connects to local queue system for action such as:
  - input checked-in member into queue system
  - collecting drop in player names for queue system
  - display password
-->

<template>
  <div class="queue_system">
    <div style="cursor:pointer; z-index:10010; position:absolute; left:5px; top:5px;" @click="get_server_ip">
      <span v-if="readyState==1" style="color:#16EC0F;"><i class="fa-solid fa-link"></i></span>
      <span v-else style="color:#EC0F0F;"><i class="fa-solid fa-link-slash"></i></span>
      ready:{{ readyState}}
      ////
      ws:{{ ws.readyState}}
      ////
    </div>
  </div>
</template>

<script>
  import {Messages} from '../../assets/js/queue_system_messages.js';

export default {
  props: {
  },
  data() {
    return {
      codeInput: "",
      clientId: "",
      ws: {}, // websocket connection
      server_ip: "",
    }
  },
  computed: {
    readyState () { //vue 2 can't pick up object property changes, needs to be computed
      return this.ws.readyState
    }
  },
  mounted() {
    var self = this
    window.addEventListener("focus", function() {
      self.connect_ws()
    }, false);


      //this.server_ip = "queue-system.mybluemix.net";
      this.server_ip = "queue-system.norcalbadminton.com";
      this.connect_ws();
  },
  methods:{
    get_server_ip(){
      alert("Status: " +this.ws.readyState)
      //this.server_ip = prompt("Enter Server IP")
    },
    connect_ws() {
      if (this.server_ip==null || this.ws.readyState == 1 || this.ws.readyState == 0) { //OPEN or CONNECTING
        console.log("already connected, exit connect_ws")
        return;
      }

      var uri = 'ws:';
      var loc = window.location;

      if (loc.protocol === 'https:') {
        uri = 'wss:';
      }
      uri = uri+ '//'+this.server_ip+'/ws_connect';

      this.ws = new WebSocket(uri,null,null,null, {rejectUnauthorized: false}) //https://stackoverflow.com/questions/38242334/disable-websocket-certificate-validation
//      this.ws = new WebSocket(uri, {
//        rejectUnauthorized: false
//      })

      var self = this
      this.ws.onopen = function() {
        console.log('Connected')
      }

      this.ws.onerror = function(e) {
        //this.server_ip = prompt("Enter server ip", "Server ip");
        //if (this.server_ip!=null) this.connect_ws()
        console.error('Socket encountered error: ', e.message, 'Closing socket');
        self.ws.close();
      }

      this.ws.onclose = function() {
          console.log('Disconnected!');
          self.connect_ws() //connect now then try again just in case connection fail
          setTimeout(function() {
            self.connect_ws()
          }, 3000);
      };

      this.ws.onmessage = function(evt) {
        //might get more than 1 msg at once
        //need to break up msgs
        var msgArray = evt.data.split(Messages.SPACER); //seperate potential multiple msg from server
        for (let i = 0; i < msgArray.length; i++) {
          var d = JSON.parse(msgArray[i])
          //server send client id
          if (d.msg_type==3) self.clientId = JSON.parse(d.body).id
        }
      }
    },
    //func to insert checked-in member into queue system
    create_queue_entry(name, memberId) {
      console.log('in create queue entry',name,memberId)
      this.connect_ws()
      this.ws.send(this.InputCheckedInMemberJSON(name,memberId))
    },

    InputCheckedInMemberJSON(name, memberId) {
      return JSON.stringify({
        "msg_type": 20, //send checked-in member info
        "client_id": this.clientId,
        body: `{
          "name": "`+name+`",
          "member_id": "`+memberId+`"
        }`})
    },
  }
}
</script>


<style scoped lang="scss">
</style>
