<template>
  <div class="admin_error_logs">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Your Upcoming Event</h5>
      <div style="text-align:center; width:100%">
        <b-table responsive striped hover :items="all_logs" :fields="fields" id="error_logs" class="logs">
        </b-table>
      </div>
      Total Connection: {{ total_open_connection }}
    </div>


  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      fields: [
       {
          key: 'id',
          label: 'ID',
          sortable: true,
       },
       {
          key: 'module',
          label: 'Module',
          sortable: true,
        },
        {
          key: 'func',
          label: 'Function',
          sortable: true,
        },
        {
          key: 'log',
          label: 'Log',
        },
        {
          key: 'log_date',
          label: 'Date',
          sortable: true,
          tdAttr: {style: "min-width:130px;"},
          formatter: (v) => { return (v).split("T")[0]+' '+this.ISOZtoH24(v) },
        },
      ],
      all_logs: [],
      total_open_connection: 0,
    }
  },
  mounted () {
    this.getErrorLogs();
  },
  components: {
  },
  computed: {
    member_id () {
      return this.$store.state.userStore.member_id
    },
  },
  methods:{
    getErrorLogs () {
      this.$http.get('/api/admin/error_logs'
      ).then((resp) => {
        console.log(resp.data)
        this.all_logs = resp.data.all_logs;
        this.total_open_connection = resp.data.total_open_connection;
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
  },
}
</script>

<style scoped lang="scss">
.events {
  font-size: 13px;

  button {
    font-size: 11px;
  }
}
</style>
