<template>
  <div class="product">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Admin Product </h5>
    </div>

    <div style="height:50px"></div>
      <b-button @click="showCategoryForm(v => category_id1 = v, category_id1)" class="m-3">Category 1</b-button>
      <b-button @click="showCategoryForm(v => category_id2 = v, category_id2)" class="m-3">Category 2</b-button><br>

      <b-modal id="modal-category-list" ok-only centered title="Choose Category" ref="categoryForm" button-size="sm">
        <productCategory title="Select Category" :selected_value="tmpSelectedVal" v-on:category_selected="categorySelected($event)"></productCategory>
      </b-modal>

      <p>
        Category 1<br><b>{{category_id1.category}}</b><br>
        Category 2<br><b>{{category_id2.category}}</b><br>
      </p>

      <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
        <b-table responsive striped hover :items="all_filtered_product" :fields="fields" id="admin_product" style="font-size:9pt">
          <template v-slot:cell(id)="row">
            {{row.item.id}}
          </template>
        </b-table>
      </div>

  </div>
</template>

<script>
import productCategory from './list_category.vue';

export default {
  components: {
    productCategory,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'listing_price',
          label: 'Price',
          tdAttr: {style: "min-width:130px;"}, // or 80px
          formatter: (v) => { return '$'+ (v).toFixed(2) },
        },
        {
          key: 'inventory',
          label: 'Inventory',
        },
      ],
      tmpSelectedVal: 0,
      tmpFn: {},
      category_id1: {id:0},
      category_id2: {id:0},
      all_product: [],
      all_category: [],
      all_filtered_product: [], 
    }
  },
  mounted () {
    this.getProduct();
  },
  computed: {
  },
  methods:{
    showCategoryForm(fn, v) {
      this.tmpSelectedVal = v.id
      this.tmpFn = fn
      this.$refs['categoryForm'].show()
    },
    categorySelected (category) {
      this.tmpFn(category)
      this.all_filtered_product = this.all_product.filter( v => ((this.category_id1.id == 0 || this.category_id1.id == v.category_id1 || this.category_id1.id == v.category_id2)
                                                              && (this.category_id2.id == 0 || this.category_id2.id == v.category_id2 || this.category_id2.id == v.category_id1)));
    },
    getProduct () {
      this.$http.get('/api/admin/product'
      ).then((resp) => {
        this.all_product = resp.data.all_product;
        this.all_category = resp.data.all_category;
        this.all_filtered_product = this.all_product; // display unfiltered product for the 1st time
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
  },
}
</script>

