<template>
  <div class="new_product_inventory">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Admin New Product Inventory</h5>
        <hr style="margin-bottom:50px;">

         <b-button @click="showProductForm(v => product = v, product)" class="m-3">Product *</b-button>

         <b-button @click="showAttributeForm(v => attribute_option_id1 = v, attribute_option_id1, product.attribute_id1)" class="m-3" v-if="product.attribute_id1>0">Attribute Option 1</b-button>
         <b-button @click="showAttributeForm(v => attribute_option_id2 = v, attribute_option_id2, product.attribute_id2)" class="m-3" v-if="product.attribute_id2>0">Attribute Option 2</b-button>
         <b-button @click="showAttributeForm(v => attribute_option_id3 = v, attribute_option_id3, product.attribute_id3)" class="m-3" v-if="product.attribute_id3>0">Attribute Option 3</b-button>
         <b-button @click="showAttributeForm(v => attribute_option_id4 = v, attribute_option_id4, product.attribute_id4)" class="m-3" v-if="product.attribute_id4>0">Attribute Option 4</b-button>
         <b-button @click="showAttributeForm(v => attribute_option_id5 = v, attribute_option_id5, product.attribute_id5)" class="m-3" v-if="product.attribute_id5>0">Attribute Option 5</b-button><br>

         <b-modal id="modal-product-list" ok-only centered title="Choose Product" ref="productForm" button-size="sm">
           <listProduct title="Select Product" v-on:product_selected="productSelected($event)"></listProduct>
         </b-modal>

         <b-modal id="modal-attribute-list" ok-only centered title="Choose Product Attribute" ref="attributeForm" button-size="sm">
           <listAttributeOption title="Select Attribute Option" v-on:attribute_option_selected="attributeOptionSelected($event)" :attribute_id="currentAttributeId"></listAttributeOption>
         </b-modal>

           <p>
             Product *<br><b>{{product.name}}</b><br>
             Attribute Option 1<br><b>{{attribute_option_id1.option}}</b><br>
             Attribute Option 2<br><b>{{attribute_option_id2.option}}</b><br>
             Attribute Option 3<br><b>{{attribute_option_id3.option}}</b><br>
             Attribute Option 4<br><b>{{attribute_option_id4.option}}</b><br>
             Attribute Option 5<br><b>{{attribute_option_id5.option}}</b><br>
           </p>

          <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="serial_num" placeholder=" "></b-form-input>
              <span>Serial num </span>
              </label>
          </p>

         <p style="max-width:500px; margin:auto">
            <label class="placeholder">
              <b-form-input v-model.trim="quantity" @blur="fCheckQuantity" :state="fQuantityOk" placeholder=" "></b-form-input>
              <span>Quanity *</span>
              <p v-if="fQuantityOk!=false">Quantity of product</p>
              <b-form-invalid-feedback :force-show="fQuantityOk==false">Quantity is required</b-form-invalid-feedback>
              </label>
          </p>

          <b-button variant="outline-secondary" @click="createProductInventory" style="margin-top:30px; width:80%; max-width:300px">Create Product Inventory</b-button>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
import listProduct from './list_product.vue';
import listAttributeOption from './list_attribute_option.vue';

export default {
  components: {
    listProduct,
    listAttributeOption,
  },
  data() {
    return {
      tmpSelectedVal: 0,
      currentAttributeId:0,
      tmpFn: {},
      product: {},
      attribute_option_id1: "0",
      attribute_option_id2: "0",
      attribute_option_id3: "0",
      attribute_option_id4: "0",
      attribute_option_id5: "0",
      serial_num: null,
      quantity: "0",
      fQuantityOk: null,
    }
  },
  methods:{
    showProductForm(fn, v) {
      this.tmpSelectedVal = v.id
      this.tmpFn = fn
      this.$refs['productForm'].show()
    },
    productSelected (product) {
      this.tmpFn(product)
    },
    showAttributeForm(fn, v, attributeId) {
      this.currentAttributeId = attributeId
      this.tmpSelectedVal = v.id
      this.tmpFn = fn
      this.$refs['attributeForm'].show()
    },
    attributeOptionSelected (attr) {
      this.tmpFn(attr)
    },
    createProductInventory() {
      this.fCheckQuantity();
      if (!this.fQuantityOk) {
        //alert('All fields are required.')
        return;
      }
      this.$http.post('/api/admin/new_product_inventory',{
        "product_id"    : parseInt(this.product.id),
        "attribute_option_id1" : parseInt(this.attribute_option_id1.id),
        "attribute_option_id2" : parseInt(this.attribute_option_id2.id),
        "attribute_option_id3" : parseInt(this.attribute_option_id3.id),
        "attribute_option_id4" : parseInt(this.attribute_option_id4.id),
        "attribute_option_id5" : parseInt(this.attribute_option_id5.id),
        "serial_num"    : this.serial_num,
        "quantity"      : parseInt(this.quantity),
      }).then(() => {
        alert('Product Inventory has been created');
        this.product = {}; //to prevent double submitting
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    fCheckQuantity() {
      this.fQuantityOk = (this.quantity.trim() != "" && (/^[0-9]+$/.test(this.quantity.trim())));
      if (this.fQuantityOk == false)
        return;
      if (parseInt(this.quantity) != 1 && this.serial_num != "") {
        alert('serial_num is provided so quantity must be set to 1');
        this.fQuantityOk = false;
      }
    },
  }
}
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}
input, textarea {
  position: relative;
  outline: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  display: block;
  box-sizing: border-box;
  margin-bottom: 14px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  border: 0;
  border-bottom: 1px solid #dddedf;
  padding: 4px 8px;
  font-family: inherit;
  transition: 0.5s all;
}
.placeholder{
  width: 100%;
  position: relative;
  transition: 0.2s;
}
.placeholder > span {
  position: absolute;
  left: 4px;
  top: 5px;
  transition: 0.2s;
  pointer-events: none;
}
.placeholder>input, .placeholder>textarea  {
  padding: 4px 8px;
}
.placeholder>input:focus~span, .placeholder>input:not(:placeholder-shown)~span,
.placeholder>textarea:focus~span, .placeholder>textarea:not(:placeholder-shown)~span {
  position: absolute;
  top: -12px;
  font-size: 10px;
}
</style>
