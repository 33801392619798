import top_header from './components/top_header.vue';
import login from './components/login.vue';
import member from './components/members/member.vue';
import register from './components/register.vue';
import member_update_profile  from './components/members/update_profile.vue';
import events from './components/booking/events.vue';
import booking_session from './components/booking/book_session.vue';
import admin_new_event from './components/admins/event.vue';
import password from './components/password.vue';
import ncbc_home from './components/ncbc_home.vue';
import email from './components/email.vue';
import check_in from './components/staff/check_in.vue';
import staff_search from './components/staff/search.vue';
import staff_member_search from './components/staff/member_search.vue';
import password_reset from './components/password_reset.vue';
import admin_summary_view from './components/admins/summary_view.vue';
import staff_register from './components/staff/register.vue';
import admin_error_logs from './components/admins/error_logs.vue';
import admin_new_category from './components/admins/category.vue';
import staff_upcoming_events from './components/staff/upcoming_events.vue';
import admin_new_product from './components/admins/product.vue';
import admin_manage_attribute from './components/admins/attribute.vue';
import admin_list_category from './components/admins/list_category.vue';
import admin_list_attribute from './components/admins/list_attribute.vue';
import admin_manage_attribute_option from './components/admins/attribute_option.vue';
import member_check_in from './components/members/check_in.vue';
import system_member_check_in from './components/system/system_member_check_in.vue';
import system_camera from './components/reusable/system_camera.vue';
import queue_system from './components/reusable/queue_system.vue';
import qr_scanner from './components/reusable/qr_scanner.vue';
import admin_list_product from './components/admins/list_product.vue'
import admin_new_product_inventory from  './components/admins/product_inventory.vue';
import ncbc_location from './components/ncbc/location.vue';
import ncbc_contact from './components/ncbc/contact.vue';
import ncbc_classes from './components/ncbc/classes.vue';
import staff_member_upgrade from './components/staff/member_upgrade.vue';
import staff_member_register from './components/staff/member_register.vue';
import staff_list_staff_logs from './components/staff/list_staff_logs.vue';
import staff_recent_expired from './components/reusable/recent_expired_member_view.vue';
import staff_checkin_list from './components/staff/checkin_list.vue';
import staff_queue_system from './components/staff/queue_system.vue';
import admin_member from './components/admins/member.vue';
import admin_queue_system from './components/admins/queue_system.vue';
import staff_list_member_logs from './components/staff/list_member_logs.vue';
import admin_product from './components/admins/product_page.vue';

const routeTable = [
    {
      path: '/',
      name: "Home",
      components: {
        default: ncbc_home,
        top: top_header,
        login: login
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/member',
      name: "MemberPortal",
      components: {
        default: member,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/register',
      name: "RegisterForm",
      components: {
        default: register,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/member/update_profile',
      name: "MemberUpdateProfile",
      components: {
        default: member_update_profile,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/new_event',
      name: "AdminNewEvent",
      components: {
        default: admin_new_event,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/booking/events',
      name: "Events",
      components: {
        default: events,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/booking/:id/book_session/',
      name: "BookingSession",
      components: {
        default: booking_session,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/password',
      name: "Password",
      components: {
        default: password,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ncbc/location',
      name: "Location",
      components: {
        default: ncbc_location,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/ncbc/classes',
      name: "Classes",
      components: {
        default: ncbc_classes,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/email',
      name: "Email",
      components: {
        default: email,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/check_in',
      name: "StaffCheckIn",
      components: {
        default: check_in,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/search',
      name: "StaffSearch",
      components: {
        default: staff_search,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/recent_expired_members',
      name: "StaffRecentExpiredMembers",
      components: {
        default: staff_recent_expired,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/member_search',
      name: "StaffMemberSearch",
      components: {
        default: staff_member_search,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/ncbc/contact',
      name: "ContactUs",
      components: {
        default: ncbc_contact,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/reset_password',
      name: "PasswordReset",
      components: {
        default: password_reset,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/staff/register',
      name: "StaffRegister",
      components: {
        default: staff_register,
        top: top_header
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/admins/summary_view',
      name: "AdminSummaryView",
      components: {
        default: admin_summary_view,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/error_logs',
      name: "AdminErrorLogs",
      components: {
        default: admin_error_logs,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/new_category',
      name: "AdminNewCategory",
      components: {
        default: admin_new_category,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/upcoming_events',
      name: "StaffUpcomingEvents",
      components: {
        default: staff_upcoming_events,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/new_product',
      name: "AdminNewProduct",
      components: {
        default: admin_new_product,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/manage_attribute',
      name: "AdminManageAttribute",
      components: {
        default: admin_manage_attribute,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/list_category',
      name: "AdminListCategory",
      components: {
        default: admin_list_category,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/list_attribute',
      name: "AdminListAttribute",
      components: {
        default: admin_list_attribute,
        top: top_header
      },
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/admins/manage_attribute_option',
      name: "AdminManageAttributeOption",
      components: {
        default: admin_manage_attribute_option,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/members/check_in',
      name: "MemberCheckIn",
      components: {
        default: member_check_in,
      },
    },
    {
      path: '/admins/list_product',
      name: "AdminListProduct",
      components: {
        default: admin_list_product,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admins/new_product_inventory',
      name: "AdminNewProductInventory",
      components: {
        default: admin_new_product_inventory,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/paying_member',
      name: "StaffMemberUpgrade",
      components: {
        default: staff_member_upgrade,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/member_register',
      name: "StaffMemberRegister",
      components: {
        default: staff_member_register,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/list_staff_logs',
      name: "StaffListStaffLogs",
      components: {
        default: staff_list_staff_logs,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/system/system_member_check_in',
      name: "SystemMemberCheckIn",
      components: {
        default: system_member_check_in,
      },
    },
    {
      path: '/reusable/queue_system',
      name: "QueueSystem",
      components: {
        default: queue_system,
      },
    },
    {
      path: '/reusable/system_camera',
      name: "SystemCamera",
      components: {
        default: system_camera,
      },
    },
    {
      path: '/reusable/qr_scanner',
      name: "QRScanner",
      components: {
        default: qr_scanner,
      },
    },
    {
      path: '/staff/checkin_list',
      name: "StaffCheckInList",
      components: {
        default: staff_checkin_list,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/queue_system',
      name: "StaffQueueSystem",
      components: {
        default: staff_queue_system,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin/member',
      name: "AdminMember",
      components: {
        default: admin_member,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin/queue_system',
      name: "AdminQueueSystem",
      components: {
        default: admin_queue_system,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/staff/list_member_logs',
      name: "StaffListMemberLogs",
      components: {
        default: staff_list_member_logs,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin/product',
      name: "AdminProduct",
      components: {
        default: admin_product,
        top: top_header
      },
      meta: {
        requiresAuth: true,
      },
    },
]

export default routeTable

