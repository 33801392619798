<template>
  <div class="member_logs">
    <div style="width:100%;">

      <b-container class="form fluid">
      <b-card class="text-center" style="background:rgba(255,255,255,.9);">

        <h5>Member Logs</h5>
        <hr style="margin-bottom:50px;">


        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <b-row>
            <b-col>
              <input type="checkbox" id="gold" value="Gold" v-model="checkedEvent">
              <label for="gold">Gold check-in</label>
            </b-col>
            <b-col style="display:none">
              <input type="checkbox" id="standard" value="Standard" v-model="checkedEvent">
              <label for="standard">Standard check-in</label>
            </b-col>
           <b-col>
              <input type="checkbox" id="paid1" value="Paid 1" v-model="checkedEvent">
              <label for="paid1">Buys Gold</label>
            </b-col>
            <b-col style="display:none">
              <input type="checkbox" id="paid2" value="Paid 2" v-model="checkedEvent">
              <label for="paid2">Buys Standard</label>
            </b-col>
            <b-col>
              <input type="checkbox" id="expired" value="Expired" v-model="checkedEvent">
              <label for="expired">Expired</label>
            </b-col>
            <b-col>
              <b-button size="sm" @click="getMemberLogs">Refresh Logs</b-button>
            </b-col>
          </b-row>

        </div>

        <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
          <h5>Member Logs</h5>
          <div style="text-align:center; width:100%">
            <b-table responsive striped hover :items="member_logs" :fields="fields" :tbody-tr-class="rowClass" id="member_list_logs" style="font-size:9pt">

              <template v-slot:cell(event)="data">
                {{ event[data.item.event] }}
              </template>

            </b-table>
          </div>
        </div>

      </b-card>
      </b-container>

    </div>
  </div>
</template>

<script>
import datetime_mixin from '../../mixins/datetime.js'

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      fields: [
       {
          key: 'log_date',
          sortable: true,
          tdAttr: {style: "min-width:130px; "},
          formatter: (v) => { return (v).split("T")[0]+' '+this.H24toH12(this.ISOZtoH24(v)) },
       },
       {
          key: 'staff',
          label: 'Staff',
          sortable: true,
       },
       {
          key: 'event',
          label: 'Event',
          sortable: true,
        },
        {
          key: 'log',
          label: 'Log',
        },
      ],
      timer: "",
      member_logs: [],
      event1: 0,
      event2: 0,
      event3: 0,
      event4: 0,
      event5: 0,
      event: ["0","Gold", "Standard", "Paid 1", "Paid 2", "Expired"],    // checkbox starts at index 1
      checkedEvent: ["Gold", "Paid 1", "Expired"],
    }
  },
  mounted () {
    this.getMemberLogs();
    this.timer = setInterval(this.getMemberLogs, 20000);
  },
  methods:{
    getMemberLogs() {
      this.event1 = 0;
      this.event2 = 0;
      this.event3 = 0;
      this.event4 = 0;
      this.event5 = 0;

      if (this.checkedEvent.includes("Gold"))
        this.event1 = 1;
      if (this.checkedEvent.includes("Standard"))
        this.event2 = 1;
      if (this.checkedEvent.includes("Paid 1"))
        this.event3 = 1;
      if (this.checkedEvent.includes("Paid 2"))
        this.event4 = 1;
      if (this.checkedEvent.includes("Expired"))
        this.event5 = 1;

      if (this.event1 == 0 && this.event2 == 0 && this.event3 == 0 && this.event4 == 0 && this.event5 == 0) {
        alert("Must select at least 1 category");
        return;
      }

      this.$http.get('/api/staff/list_all_member_logs?event1='+this.event1+'&event2='+this.event2+'&event3='+this.event3+'&event4='+this.event4+'&event5='+this.event5
      ).then((resp) => {
        this.member_logs = resp.data.member_logs;
      }).catch((error) => {
        alert(error.response.data.message);
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.event==5 && type=='row') { //expired membership
        return "row-warning"
      }
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>


<style scoped lang="scss">
* {
  font-family: 'Montserrat';
  box-sizing: border-box;
  color: #8b8c8d;
}

input {
  margin: 3px;
}
</style>
