import axios from 'axios'

export default {
  getHeaderData (succeed_cb,fail_cb) {
      axios.get('/member/header_data',{
      }).then((resp) => {
        succeed_cb(resp.data) //user info
      }).catch((error) => {
        console.log(error.response.data.message);
        fail_cb(error.response.data.message)
      })
  },
}

