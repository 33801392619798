
<template>
  <div class="ncbc_location">

    <div class="container" style="padding:10px; background:rgba(0,0,0,.5);color:#fff;text-align:left;">
      <p style="color:#fff;text-align:center;padding:15px;">Location and Hours</p>
      <hr>
      <div class="row">
        <div class="col-12 col-md-6 order-2" style="opacity:0.85; padding:30px;">
              <p>
                <b>Address:</b><br>
                2421 Mercantile Dr, suite d,<br>
                Rancho Cordova,<br>
                CA, 95742
              </p>
              <br><br>
              Gym is closed due to the current covid situation, reopening on Feb. 24 2021<hr>
              <p>
                <b>Gym Hours: (On and after Feb 24 2021)</b><br>
                <br>
                <!-- Monday: CLOSED<br> -->
                <!-- Tuesday: CLOSED<br> -->
                <!-- Wednesday: OPEN<br> -->
                <!-- Thursday: CLOSED<br> -->
                <!-- Friday: CLOSED<br> -->
                <!-- Saturday: CLOSED<br> -->
                <!-- Sunday: CLOSED<br> -->
                Monday: 4:00PM - 10:00PM<br>
                Tuesday: 4:00PM - 10:00PM<br>
                Wednesday: 4:00PM - 10:00PM<br>
                Thursday: 4:00PM - 10:00PM<br>
                Friday: 4:00PM - 10:00PM<br>
                Saturday: 1:00PM - 9:00PM<br>
                Sunday: 1:00PM - 10:00PM<br>
              </p>
        </div>

        <div class="col-12 col-md-6 order-1" style="opacity:0.80; text-align:left; padding:30px; color:#fff">
          <a style="color:#fff" href="https://www.google.com/maps/place/Northern+California+Badminton+Club/@38.6143192,-121.2532006,17z/data=!3m1!4b1!4m5!3m4!1s0x809ae784bcf57ab7:0x8e4adb63b28e311a!8m2!3d38.6143192!4d-121.2510119" target="_blank">
            <i class="fas fa-map-marker-alt"></i>&nbsp;See Gym Location in Google Map
          </a>
          <hr>
          <router-link to="/booking/events" style="color:#fff;">
            See our gym calendar to see all open events.
          </router-link>
        </div>
        <div style="clear:both"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods:{
  }
}
</script>


<style scoped lang="scss">
.top_menu_a {
  color:#fff;
  font-weight: 400;
  font-size: 18px;
}

</style>
