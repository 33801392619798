<template>
  <div class="staff_queue_system">

    <div class="container" style="background:rgba(255,255,255,.9); padding:20px 0;">
      <h5>Court Queues ({{ clientId }})</h5>
        <div class="d-flex flex-wrap">
          <!-- <div class="row m-2 p-3" v-for="c in world.courts" style="font-size:9pt; border-width:1px; border-bottom-style:solid; max-width:350px;"> -->
          <!-- </div> -->
        </div>
      <hr>
      <h5>Court Configs</h5>
      <div class="d-flex flex-wrap">
        <div class="row m-2 p-3" v-for="(c, index1) in world.courts" :key="c.id" style="font-size:9pt; border-width:1px; border-bottom-style:solid; max-width:350px;">
          <div class="col-2">
            {{ c.id }}
          </div>
          <div class="col-10" style="text-align:left">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="c.is_single_court" disabled>
              <label class="form-check-label">Single Court</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="c.is_timed_court" disabled>
              <label class="form-check-label">Timed Court</label>
            </div>
          </div>

          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            <input type="text" class="form-control" placeholder="General Message" v-model="c.message" id="courtMsg" style="background:#f5f5f5;color:green;">
          </div>
          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="c.closed">
              <label class="form-check-label">closed</label>
            </div>
            <button type="button" class="btn btn-primary btn-sm" @click="update_court_status(index1)" style="font-size:9px">update</button>
          </div>


          <div class="col-12"><hr></div>

          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            New Reservation
          </div>

          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            <select class="form-select" v-bind:id="'reserveMsg-'+index1">
              <option value="Court Reserved" selected>Court Reservation</option>
              <option value="NCBC Academy">NCBC Academy</option>
              <option value="Lesson/Training">Lesson/Training</option>
              <option value="Court Closed">Court Closed</option>
            </select>
          </div>

          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            <select class="form-select" v-bind:id="'reserveStartHr-'+index1">
              <option value="1" selected>1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <select class="form-select" v-bind:id="'reserveStartMin-'+index1">
              <option value="00" selected>00</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
            </select>
            &nbsp;-&nbsp;
            <select class="form-select" v-bind:id="'reserveEndHr-'+index1">
              <option value="1">1</option>
              <option value="2" selected>2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <select class="form-select" v-bind:id="'reserveEndMin-'+index1">
              <option value="00" selected>00</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
            </select>
            <button type="button" class="btn btn-primary btn-sm" style="font-size:7px" @click="reserve_court(index1)">reserve</button>
          </div>

          <div class="col-2"></div>
          <div class="col-10" style="text-align:left">
            <div v-for="(r, index2) in c.reservation" :key="index2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-bind:value="index2" v-bind:id="'reserve-'+index2+'_ct-'+index1">
                <label class="form-check-label" v-bind:for="'reserve-'+index2+'_ct-'+index1">
                  {{ r.start_time }} - {{ r.end_time }} ({{r.message}})
                </label>
              </div>
            </div>

            <div v-if="c.reservation && c.reservation.length>0">
              <button type="button" class="m-2 btn btn-primary btn-sm" style="font-size:7px" @click="delete_reservation(index1)">delete</button>
            </div>
          </div>

          <div class="col-12"><hr></div>

        </div>
        <hr>
        <div id="queueLog" style="width:90%; height:300px; background:#fff; text-align:left; margin:auto; overflow:scroll; font-size:12px"></div>
      </div>
    </div>
  </div>

</template>

<script>
  import datetime_mixin from '../../mixins/datetime.js'
  import {Messages} from '../../assets/js/queue_system_messages.js';

export default {
  mixins: [datetime_mixin],
  data() {
    return {
      world: {},
      clientId: "",
      ws: {}, // websocket connection
      server_ip: "",
    }
  },
  mounted () {
    //this.server_ip = "queue-system.mybluemix.net";
    this.server_ip = "queue-system.norcalbadminton.com";
    //this.server_ip = "localhost:3031";
    this.connect_ws();
  },
  components: {
  },
  methods:{
    connect_ws() {
      if (this.server_ip==null || this.ws.readyState == 1 || this.ws.readyState == 0) { //OPEN or CONNECTING
        console.log("exit connect_ws")
        return;
      }
      //var uri = 'ws://'+this.server_ip+':3031/ws_connect';
      var uri = 'ws:';
      var loc = window.location;

      if (loc.protocol === 'https:') {
        uri = 'wss:';
      }
      uri = uri+'//'+this.server_ip+'/ws_connect';

      this.ws = new WebSocket(uri,null,null,null, {rejectUnauthorized: false}) //https://stackoverflow.com/questions/38242334/disable-websocket-certificate-validation
//      this.ws = new WebSocket(uri, {
//        rejectUnauthorized: false
//      })

      var self = this
      this.ws.onopen = () => {
        console.log('Connected')
      }
      this.ws.onerror = function(e) {
        //this.server_ip = prompt("Enter server ip", "Server ip");
        //if (this.server_ip!=null) this.connect_ws()
        console.error('Socket encountered error: ', e.message, 'Closing socket');
        self.ws.close();
      }

      this.ws.onclose = function() {
          console.log('Disconnected!');
          self.connect_ws() //connect now then try again just in case connection fail
          setTimeout(function() {
            self.connect_ws()
          }, 2000);
      };

      this.ws.onmessage = function(evt) {
        //might get more than 1 msg at once
        //need to break up msgs
        console.log('got msg ',evt.data)
        var msgArray = evt.data.split(Messages.SPACER); //seperate potential multiple msg from server
        for (let i = 0; i < msgArray.length; i++) {
          var d = JSON.parse(msgArray[i])


          if (d.msg_type==2) { //worldmap sent from server
            self.world  = JSON.parse(d.body)

            //get log messages
            var l = document.getElementById('queueLog')
            console.log("l",self.world.log_msg)
            l.innerHTML += self.world.log_msg.replace("\n","<br>")

            console.log('world',self.world)
          }else if (d.msg_type==3) { //server send client id
            self.clientId = JSON.parse(d.body).id
            self.ws.send(Messages.RequestWorldMap(self.clientId)); //got id, request worldmap
          }
        }
      }
    },
    reserve_court(idx) {
      var startHr = document.getElementById('reserveStartHr-'+idx).value;
      var startMin = document.getElementById('reserveStartMin-'+idx).value;
      var endHr = document.getElementById('reserveEndHr-'+idx).value;
      var endMin = document.getElementById('reserveEndMin-'+idx).value;
      var msg = document.getElementById('reserveMsg-'+idx).value;
      console.log('reserve msg', msg)
      if (this.IsTime2Larger(startHr+":"+startMin, endHr+":"+endMin)) {
        var s = Messages.RegisterReservation(this.clientId, idx, msg, startHr+":"+startMin+"pm", endHr+":"+endMin+"pm")
        this.ws.send(s); //send request to update court
      }else{
        alert('Reservation starting time must be before ending time');
      }
    },
    update_court_status(idx){
      console.log("update",this.world.courts[idx].closed, this.world.courts[idx].message)
      var s = Messages.RequestUpdateCourtStatus(this.clientId, idx, this.world.courts[idx].closed, this.world.courts[idx].message)
      console.log('str',s)
      this.ws.send(s); //send request to update court
    },
    delete_reservation(courtIdx){
      //loop thru all reservation
      this.world.courts[courtIdx].reservation.forEach((r,i)=>{
        if (document.getElementById("reserve-"+i+"_ct-"+courtIdx).checked){
          var s = Messages.RequestDeleteReservation(this.clientId, courtIdx, r.start_time, r.end_time)
          this.ws.send(s); //send request to delete court reservation
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
