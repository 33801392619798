<template>
  <div class="top_header">

    <div class="top_bar clearfix">
      <a class="f-right" style="cursor:pointer; color:#fff;" v-if="!user" href="/register">&nbsp;Register</a>
      <span class="f-right" v-if="!user"> | </span>
      <div class="f-right" style="cursor:pointer" v-if="!user" @click="show_login"><i class="fas fa-user-circle"></i>&nbsp;Sign in</div>
      <div class="f-right" v-if="user" v-b-toggle.member_bar><i class="fas fa-user-circle"></i>&nbsp;{{ this.user }}&nbsp;<i class="fas fa-bars" style="margin-left:20px"></i></div>
    </div>

    <div style="width:100%;height:5px;background:#f13131;"></div>

    <b-sidebar id="member_bar" style="z-index:15;" title="Member Portal" right shadow backdrop  backdrop-variant="transparent">
      <hr>
      <div class="px-3 py-2">
        <p class="side_bar_btn" @click="home"><i class="far fa-calendar-check mr-2"></i>My Event List</p>
        <p class="side_bar_btn" @click="member_update_profile"><i class="far fa-address-card mr-2"></i>Update Profile</p>
        <p class="side_bar_btn" @click="password"><i class="fas fa-key mr-2"></i>Change Password</p>
        <p class="side_bar_btn" @click="email"><i class="far fa-user mr-2"></i>Change Login Email</p>
        <hr>


        <!-- <div v-if="this.$store.state.userStore.roles.includes('WEB_MEMBER')"> -->
        <!--   <p class="side_bar_btn" @click="affiliate_clickthru"><i class="fas fa-mouse mr-2"></i>Click-through History</p> -->
         <!-- <hr> -->
       <!-- </div> -->

        <div v-if="this.$store.state.userStore.roles.includes('STAFF')">
          <div style="padding-bottom:20px">Staff Functions</div>
          <p class="side_bar_btn" @click="staff_check_in"><i class="fas fa-check-double mr-2"></i>Event Check In</p>
          <!-- <p class="side_bar_btn" @click="staff_member_search"><i class="fas fa-search mr-2"></i>Member Search test</p> -->
          <!-- <p class="side_bar_btn" @click="staff_register"><i class="far fa-id-badge mr-2"></i>Register New Member</p> -->
          <p class="side_bar_btn" @click="staff_member_upgrade"><i class="fa-solid fa-users mr-2"></i>Membership</p>
          <p class="side_bar_btn" @click="staff_list_staff_logs"><i class="fa-solid fa-pen-to-square mr-2"></i>Staff Logs</p>
          <p class="side_bar_btn" @click="staff_list_member_logs"><i class="fa-solid fa-pen-to-square mr-2"></i>Member Logs</p>
          <p class="side_bar_btn" @click="staff_recent_expired"><i class="fa-solid fa-user-slash mr-2"></i>Recent Expired Member</p>
          <p class="side_bar_btn" @click="staff_checkin_list"><i class="fa-solid fa-people-group mr-2"></i>Checked-in Members</p>
          <p class="side_bar_btn" @click="staff_queue_system"><i class="fa-solid fa-list mr-2"></i>Queue System</p>
          <hr>
        </div>

        <div v-if="this.$store.state.userStore.roles.includes('ADMIN')">
          <div style="padding-bottom:20px">Admin Functions</div>
           <p class="side_bar_btn" @click="admin_new_event"><i class="fas fa-plus mr-2"></i>Create Event</p>
           <p class="side_bar_btn" @click="admin_summary_view"><i class="far fa-list-alt mr-2"></i>Summary View</p>
           <p class="side_bar_btn" @click="admin_error_logs"><i class="far fa-list-alt mr-2"></i>Error Logs</p>
           <p class="side_bar_btn" @click="admin_queue_system"><i class="fa-solid fa-list mr-2"></i>Queue System</p>
           <p class="side_bar_btn" @click="admin_new_category"><i class="far fa-list-alt mr-2"></i>New Category</p>
           <p class="side_bar_btn" @click="admin_new_product"><i class="far fa-list-alt mr-2"></i>New Product</p>
           <p class="side_bar_btn" @click="admin_manage_attribute"><i class="far fa-list-alt mr-2"></i>Manage Attribute</p>
           <p class="side_bar_btn" @click="admin_manage_attribute_option"><i class="far fa-list-alt mr-2"></i>Manage Attribute Option</p>
           <p class="side_bar_btn" @click="admin_new_product_inventory"><i class="far fa-list-alt mr-2"></i>New Product Inventory</p>
           <p class="side_bar_btn" @click="admin_product"><i class="far fa-list-alt mr-2"></i>Product</p>
           <p class="side_bar_btn" @click="admin_member"><i class="fas fa-search mr-2"></i>List Members</p>
           <p class="side_bar_btn" @click="staff_upcoming_events"><i class="far fa-calendar-alt mr-2"></i>(staff feat.)Upcoming Events</p>
           <p class="side_bar_btn" @click="staff_search"><i class="fas fa-search mr-2"></i>(staff feat.)Member Search</p>
           <hr>
        </div>

        <div v-if="this.$store.state.userStore.roles.includes('SYSTEM')">
          <div style="padding-bottom:20px">SYSTEM Functions</div>
          <p class="side_bar_btn" @click="system_member_check_in"><i class="far fa-user mr-2"></i>Check In</p>
          <p class="side_bar_btn" @click="system_camera"><i class="far fa-user mr-2"></i>Camera</p>
          <p class="side_bar_btn" @click="qr_scanner"><i class="far fa-user mr-2"></i>QR Scanner</p>
          <hr>
        </div>

        <p class="side_bar_btn" @click="signout"><i class="fas fa-sign-out-alt mr-2"></i>Sign Out</p>
        <div style="height:100px"></div>
      </div>
    </b-sidebar>


    <b-modal id="modal-login" title="Please log in" ref="memberLogin" hide-footer>
      <div style="text-align:center; margin:auto">
        <login :login_only=1></login>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Login from './login.vue';

export default {
  components: {
    Login,
  },
  computed: {
    user () {
      return this.$store.state.userStore.user
    }
  },
  methods:{
    home() {
      if (this.$route.name != 'MemberPortal') this.$router.push({name: 'MemberPortal'})
    },
    signout() {
      console.log("sigining out!!")
      this.$http.get('/api/member/sign_out'
      ).then(() => {
      }).catch((error) => {
        console.log(error.response.data.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      }).finally(() => {
        this.$store.commit("logging_out")
        location.reload()
      })
    },
    password() {
      this.$router.push({name: 'Password'})
    },
    email() {
      this.$router.push({name: 'Email'})
    },
    member_update_profile() {
      this.$router.push({name: 'MemberUpdateProfile'})
    },
    member_check_in() {
      this.$router.push({name: 'MemberCheckIn'})
    },
    system_camera() {
      this.$router.push({name: 'SystemCamera'})
    },
    qr_scanner() {
      this.$router.push({name: 'QRScanner'})
    },
    system_member_check_in() {
      this.$router.push('system/system_member_check_in?system_bare=1')
    },
    staff_check_in() {
      this.$router.push({name: 'StaffCheckIn'})
    },
    staff_member_search () {
      this.$router.push({name: 'StaffMemberSearch'})
    },
    staff_search () {
      this.$router.push({name: 'StaffSearch'})
    },
//    staff_register () {
//      this.$router.push({name: 'StaffRegister'})
//    },
    staff_upcoming_events() {
      this.$router.push({name: 'StaffUpcomingEvents'})
    },
    staff_member_upgrade() {
      this.$router.push({name: 'StaffMemberUpgrade'})
    },
    staff_list_staff_logs() {
      this.$router.push({name: 'StaffListStaffLogs'})
    },
    staff_list_member_logs() {
      this.$router.push({name: 'StaffListMemberLogs'})
    },
    staff_recent_expired() {
      this.$router.push({name: 'StaffRecentExpiredMembers'})
    },
    staff_checkin_list() {
      this.$router.push({name: 'StaffCheckInList'})
    },
    staff_queue_system() {
      this.$router.push({name: 'StaffQueueSystem'})
    },
    admin_new_event() {
      this.$router.push({name: 'AdminNewEvent'})
    },
    admin_summary_view() {
      this.$router.push({name: 'AdminSummaryView'})
    },
    admin_error_logs() {
      this.$router.push({name: 'AdminErrorLogs'})
    },
    admin_queue_system() {
      this.$router.push({name: 'AdminQueueSystem'})
    },
    admin_new_category() {
      this.$router.push({name: 'AdminNewCategory'})
    },
    admin_new_product() {
      this.$router.push({name: 'AdminNewProduct'})
    },
    admin_manage_attribute() {
      this.$router.push({name: 'AdminManageAttribute'})
    },
    admin_manage_attribute_option() {
      this.$router.push({name: 'AdminManageAttributeOption'})
    },
    admin_new_product_inventory() {
      this.$router.push({name: 'AdminNewProductInventory'})
    },
    admin_product() {
      this.$router.push({name: 'AdminProduct'})
    },
    admin_member() {
      this.$router.push({name: 'AdminMember'})
    },
    show_login (){
      this.$refs.memberLogin.show()
    },
  },
}
</script>

<style>
.top_header {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size:12pt;
  position: relative;
}

.top_bar {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #000;
}

.f-right {
  float:right;
  cursor:pointer;
  border: 0;
  padding-right:15px;
  outline: none;
}

#member_bar {
  text-align: left;
}
</style>

