<template>
  <div id="app" style="background:#222; color:#fff; width:100%; min-height:100%;">
    <!-- <router-view :key="$route.fullPath" name="top"></router-view> -->

    <div style="height:50px"></div>

    <div style="margin:auto;">
      <img src='./assets/images/bu_logo_lg_white.png' style="width:150px;" />
    </div>

    <div style="height:50px"></div>

    <div class="container">
      BadmintonUnited.org is a non profit organization aims to promote the adoption of badminton in United States.<br>
      We provide the infastructure to host badminton facilities throughout the nation and organize leagues/ladders for all communities.<br>
      Any inquiries please contact us at contact@badmintonunited.org
    </div>

    <div style="height:50px"></div>

    <div>
      <router-view :badminton_united=1 :login_only="1" title_caption="Center Login" name="login" />
    </div>

    <div style="height:200px"></div>
    <div style="text-align; padding:15px;">badmintonunited.org ® {{new Date().getFullYear()}}</div>


  </div>
</template>

<script>
import title_mixin from './mixins/pagetitle.js'

export default {
  name: 'App',
  mixins: [title_mixin],
  title: "Badminton United Org.",
  components: {
  },
  mounted () {
    const favicon = document.getElementById("favicon");
    favicon.href = "https://www.badmintonunited.org/favicon_bu.ico";
  },
}
</script>

<style>
#app {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
